import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import axios from 'axios';
Vue.use(VueRouter);

let isLogged = false;

function isAuthenticated() {
  let token = localStorage.getItem("user-token");
  let tokenize = JSON.parse(token)
  // console.log(tokenize.token);
  if (token !== null) {
    tokenize = JSON.parse(token);
    axios.get(
      'https://bilimseferberligi.org/cms/api/v1/token/check',
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          "Authorization": `Bearer ${tokenize.token}`
        }
      }
    ).then(response => {
      if (response.data.success === true || response.data.success === "true") {
        isLogged = true;
        return true;
      } else {
        return false;
      }
    });
  } else {
    return false;
  }
}
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/bagis",
    name: "bagis",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Bagiss.vue")
  },
  {
    path: "/bagis-yap",
    name: "BagisYapView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BagisYapView.vue")
  },
  {
    path: "/kampanya-olustur",
    name: "KampanyaOlusturView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KampanyaOlusturView.vue"),
      // beforeEnter(to, from, next) {
      //   if (isAuthenticated() === false || isAuthenticated() === "false") {
      //     next('/');
      //   } else {
      //     next();
      //   }
      // }
  },
  {
    path: "/hediye-karti-gonder",
    name: "HediyeKartiView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HediyeKartiView.vue")
  },
  {
    path: "/carousel",
    name: "Carousel",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Carousel.vue")
  },
  {
    path: "/404",
    name: "Sayfa Bulunamadı",
    component: () =>
      import("../views/404.vue")
  },
  {
    path: "/sifre-yenile/:id",
    name: "Şifreni Yenile",
    component: () =>
      import("../views/ResetPassword.vue")
  },
  {
    path: "/23-nisan",
    name: "23 Nisan",
    component: () =>
      import("../views/23nisan.vue")
  },
  {
    path: "/sikca-sorulan-sorular",
    name: "FAQ",
    component: () =>
      import("../views/FAQ.vue")
  },
  {
    path: "/bilim-seferberligi",
    name: "Bilim Seferberligi",
    component: () =>
      import("../views/BilimSeferberligi.vue")
  },
  {
    path: "/destekcilerimiz",
    name: "Destekcilerimiz",
    component: () =>
      import("../views/Destekcilerimiz.vue")
  },
  {
    path: "/hikayelerimiz",
    name: "Hikayelerimiz",
    component: () =>
      import("../views/Hikayelerimiz.vue")
  },
  {
    path: "/kampanyalar",
    name: "Kampanyalar",
    component: () =>
      import("../views/Kampanyalar.vue")
  },
  {
    path: "/kampanyalar/:slug",
    name: "KampanyaDetay",
    component: () =>
      import("../views/KampanyaDetay.vue")
  },
  {
    path: "/profil",
    name: "Profile",
    component: () =>
      import("../views/Profile.vue"),
    beforeEnter(to, from, next) {
      if (isAuthenticated() === false || isAuthenticated() === "false") {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: "/iletisim",
    name: "İletişim",
    component: () =>
      import("../views/Contact.vue")
  },
  {
    path: "/workplace-form",
    name: "Workplace",
    component: () =>
      import("../views/Workplace.vue")
  },
  {
    path: "/kvkk",
    name: "kvkk",
    component: () =>
      import("../views/KVKK.vue")
  },
  {
    path: "/bagisci-haklari-beyannamesi",
    name: "bagisci-haklari-beyannamesi",
    component: () =>
      import("../views/Bagiscihaklaribeyannamesi.vue")
  },
  {
    path: "/aydinlatma-ve-riza-metni",
    name: "riza",
    component: () =>
      import("../views/Riza.vue")
  },
  {
    path: "/sikca-sorulan-sorular",
    name: "sikca-sorulan-sorular",
    component: () =>
      import("../views/FAQ.vue")
  },
  {
    path: "/guvenilirlik",
    name: "guvenilirlik",
    component: () =>
      import("../views/Reliability.vue")
  },
  {
    path: "/ulasilan-okullar",
    name: "okullar",
    component: () =>
      import("../views/Okullar.vue")
  },
  {
    path: "/yaralari-bilimle-saralim",
    name: "deprem",
    component: () =>
      import("../views/Deprem.vue")
  },
  // { path: '/:pathMatch(.*)*', redirect: '/404' },
  { path: '*', 
    component: () =>
      import("../views/404.vue") 
  },
];

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // }
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;

    }

    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 }
  },
});

export default router;
