<template>
  <form
    @submit.prevent="submitForm"
    autocomplete="off"
    class="kampanya-olustur d-flex flex-column flex-md-row h-100 w-100"
  >
    <div
      class="
        sides
        left-side
        section-wrapper
        d-flex
        align-items-center
        justify-content-center
      "
    >
      <div
        class="
          w-100
          sides-container
          d-flex
          flex-column
          justify-content-center
          mb-5
        "
      >
        <h3 class="mb-3">Kampanya Oluştur</h3>
        <p class="mb-5">
          1 okulu bilimle buluşturmanın bedeli 18.500 TL’dir. Eğer kampanyanız
          18.500 TL ve katları olursa, bilimle buluşturacağınız okulun il seçebilirsiniz.
        </p>

        <div class="form-wrapper">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="campaignTitle">Kampanya Başlığı</label>
              <span
                class="char-count"
                v-text="
                  '*' + (maxBaslik - form.campaignTitle.length) + ' karakter'
                "
              ></span>
            </div>
            <input
              :maxlength="maxBaslik"
              v-model="form.campaignTitle"
              v-on:input="removeHash"
              v-bind:class="{
                error: $v.form.campaignTitle.$error,
                valid:
                  $v.form.campaignTitle.$dirty &&
                  !$v.form.campaignTitle.$invalid,
              }"
              type="text"
              class="form-control"
            />
            <p class="form-warning" v-if="$v.form.campaignTitle.$invalid">
              Bu alan zorunludur.
            </p>
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="campaignMsg">Kampanya Mesajı</label>
              <span
                class="char-count"
                v-text="'*' + (maxMsg - form.campaignMsg.length) + ' karakter'"
              ></span>
            </div>
            <textarea
             :maxlength="maxMsg"
              v-model="form.campaignMsg"
              v-on:input="$v.form.campaignMsg.$touch"
              v-bind:class="{
                error: $v.form.campaignMsg.$error,
                valid:
                  $v.form.campaignMsg.$dirty && !$v.form.campaignMsg.$invalid,
              }"
              rows="6"
              type="text"
              class="form-control"
            ></textarea>
            <p class="form-warning" v-if="$v.form.campaignMsg.$invalid">
              Bu alan zorunludur.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="sides right-side section-wrapper d-flex flex-column align-items-center justify-content-center">
      <div class="relative-checkbox w-100 d-flex flex-column flex-md-row align-items-md-center justify-content-between">
        <label
          class="custom-checkbox flex-1 mb-3 mb-md-0 d-flex align-items-center"
          >Kampanya Bedelini Belirt <br />(İsteğe bağlı)
          <input
            type="checkbox"
            @click="toggleInput(form.kampanyaBedeli, $event)"
            v-model="form.kampanyaBedeli"
          />
          <span class="checkmark"></span>
        </label>

        <div class="form-group flex-1 w-100" @click="enableInput($event)">
          <div class="d-flex justify-content-between">
            <label for="miktar" class="d-none d-md-block">Miktar</label>
          </div>
          <!-- <input
            :maxlength="maxBaslik"
            v-model="form.miktar" 
            type="number" class="form-control"> -->
          <the-mask
            class="form-control"
            :maxlength="maxBaslik"
            type="tel"
            mask="#######"
            v-model="form.miktar"
          />
        </div>
      </div>

      <div class="form-group w-100 mt-2" v-if="form.miktar > 9999">
        <select class="custom-selectbox mw-100" v-model="form.city">
          <option value="" disabled selected>
            Kampanyanın ilini seç (İsteğe bağlı)
          </option>
          <option
            v-for="(city, index) in allCities"
            :key="index"
            :value="city.id"
          >
            {{ city.title }}
          </option>
        </select>
      </div>

      <div class="relative-checkbox my-3 w-100 d-flex flex-column flex-md-row align-items-md-center justify-content-between">
        <label class="custom-checkbox flex-1 mb-3 mb-md-0 mt-md-0 d-flex align-items-center">
          Kampanya Bitiş Tarihi <br />(İsteğe bağlı)
          <input
            type="checkbox"
            class="ozelgun"
            @click="toggleInput(form.ozelgun, $event)"
            v-model="form.ozelgun"
          />
          <span class="checkmark"></span>
        </label>

        <div class="form-group w-100 flex-1" @click="enableInput($event)">
          <div class="position-relative input-icon d-flex align-items-center clndr">
             <span class="svg-icon icons-calender"></span>
            <flat-pickr class="form-control" :config="config" v-model="form.tarih"   v-bind:class="{
                error: $v.form.tarih.$error,
                valid:
                  $v.form.tarih.$dirty &&
                  !$v.form.tarih.$invalid,
              }">
            </flat-pickr>
           <p class="form-warning" v-if="$v.form.tarih.$invalid">
              Bu alan zorunludur.
            </p>
          </div>
        </div>
      </div>

      <ButtonRounded
        class="ml-auto"
        Text="Devam Et"
        :isRouting="false"
        :disabled="disabled"
      />
    </div>
  </form>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

import ButtonRounded from "@/components/ButtonRounded";
import { required } from "vuelidate/lib/validators";

export default {
  name: "KampanyaOlustur",
  components: {
    ButtonRounded,
    flatPickr,
  },
  data() {
    return {
      maxBaslik: 36,
      maxMsg: 400,
      disabled: false,
      date: null,
      allCities: "",
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        //altFormat: "m / j / Y",
        altInput: false,
        allowInput: false,
        readOnly: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        locale: Turkish,
      },
      form: {
        campaignTitle: "",
        campaignMsg: "",
        tarih: "",
        miktar: "",
        city: "",
        ozelgun: false,
        kampanyaBedeli: false,
      },
    };
  },
  validations: {
    form: {
      campaignTitle: {
        required: required,
      },
      campaignMsg: {
        required: required,
      },
      tarih: {
        // required: required
      },
      // miktar: {
      //   required: required
      // },
      // bagisiGizle: { checked: value => value === true },
      // bagiscilariGizle: { checked: value => value === true },
    },
  },
  mounted() {
    this.getCities();
  },
  methods: {
    removeHash: function(){
      this.form.campaignTitle = this.form.campaignTitle.replace(/[`~!@#$%^&*()_+=?;:'",.<>\{\}\[\]\\\/]/gi, "");
    },
    toggleInput(e, item) {
      let $target = $(item.target).closest("label").siblings().find("input");
      $($target).toggleClass("enabled");

      // $target.prop('disabled', function(i, v) { return !v; });
      $target.val("");
    },
    getCities() {
      this.$api.getAllCities().then((response) => {
        this.allCities = response;
      });
    },
    enableInput(item) {
      let $checkbox = $(item.target)
        .closest(".relative-checkbox")
        .find("input")[0];
      let $input = $(item.target)
        .closest(".relative-checkbox")
        .find("input")[1];

      if (!$($input).hasClass("enabled")) {
        $($input).addClass("enabled");
        // $($input).prop('disabled', function(i, v) { return !v; });
        $($checkbox).prop("checked", true);
      }
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        // this.form.tarih = this.date;
        // this.form.kampanyaBedeli = this.form.miktar > 0 ? true : false;
        // //  this.form.ozelgun = (this.form.tarih = '' ) ? false : true;
        // if ($("input.ozelgun").is(":checked")) {
        //   this.form.tarih = this.date;
        // } else {
        //   this.form.tarih = "";
        // }
        if (this.form.tarih !== "") {
          this.form.ozelgun = true;
        } else {
          this.form.ozelgun = false;
        }
        console.log(this.form);
        this.$store.commit("_createCampaign", this.form);
        this.$emit("clicked", "1");
      } else {
        
      }
    },
  },
};
</script>

<style lang="scss">
.clndr{
  position: relative;
  .form-warning{
    position: absolute;
    left: 0;
    bottom: -25px;
  }
}
form.kampanya-olustur {
  height: calc(100% + 80px) !important;

  @media (max-width: 567px) {
    .section-wrapper {
      &.left-side {
        padding: 2rem 2rem 0 2rem;
      }
      &.right-side {
        padding: 0 2rem 2rem 2rem;
      }
    }
  }
}
</style>