<template>
  <div class="birBagisYap padding-wrapper">
    <div class="d-flex flex-column flex-md-row align-items-end">
      <div class="heading">
        <h3>Sen de Bağış Yap</h3>
        <p class="mt-4">
          Bağışınızı düzenli veya tek seferlik yapabilirsiniz.  
        </p>
      </div>

      <div class="bottom-information text-left text-md-right">
        <Toggle
          :labels="toggleLabels"
          bgColor="orange"
          @toggle-checkbox="checkboxValue($event)"
        />
      </div>
    </div>
    <div class="d-flex flex-wrap flex-lg-nowrap flex-column flex-lg-row my-4">
      <div class="d-grid grid-t-columns-3 flex-3 mr-0 mr-lg-3 mb-3 mb-lg-0">
        <template v-for="item in donationData">
        <div
          v-if="item.type === 'onetime' && formValue.donation_status !== 'duzenli' "
          :key="item.id"
          :id="item.id"
          class="bagis-card bagis-card-static"
          @click.self="
            selectDonation($event, 'bagis', 'auto', item.id, item.price, 1)
          "
        >
          <img src="/svg/check.svg" class="check-icon" />
          <div class="price">{{ item.full_price }}</div>
          <div class="price-card-desc">
            <!-- <p class="d-none d-md-block">{{item.title}}</p> -->
            <span>{{ item.description }}</span>
          </div>
        </div>
        
        <div
          v-if="item.type !== 'onetime' && formValue.donation_status === 'duzenli' "
          :key="item.id"
          :id="item.id"
          class="bagis-card bagis-card-static"
          @click.self="
            selectDonation($event, 'bagis', 'auto', item.id, item.price, 1)
          "
        >
          <img src="/svg/check.svg" class="check-icon" />
          <div class="price">{{ item.full_price }}</div>
          <div class="price-card-desc">
            <!-- <p class="d-none d-md-block">{{item.title}}</p> -->
            <span>{{ item.description }}</span>
          </div>
        </div>
        </template>

      </div>

      <div class="d-grid grid-t-columns-3 flex-3">
        <div class="bagis-card text-donation" @click="selectDonation($event, 'bagis', 'manuel', 0, 0, 1)">
          <img src="/svg/check.svg" class="check-icon" />
          <div class="price-card-desc">
            <the-mask
              :tokens="minVal"
              mask="XYYYYYY"
              placeholder="0 TL"
              v-model="donationValue"
              v-on:input="
                donationValue >= 25
                  ? (bagisCardIsSelected = true)
                  : (bagisCardIsSelected = false)
              "
              type="tel"
              class="price"
            />

            <div class="h-100 d-flex flex-column justify-content-center">
              <p class="d-none d-md-block">
                <span v-if="formValue.donation_status === 'duzenli'">Düzenli<br /></span>
                Bağış Miktarını <br />
                Kendin Belirle
              </p>
              <p class="d-block d-md-none mt-3">
                <b v-if="formValue.donation_status === 'duzenli'">Düzenli </b> Bağış Miktarını Kendin Belirle
              </p>
              <span class="d-none d-md-block">Bağış yapmak istediğin tutarı kendin belirle.</span>
              <span class=" minValWarn" v-if="donationValue >= 1 && donationValue < 25">Minimum bağış miktarı 25TL'dir.</span>
            </div>
          </div>
        </div>

        <div class="bagis-card text-donation set" @click.self="selectDonation( $event, 'bilimseti', 'auto', 0, 15000, 0)">
          <img src="/svg/check.svg" class="check-icon" />
          <div class="price-card-desc">
            <select class="custom-selectbo selectpicker setpicker text-donation" id="selectpickerr"
              v-model="formValue.amount"
              v-on:input="formValue.amount >= 1 ? (bagisCardIsSelected = true) : (bagisCardIsSelected = false)"
              @change="selectDonation($event, 'bilimseti', 'auto', 0, 15000, formValue.amount); bagisCardIsSelected = true;"
            >
              <option value="0" disabled>Seçiniz</option>
              <option value="1">1 Bilim Seti</option>
              <option value="2">2 Bilim Seti</option>
              <option value="3">3 Bilim Seti</option>
              <option value="4">4 Bilim Seti</option>
              <option value="5">5 Bilim Seti</option>
              <option value="6">6 Bilim Seti</option>
              <option value="7">7 Bilim Seti</option>
              <option value="8">8 Bilim Seti</option>
              <option value="9">9 Bilim Seti</option>
              <option value="10">10 Bilim Seti</option>
            </select>

            <div class="h-100 d-flex flex-column justify-content-center">
              <p class="d-none d-md-block">
                <span v-if="formValue.donation_status === 'duzenli'">Düzenli <br/></span>
                Bilim Seti Gönder
              </p>
              <p class="d-block d-md-none mt-3"><b v-if="formValue.donation_status === 'duzenli'">Düzenli</b> Bilim Seti Gönder</p>
              <span class="d-none d-md-block">
                <span>Bir Bilim Setinin bedeli <strong>15.000TL</strong>'dir, her setten <strong>100 çocuk</strong> faydalanır.</span>
              </span>
            </div>
          </div>
        </div>

        <div class="bagis-card text-donation" @click.self="selectDonation( $event, 'bilimseti', 'auto', 0, 60000, 1, formValue.city)">
          <img src="/svg/check.svg" class="check-icon" />
          <div class="price-card-desc">
            <h2 class="d-flex justify-content-center align-items-end">60.000TL<span class="ml-3 mb-1 d-md-none d-block">Bir Okul Sahiplen</span></h2>

            <div class="h-100 d-flex flex-column justify-content-center">
              <p class="d-none d-md-block">Bir Okul Sahiplen</p>
              <span class="d-none d-md-block">400 çocuğu Bilim Seti ile buluştur.</span>
            </div>

            <select class="custom-selectbo selectpicker text-donation" id="selectpicker"
              v-on:input="formValue.city >= 1 ? (bagisCardIsSelected = true) : (bagisCardIsSelected = false)"
              v-model="formValue.city"
              @change="selectDonation($event,'bilimseti', 'auto', 0, 60000, 1, formValue.city); bagisCardIsSelected = true;">
              <option value="0" disabled selected>İl Seç</option>
               <option v-for="(city, index) in cities" :key="index" :value="city.id">{{city.title}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center  flex-md-row justify-content-end bottom-info">
      <!-- <div class="d-flex align-items-center">
        <span class="svg-icon icons-information"></span>
        <p>Düzenli ödeme talimatı verdiğiniz için bağışınız her ayın **/**/**** tarihinde belirlemiş olduğunuz kredi kartından otomatik çekilecektir.</p>
      </div> -->
      <div class="load" v-if="loading"></div>
      <ButtonRounded
        Text="Devam Et"
        :isRouting="false"
        :disabled="!bagisCardIsSelected"
        @click.native="formValidation"
      />
    </div>
  </div>
</template>
<script>
import Toggle from "@/components/Toggle";
import ButtonRounded from "@/components/ButtonRounded";
import "bootstrap-select";
import "bootstrap-select/dist/css/bootstrap-select.css";
export default {
  name: "BirBagisYap",
  components: {
    Toggle,
    ButtonRounded,
  },
  props: ["donationData", "cities"],
  data() {
    return {
      toggleLabels: ["düzenli", "tek sefer"],
      selected: "",
      bagisCardIsSelected: false,
      isDisabled: true,
      donationValue: "",
      allCities: {},
      formValue: {
        donation_type: "",
        selected_type: "",
        donation_status: "duzenli",
        donation_id: 0,
        price: 0,
        amount: 0,
        city: 0,
      },
      loading: false,
      minVal: {
        X: { pattern: /[1-9]/ },
        Y: { pattern: /[0-9]/ }
      }
    };
  },
  mounted() {
    this.getCityList()   
  },
  methods: {
    checkboxValue(e) {
      e == true ? (this.formValue.donation_status = "duzenli") : (this.formValue.donation_status = "teksefer");
      this.$store.commit("_donation_status", this.formValue.donation_status);
    },

    selectDonation(e, type, selected_type, donation_id, price, amount, city) {

      $(".bagis-card").not(e.target).removeClass("active");

      if (
        $(e.target).prop("nodeName") == "SPAN" ||
        $(e.target).prop("nodeName") == "INPUT" ||
        $(e.target).prop("nodeName") == "SELECT" ||
        $(e.target).prop("nodeName") == "BUTTON"
      ) {
        $(e.target).closest(".bagis-card").addClass("active");
      } else {
        e.target.classList.add("active");
      }
      this.bagisCardIsSelected = true;
      if(type == 'bagis' && selected_type == 'manuel'){
        
        this.bagisCardIsSelected = false;
        if(this.donationValue){
          if(this.donationValue < 25 ){
            this.bagisCardIsSelected = false;
          }else{
            this.bagisCardIsSelected = true;
          }
        }else{}
      }
      console.log(`qweqwe`,this.formValue.amount, e);
      // else if(type == 'bilimseti' && selected_type == 'auto'){
      //   console.log(`qweqwe`,this.formValue.amount, e);
      //       this.bagisCardIsSelected = false;
      //   if(this.formValue.amount >= 1){
      //       this.bagisCardIsSelected = false;
      //     }else{
      //       this.bagisCardIsSelected = true;
      //     }
        
      // }
  

      if ($(".text-donation").hasClass("active") && $(".text-donation input").val() == "") {
        this.bagisCardIsSelected = false;
      }

      let selectEl = $(e.target).find("select"); 
      if(selectEl.length > 0 && $(e.target).find("select option:selected").val() > 0){
        this.bagisCardIsSelected = true;
      }
      // let selectSet = $(e.target).find(".setpicker"); 
      // if(selectSet.length > 0 && $(e.target).find("select option:selected").val() >= 1){
      //   // $('.selectpicker').selectpicker('val', 0);
      //   this.bagisCardIsSelected = true;
      //   console.log(`qweqwe`, this.formValue.amount);
      // }else{
      //   this.bagisCardIsSelected = false;
      // }
      this.formValue.donation_type = type;
      this.formValue.selected_type = selected_type;
      this.formValue.donation_id = donation_id;
      var total_price = price * amount;
      this.formValue.price = total_price;
      this.formValue.amount = amount;
      this.formValue.city = city;
      
      this.$store.commit("_donation_status", this.formValue.donation_status);

      // Slide End
      var elem = document.querySelector(".modal-wrapper");
      elem.scrollIntoView({ behavior: "smooth", block: "end" });
    },
    formValidation() {

      // Miktarı Kendin Belirle
      if ( this.formValue.selected_type == "manuel" && this.formValue.donation_type == "bagis") {
        this.formValue.price = this.donationValue;
      }

      this.$store.commit("_formValue", this.formValue);
      this.$store.commit("_selectedDonatinPrice", this.formValue.price);
      // console.log("type", this.formValue);

    
      this.loading = true
      let params = {};
      params['price'] = this.formValue.price
      params['selected_type'] = this.formValue.selected_type 
      params['donation_id'] =  this.formValue.donation_id 
      params['donation_type'] = this.formValue.donation_type
      this.$api.validInfoCreate(params).then((response) => {
        console.log(response);
        if(response.success){
          this.bagisCardIsSelected = true;
          this.bagisCardIsSelected ? this.slideToNext(2) : "";
          var elem = document.querySelector(".modal-wrapper");
          elem.scrollIntoView({ behavior: "smooth" });
        }else{
          this.bagisCardIsSelected = false;
        }
       setTimeout(() => {
        this.loading = false
       }, 1000);
      })
    },
    slideToNext(event) {
      this.$emit("clicked");
      gtag("event", "Bağış Seçimi", {
        event_category: "Bağış",
        event_label: "Bağış Seçimi",
        value: "",
      });
    },
    getCityList() {
      this.$api.getCampaignCity().then((response) => {
        //tekrarları siliyoruz
        function multiDimensionalUnique(arr) {
          var uniques = [];
          var itemsFound = {};
          for (var i = 0, l = arr.length; i < l; i++) {
            var stringified = JSON.stringify(arr[i]);
            if (itemsFound[stringified]) {
              continue;
            }
            uniques.push(arr[i]);
            itemsFound[stringified] = true;
          }
          $("#selectpickerr").selectpicker();
          $("#selectpicker").selectpicker({
            size: '5',
            dropupAuto: true
          });
          return uniques;
        }
        let cities = multiDimensionalUnique(response);
        this.cityList = cities;
        
      });
    },
  },
};
</script>

<style lang="scss">
 @keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}
 

 @-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to { 
        -webkit-transform: rotate(360deg);
    }
}

.load {
  width: 30px;
    margin-right: 1rem;
    height: 30px;
    border: solid 2px #fff;
    border-radius: 50%;
	border-right-color: transparent;
	border-bottom-color: transparent;
	 -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name:             rotate; 
    -webkit-animation-duration:         1.0s; 
    -webkit-animation-iteration-count:  infinite;
    -webkit-animation-timing-function: linear;
    	
    	 transition: all 0.5s ease-in;
    animation-name:             rotate; 
    animation-duration:         1.0s; 
    animation-iteration-count:  infinite;
    animation-timing-function: linear; 
}
.check-icon{
  pointer-events: none  !important;
}
.bootstrap-select {
  width: 100% !important;
  .dropdown-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='gray'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-size: 14px !important;
    background-position: calc(100% - 10px) 26px !important;
    background-repeat: no-repeat;
    background-color: #dae3f3 !important;
    border-radius: 8px;
    border-color: #dae3f3 !important;
    height: 60px;
    pointer-events: auto;
    padding: 15px;
    &:focus {
      outline: unset !important;
    }
    &::after {
      display: none !important;
    }
  }
}
.bootstrap-select .dropdown-menu.inner{
  max-height: 200px;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--purpleDark) !important;
}
.bagis-card {
  .btn-light.focus,
  .btn-light:focus {
    outline: unset !important;
  }
}
.bootstrap-select .dropdown-menu {
  pointer-events: auto;
}
.birBagisYap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .heading {
    width: 50%;
  }
  .bottom-information {
    width: 50%;
  }
  .bagis-card {
    background: #fff;
    color: var(--purpleDark);
    text-align: center;
    transition: var(--fast);
    margin: 2rem 1rem 1.5rem 0;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:last-child {
      margin-right: 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 5px solid #fff;
      border-radius: 10px;
      z-index: -1;
      opacity: 0;
      transition: var(--slow);
    }
    &:hover {
      &:before {
        border: 5px solid var(--orange);
      }
    }
    &.active {
      color: #fff;
      &:before {
        opacity: 1;
        background: var(--gradientOrange);
        border: 5px solid var(--gradientOrange);
      }
      input.price {
        color: #fff;
        background: transparent;
        border: 1px solid var(--yellow);
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #fff !important;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #fff !important;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #fff !important;
        }
      }
    }
    &:hover,
    &.active {
      box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.5);
      .price {
        // border-bottom: 1px solid rgba(240,239,250, .1);
      }
    }
    &.active {
      .check-icon {
        display: block;
      }
    }


    &.bagis-card-static{
      display: flex;
      justify-content: center;
      
      .price-card-desc{
        height: auto;
      }
      // flex: 1 1 auto;
      // width: 45%;
      // margin: 0 0 1rem 0;
      // &:first-child{
      //   margin-right: 1rem;
      // }
      // &:last-child{
      //   margin: 0;
      // }
    }

    .price {
      font-size: 2rem;
      font-weight: bold;
      // border-bottom: 1px solid var(--borderBlue);
      // padding-bottom: 1rem;
      margin-bottom: 1rem;
      pointer-events: none;
    }
    input.price {
      color: var(--purpleDark);
      border: 1px solid var(--borderBlue);
      border-radius: 5px;
      // height: 90px;
      max-width: 200px;
      // padding-bottom: 0;
      // text-align: center;
      pointer-events: auto;

      background: #dae3f3;
      min-height: 60px;
      text-align: right;
      padding: 0 1rem;
    }
    .price-card-desc {
      pointer-events: none;
      p {
        pointer-events: none;
        font-weight: bold;
        font-size: 1rem;
        margin: 0.5rem 0;
      }

      .minValWarn{
        font-size: 0.9rem;
        font-weight: bold;
        margin-top: 0.5rem;
      }
    }
    .check-icon {
      display: none;
      position: absolute;
      top: -8px;
      right: -8px;
      @media (max-width: 576px) {
        top: -15px;
        right: -15px;
      }
    }
    .count {
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      span {
        background: var(--purpleDark);
        color: #fff;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        display: inline-block;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin: 0 -50%;
        position: absolute;
        top: calc(50% - 0.5rem);
        font-size: 1.1rem;
        font-weight: 600;
        pointer-events: auto;
        &:first-child {
          transform: translateY(-50%);
          left: -1rem;
        }
        &:last-child {
          transform: translateY(-50%);
          right: -1rem;
        }
      }
    }
  }
  .svg-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }

  @media (max-width: 1550px) {
    .bagis-card {
      padding: 2rem 1.5rem;
    }
  }

  @media (max-width: 1366px) {
    .bagis-card input.price {
      max-width: 130px;
    }
  }

  @media (min-width: 567px) and (max-width: 1024px) {
    .bagis-card {
      margin: 0 2px 0.5rem 0;
      padding: 1.5rem 1rem;
      width: 100%;
      &:last-child {
        // margin: 0 .5rem .5rem 0;
        width: 100%;
        input.price {
          width: 50%;
          max-width: unset;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .d-grid{
      .bagis-card{
        margin: 10px 0px !important;
      }
    }
    .heading {
      width: 100%;
      margin-bottom: 1rem;
    }
    .bottom-information {
      width: 100%;
      margin-bottom: 1rem;
    }
    .bagis-card {
      width: 100%;
      margin: 10px 0px !important;
      input.price {
        max-width: 130px;
        height: 60px;
      }

      .price-card-desc {
        p {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        span {
          font-size: 0.9rem;
        }
      }

      .count span {
        line-height: 1.9;
        width: 35px;
        height: 35px;
        font-size: 1.4rem;
      }
    }

    .bottom-info {
      padding: 1rem 0 4rem 0;
      .svg-icon {
        width: 5rem;
      }

      .bottom-information {
        padding-bottom: 2rem;
        h3 {
          margin-bottom: 1rem;
        }
      }

      .button-rounded {
        width: 100%;
      }
    }
  }

  @media (max-width: 567px) {
    .bagis-card {
      &:last-child input.price {
        width: 100%;
      }
      .price {
        font-size: 2rem;
      }
    }
  }
}

.d-grid {
  display: grid;
  grid-gap: 1rem;

  /*  IE BUG  */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* detect IE */

    display: flex;
    flex-wrap: wrap;
    .bagis-card{
      width: 46%;
      margin: 10px
    }
    
  }
  /*  IE BUG  */
  .bagis-card {
    .price-card-desc {
      width: 100%;
    }
    margin: 0;
    justify-content: space-between;
    .price {
      margin: 0;
    }

    input.price {
      background: #dae3f3;
      color: var(--purpleDark);
      // height: 60px;
      text-align: right;
      padding: 0 1rem;
      width: 100%;
      max-width: unset;
    }

    .custom-selectbox {
      background-color: #dae3f3 !important;
      border-radius: 8px;
      border-color: #dae3f3 !important;
      height: 60px;
      pointer-events: auto;
    }

    .price-card-desc {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h2 {
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 567px) {
    .bagis-card {
      width: 100%;
    }
  }
}

.grid-t-columns-1 {
  grid-template-columns: 1fr;
}
.grid-t-columns-2 {
  grid-template-columns: 1fr 1fr;
}
.grid-t-columns-3 {
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 567px) {
    grid-template-columns: 1fr;
  }
}
.grid-t-columns-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
</style>