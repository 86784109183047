<template>
  <div v-if="isVisible" class="cookie-bar d-flex flex-column flex-md-row align-items-center justify-content-between">
    <div>
      <span>
        Sitemizi kullanarak çerezlere izin vermektesiniz. Detaylı bilgi için <a target="_blank" href="https://bilimseferberligi.org/aydinlatma-ve-riza-metni" class="text-underline">Çerez Politika'mızı</a> inceleyebilirsiniz. 
      </span>
    </div>

    <div class="button-container">
      <ButtonRounded
        Text="Kabul Et"
        btnSize="small"
        color="orange"
        :isRouting="false"
        @click.native="exceptCookies"
      />
    </div>

  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";

export default {
  data(){
    return{
      isVisible: true,
    }
  },
  components: {
    ButtonRounded
  },
  mounted(){
    if(localStorage.getItem('cookie-accepted')){
      this.isVisible = false;
    }
  },
  methods:{
    exceptCookies(){
      let cookieBar = document.querySelector(".cookie-bar");
      cookieBar.classList.add("hide");
      localStorage.setItem('cookie-accepted', true);
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie-bar{
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  padding: 1.5rem 2rem;
  background: #fff;
  color: var(--purpleDark);
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
  transition: var(--fast);
  text-align: left;
  &.hide{
    transform: translateY(100%);
  }

  a{
    font-weight: 500;
  }

  @media (max-width: 567px) {
    padding: 1rem;
    text-align: center; 
    .button-container{
      margin-top: 1rem;
    }
  }
}
</style>