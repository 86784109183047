<template>
  <div class="odeme-wrapper padding-section-wrapper d-flex">
    <modalPayment
      :data="modalData"
      :modalMsg="modalMsg"
      :success="modalSuccess"
      ref="modalPayment"
    ></modalPayment>

    <div
      class="
        sides
        left-side
        section-wrapper
        d-none d-md-flex
        align-items-center
        justify-content-center
      "
    >
      <div class="sides-container d-flex flex-column justify-content-around">
        <h3>Ödeme</h3>
        <img src="/svg/odeme.svg" />
        <div>
          <div class="w-100 d-flex justify-content-between align-items-center">
          <!-- <h2>{{ this.$store.state.userInfo.total_price }}</h2> -->
           <h2>{{this.$store.state.formValue.price}} TL <span v-if="this.$store.state.formValue.donation_status === 'duzenli'">- aylık düzenli bağış</span></h2>
           
          </div>
          <p>
            Yapacağınız bağış ile Türkiye’nin dört bir köşesindeki çocuklara son
            teknoloji bilim setleri gönderilmesine katkı sağladığınız için
            teşekkür ederiz.
            <br>
            
          </p>
         <div class="d-flex justify-content-between align-items-end">
<p class="min">Kart bilgileriniz Iyzico altyapısında saklanmaktadır.</p>
<img src="/svg/iyzico.svg" class="iyzi"/>
         </div>
        </div>
      </div>
    </div>

    <div
      class="
        sides
        right-side
        section-wrapper
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
    >
      <div class="d-block d-md-none mb-5">
        <h3>Ödeme</h3>
        <div>
          <!-- <h2>{{ this.$store.state.userInfo.total_price }}</h2> -->
          <h2>{{this.$store.state.formValue.price}} TL<span v-if="this.$store.state.formValue.donation_status === 'duzenli'"> - aylık düzenli bağış</span></h2>

          <p>
            Yapacağınız bağış ile Türkiye’nin dört bir köşesindeki çocuklara son
            teknoloji bilim setleri gönderilmesine katkı sağladığınız için
            teşekkür ederiz.
          </p>
        </div>
      </div>

      <form @submit.prevent="submitForm" autocomplete="off" class="w-100">
        <loading
          :active.sync="isLoading"
          background-color="#332abb"
          :opacity="0.99"
          color="#fff"
          :is-full-page="fullPage"
        ></loading>
        <div class="form-group">
          <label for="cardName">Kart Üzerindeki İsim</label>
          <input
            name="ccname"
            id="frmNameCC"
            autocomplete="cc-name"
            v-model="form.cardName"
            v-on:input="removeSpace"
            v-bind:class="{
              error: $v.form.cardName.$error,
              valid: $v.form.cardName.$dirty && !$v.form.cardName.$invalid,
            }"
            type="text"
            class="form-control credit-card-name"
          />
          <p class="form-warning" v-if="!$v.form.cardName.alpha">
            <span class="svg-icon icons-alert"></span>Ad Soyad şeklinde giriniz.
          </p>
          <p class="form-warning" v-if="!$v.form.cardName.nameSurnameLength">
            <span class="svg-icon icons-alert"></span>En az 2 karakter
            girmelisiniz
          </p>
          <p class="form-warning" v-if="!$v.form.cardName.required">
            <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
          </p>
          <p class="form-warning" v-if="!$v.form.cardName.minLength">
            <span class="svg-icon icons-alert"></span>En az 2 karakter
            girmelisiniz.
          </p>
        </div>

        <div class="form-group">
          <label for="cardNo">Kart Numarası</label>
          <the-mask
            name="cardnumber"
            id="frmCCNum"
            autocomplete="cc-number"
            mask="#### #### #### ####"
            placeholder="---- ---- ---- -----"
            v-model="form.cardNo"
            v-bind:class="{
              error: $v.form.cardNo.$error,
              valid: $v.form.cardNo.$dirty && !$v.form.cardNo.$invalid,
            }"
            type="tel"
            class="form-control"
          />
          <p class="form-warning" v-if="!$v.form.cardNo.required">
            <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
          </p>
          <p class="form-warning" v-if="!$v.form.cardNo.minLength">
            <span class="svg-icon icons-alert"></span>Yanlış kart formatı.
          </p>
        </div>

        <div class="row">
          <div class="col-7 d-flex justify-content-between">
            <div class="form-group w-100 mr-4">
              <label for="cardMonth">Ay</label>
              <select
                class="custom-selectbox"
                v-model="form.selectedMonth"
                v-on:input="form.selectedMonth = form.selectedMonth"
                v-bind:class="{
                  error: $v.form.selectedMonth.$error,
                  valid:
                    $v.form.selectedMonth.$dirty &&
                    !$v.form.selectedMonth.$invalid,
                }"
              >
                <option value="" disabled selected>Ay</option>
                <option
                  v-bind:value="n < 10 ? '0' + n : n"
                  v-for="n in 12"
                  v-bind:disabled="n < minCardMonth"
                  v-bind:key="n"
                  v-html="n < 10 ? '0' + n : n "
                >
                  
                </option>
              </select>
            </div>

            <div class="form-group w-100">
              <label for="cardYear">Yıl</label>
              <select
                class="custom-selectbox"
                v-model="form.selectedYear"
                v-on:input="form.selectedYear = form.selectedYear"
                v-bind:class="{
                  error: $v.form.selectedYear.$error,
                  valid:
                    $v.form.selectedYear.$dirty &&
                    !$v.form.selectedYear.$invalid,
                }"
              >
                <option value="" disabled selected>Yıl</option>
                <option
                  v-bind:value="$index + minCardYear"
                  v-for="(n, $index) in 12"
                  v-bind:key="n"
                >
                  {{ $index + minCardYear }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-5">
            <div class="form-group">
              <label for="cardCvc">CVC</label>
              <the-mask
                class="form-control"
                type="tel"
                name="cvc"
                id="frmCCCVC"
                autocomplete="cc-csc"
                mask="####"
                v-model="form.cardCvc"
                v-bind:class="{
                  error: $v.form.cardCvc.$error,
                  valid: $v.form.cardCvc.$dirty && !$v.form.cardCvc.$invalid,
                }"
              />
            </div>
          </div>
        </div>

        <!-- <label class="custom-checkbox"> <span>Kredi Kartımdan </span> çekilmesini Kabul ediyorum.
          <input type="checkbox"
            v-model="form.terms_accepted"
            v-bind:class="{error: $v.form.terms_accepted.$error, valid: $v.form.terms_accepted.$dirty && !$v.form.terms_accepted.$invalid}"
          >
          <span class="checkmark"></span>
        </label> -->
        <ButtonRounded
          class="float-right"
          Text="Ödemeyi Tamamla"
          :isRouting="false"
          :disabled="disabled"
        />
          <div class="w-100 d-flex d-md-none align-items-center justify-content-center">
            <img src="/svg/iyzico.svg" class="iyzi mt-3 ">
          </div>
      </form>
    </div>
  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import ModalPayment from "@/components/ModalPayment";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import {
  integer,
  required,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";

const nameSurnameLength = (value) => {
  let isValid = true;
  let tempValue = value.trim()
  tempValue.split(" ").forEach((e) => {
    if (e.length < 2) {
      isValid = false;
    }
  });
  return isValid;
};

export default {
  name: "Odeme",
  props: ["donationType"],
  components: {
    ButtonRounded,
    ModalPayment,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      price: "",
      paymentResponse: "",
      modalSuccess: false,
      modalData: "",
      modalMsg: "",
      minCardYear: new Date().getFullYear(),
      disabled: false,
      form: {
        cardName: "",
        cardNo: "",
        selectedMonth: "",
        selectedYear: "",
        cardCvc: "",
        terms_accepted: false,
      },
    };
  },
  computed: {
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },
  },
  validations: {
    form: {
      cardName: {
        required,
        nameSurnameLength,
        alpha: helpers.regex(
          "alpha",
          /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/i
        ),
        minLength: minLength(2),
      },
      cardNo: {
        required,
        integer: integer,
        minLength: minLength(16),
      },
      cardCvc: {
        required,
        maxLength: minLength(3),
        maxLength: maxLength(4),
      },
      // terms_accepted: {
      //   checked: value => value === true
      // },
      selectedMonth: {
        required,
      },
      selectedYear: {
        required,
      },
    },
  },
  methods: {
    doAjax() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    showModalPayment() {
      this.$refs.modalPayment.openModal();
    },
    closeModalPayment() {
      this.$refs.modalPayment.closeModal();
    },
    removeSpace: function (event) {
      this.form.cardName = this.form.cardName.replace(/\s\s+/g, " ");
    },
    payment(data) {
      let params = {};
      
      params["conversation_id"] = this.$store.state.conversation_id;
      params["card_holder_name"] = data.cardName.$model.trim();
      params["card_number"] = data.cardNo.$model;
      params["expire_month"] = data.selectedMonth.$model;
      params["expire_year"] = data.selectedYear.$model;
      params["cvc"] = data.cardCvc.$model;

      this.isLoading = true;
      this.disabled = true;

      if (this.donationType === "standartDonate") {
        if (this.$store.state.donation_status == "teksefer") {
          // Tek Sefer
          this.$api.paymentTeksefer(params).then((response) => {
            this.showModalPayment();
            this.modalSuccess = response.success;

            if (response.success) {
              var elem = document.querySelector(".modal-wrapper");
              elem.scrollIntoView({ behavior: "smooth" });

              this.disabled = false;

              this.modalData = response.redirect3D;
              this.isLoading = false;
              this.checkStatus();
              gtag("event", "Bağış Ödeme Adımı (Teksefer)", {
                event_category: "Bağış",
                event_label: "Bağış Ödeme Adımı (Teksefer)",
                value: "",
              });
            } else {
              this.modalMsg = response;
              this.disabled = false;
              this.isLoading = false;
            }
          });
        } else if (this.$store.state.donation_status == "duzenli") {
          // Duzenli
          this.$api.paymentDuzenli(params).then((response) => {
            this.showModalPayment();

            this.modalSuccess = response.success;
            if (response.success) {
              var elem = document.querySelector(".modal-wrapper");
              elem.scrollIntoView({ behavior: "smooth" });
              this.isLoading = false;
              this.closeModalPayment();
              this.$emit("clicked", "1");
              this.disabled = false;
              this.modalMsg = response;
              gtag("event", "Bağış Ödeme Adımı (Düzenli)", {
                event_category: "Bağış",
                event_label: "Bağış Ödeme Adımı (Düzenli)",
                value: "",
              });
              // this.modalData = response.redirect3D;
              // this.checkStatus();
            } else {
              this.modalMsg = response;

              this.disabled = false;
              this.isLoading = false;
            }
          });
        }
      } else if (this.donationType === "campaignDonate") {
        this.$api.campaignPaymentTeksefer(params).then((response) => {
          this.showModalPayment();
          this.modalSuccess = response.success;
          if (response.success) {
            var elem = document.querySelector(".modal-wrapper");
            elem.scrollIntoView({ behavior: "smooth" });
            this.disabled = false;
            this.modalData = response.redirect3D;
            this.isLoading = false;
            //this.checkStatus();
            this.campaignCheckStatus();
          } else {
            this.modalSuccess = false;
            this.modalMsg = response;
            this.disabled = false;
            this.isLoading = false;
          }
        });
      } else if (this.donationType === "giftCardDonate") {
        this.$api.giftCardPaymentTeksefer(params).then((response) => {
          this.showModalPayment();
          this.modalSuccess = response.success;
          if (response.success) {
            var elem = document.querySelector(".modal-wrapper");
            elem.scrollIntoView({ behavior: "smooth" });

            this.disabled = false;

            this.modalData = response.redirect3D;
            this.isLoading = false;
            this.giftCheckStatus();
          } else {
            this.modalMsg = response;
            this.disabled = false;
            this.isLoading = false;
          }
        });
      }
    },
    submitForm() {
      this.$v.$touch();
      
      if (!this.$v.form.$invalid) {
        this.payment(this.$v.form);
      } else {
        
      }
    },
    checkStatus() {
      // console.log("pending...");
      let params = {};
      params["conversation_id"] = this.$store.state.conversation_id;

      let interval = setInterval(() => {
        this.$api.checkStatus(params).then((response) => {
          
          this.modalSuccess = true;

          if (response.status == "success") {
            this.isLoading = false;
            clearInterval(interval);
            this.closeModalPayment();
            this.$emit("clicked", "1");
              fbq('track', 'FormSuccess', {
        conversion_id: this.$store.state.conversation_id, 
        value: this.$store.state.userInfo.total_price,
        donation_type: this.$store.state.formValue.donation_status,
        currency: 'TRY' 
      });          
          } else if (response.status == "error") {
            /*  $(".payment-modal iframe").remove();*/
            clearInterval(interval);
            this.modalSuccess = false;
            this.modalMsg = response;
          }
        });
      }, 1000);
    },
    campaignCheckStatus() {
      // console.log("pending...");
      let params = {};
      params["conversation_id"] = this.$store.state.conversation_id;

      let interval = setInterval(() => {
        this.$api.campaignCheckStatus(params).then((response) => {
          this.modalSuccess = true;

          if (response.status == "success") {
            this.isLoading = false;
            clearInterval(interval);
            this.closeModalPayment();
            this.$emit("clicked", "1");
              fbq('track', 'FormSuccess', {
        conversion_id: this.$store.state.conversation_id, 
        value: this.$store.state.userInfo.total_price,
        donation_type: this.$store.state.formValue.donation_status,
        currency: 'TRY' 
      });          
          } else if (response.status == "error") {
            /*  $(".payment-modal iframe").remove();*/
            clearInterval(interval);
            this.modalSuccess = false;
            this.modalMsg = response;
          }
        });
      }, 1000);
    },
    giftCheckStatus() {
      // console.log("pending...");
      let params = {};
      params["conversation_id"] = this.$store.state.conversation_id;

      let interval = setInterval(() => {
        this.$api.giftCheckStatus(params).then((response) => {

          this.modalSuccess = true;

          if (response.status == "success") {
            this.isLoading = false;
            clearInterval(interval);
            this.closeModalPayment();
            this.$emit("clicked", "1");
              fbq('track', 'FormSuccess', {
        conversion_id: this.$store.state.conversation_id, 
        value: this.$store.state.userInfo.total_price,
        donation_type: this.$store.state.formValue.donation_status,
        currency: 'TRY' 
      });          
          } else if (response.status == "error") {
            /*  $(".payment-modal iframe").remove();*/
            clearInterval(interval);
            this.modalSuccess = false;
            this.modalMsg = response;
          }
        });
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.credit-card-name {
  text-transform: uppercase;
}
.min{
  font-size: 14px;
}
.iyzi{
  max-height: 50px;
}
.odeme-wrapper {
  height: 100%;
}

@media (max-wdith: 768px) {
  .odeme-wrapper {
    height: auto;
  }
}

.sides {
  height: 100%;
  width: 50vw;

  .sides-container {
    height: 100%;
    // width: 450px;
    img {
      margin: 0px 0;
    }

    h2 {
      font-size: 3rem;
      margin-bottom: 1rem;
      span{ 
        font-size: 1.3rem;
      }
    }
  }
}

.left-side {
  background: var(--purpleMid);
}

.right-side {
  background: var(--blueOcean);
}
</style>
