<template>
  <div>
    <div :class="'swiper-overflow-card text-center ' + bg" >
    <p>{{carddData.desc}} </p>
    <h4>{{carddData.name_surname}}</h4>
    <span>{{carddData.school}}</span>

    <div class="play-video" @click="openVideo">
      <img src="/svg/icon-video.svg">
      <span>Videoyu İzle</span>
    </div>
    <!-- <a :href="carddData.video" target="_blank">
    </a> -->
  </div>
  </div>
</template>

<script>
export default {
  name: "FloatingCard",
  props: ["bg", "carddData"],
  methods: {
    openVideo(event){
      this.$emit("clicked", this.carddData.video);
    }
  }
}
</script>

<style lang="scss">


.swiper-overflow-card{
  &.green{ background: var(--green) }
  &.orange{ background: var(--orange) }
  border-radius: 20px;
  color: #fff;
  max-width: 360px;
  min-height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2.5rem 2rem;
  h4{
    //margin-top: 4rem;
    font-size: 1.6rem;
  }
  span{
    color: var(--whiteOld);
  }

  .play-video{
    margin-top: 2rem;
    cursor: pointer;
    span{
      text-decoration: underline;
    }
    img{
      width: 24px;
      height: 24px;
      aspect-ratio: 1 / 1;
      margin-right: .2rem;
    }
  }
  @media (max-width: 1200px){
    padding: 1.5rem;
      h4{
    //margin-top: 4rem;
    font-size: 1.2rem;
  }
  .play-video{
    margin-top: 1rem;
  }
  }
  
  @media (max-width: 567px){
    min-height: 200px;
    h4{
      margin-top: 2rem;
    }
    .play-video{
      margin-top: 2rem;
    }
  }

}
</style>