<template>
  <div class="d-flex w-100 h-100 signup">
    <div class="sides left-side section-wrapper d-flex flex-column align-items-start justify-content-center">
      <loading :active.sync="isLoading" 
      background-color="#332abb"
      :opacity= 0.99
      color="#fff"
      :is-full-page="fullPage"></loading>

      <form @submit.prevent="submitForm" autocomplete="off" class="w-100">
        <h2>Kayıt Ol</h2>
        
        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label for="userName">Adınız</label>
            <input 
              v-model="form.userName" 
              v-on:input="removeSpace"
              v-bind:class="{error: $v.form.userName.$error, valid: $v.form.userName.$dirty && !$v.form.userName.$invalid}"
              type="text" class="form-control">
            <p class="form-warning" v-if="!$v.form.userName.nameSurnameLength">
              <span class="svg-icon icons-alert"></span> En az 2 karakter girmelisiniz</p>
            <p class="form-warning" v-if="!$v.form.userName.minLength">
              <span class="svg-icon icons-alert"></span> En az 2 karakter girmelisiniz</p>
            <p class="form-warning" v-if="!$v.form.userName.required">
              <span class="svg-icon icons-alert"></span> Bu alan zorunludur.</p>
          </div>
          
          <div class="form-group col-12 col-md-6">
            <label for="userSurname">Soyadınız</label>
            <input 
              v-model="form.userSurname" 
              v-on:input="removeSpace"
              v-bind:class="{error: $v.form.userSurname.$error, valid: $v.form.userSurname.$dirty && !$v.form.userSurname.$invalid}"
              type="text" class="form-control">
            <p class="form-warning" v-if="!$v.form.userSurname.nameSurnameLength">
              <span class="svg-icon icons-alert"></span> En az 2 karakter girmelisiniz</p>
            <p class="form-warning" v-if="!$v.form.userSurname.minLength">
              <span class="svg-icon icons-alert"></span> En az 2 karakter girmelisiniz</p>
            <p class="form-warning" v-if="!$v.form.userSurname.required">
              <span class="svg-icon icons-alert"></span> Bu alan zorunludur.</p>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label for="userMail">E-Posta</label>
            <input 
              v-model="form.userMail" 
              v-on:keydown='key'
              v-bind:class="{error: $v.form.userMail.$error, valid: $v.form.userMail.$dirty && !$v.form.userMail.$invalid}"
              inputmode="email" type="email" class="form-control">
            <p class="form-warning" v-if="!$v.form.userMail.email">
              <span class="svg-icon icons-alert"></span>Yanlış mail formatı</p>
            <p class="form-warning" v-if="!$v.form.userMail.required">
              <span class="svg-icon icons-alert"></span>Bu alan zorunludur.</p>
          </div>
          
          <div class="form-group col-12 col-md-6">
            <label for="userPhone">Telefon Numarası</label>
              <div class="d-flex">
          
                 <vue-country-code v-model="dial"
                  @onSelect="onSelect"
                  defaultCountry="TR"
                  :preferredCountries="['vn', 'us', 'gb']">
              </vue-country-code>
              <the-mask
                name="phone" id="frmPhoneNumA" autocomplete="tel"
                mask="(###) ### ## ##"
                placeholder="(---) --- -- --"
                v-model="form.userPhone" 
                v-bind:class="{error: $v.form.userPhone.$error, valid: $v.form.userPhone.$dirty && !$v.form.userPhone.$invalid}"
                type="tel" class="form-control" />
              </div>
              <p class="form-warning" v-if="!$v.form.userPhone.required">
                <span class="svg-icon icons-alert"></span>Bu alan zorunludur.</p>
              <p class="form-warning" v-if="!$v.form.userPhone.minLength">
                <span class="svg-icon icons-alert"></span>Yanlış telefon formatı</p>
          </div>
        </div>

        <div class="form-group password position-relative">
          <label for="userPass">Şifre</label>
          <input class="form-control" type="password"
            v-model="form.userPass" 
            v-on:input="removeSpace"
            v-bind:class="{error: $v.form.userPass.$error, valid: $v.form.userPass.$dirty && !$v.form.userPass.$invalid}"
            >
          <p class="form-warning" v-if="!$v.form.userPass.required">
            <span class="svg-icon icons-alert"></span> Bu alan zorunludur.</p>
          <p class="form-warning" v-if="!$v.form.userPass.minLength">
            <span class="svg-icon icons-alert"></span> En az 2 karakter girmelisiniz</p>
          <span class="svg-icon icons-eye" @click="showPassword($event)"></span>
        </div>
        
        <div class="form-group password position-relative">
          <label for="passRepeat">Şifre Tekrar</label>
          <input class="form-control" type="password"
            v-model="form.passRepeat" 
            v-on:input="removeSpace"
            v-bind:class="{error: $v.form.passRepeat.$error, valid: $v.form.passRepeat.$dirty && !$v.form.passRepeat.$invalid}"
            >
          <p class="form-warning" v-if="!$v.form.passRepeat.sameAsPassword">
            <span class="svg-icon icons-alert"></span> Şifreler uyuşmuyor.</p>
          <span class="svg-icon icons-eye" @click="showPassword($event)"></span>
        </div>


        <label class="custom-checkbox flex-wrap d-flex align-items-center"><a class="mx-1" href="/kvkk" target="_blank">KVKK</a> ve  <a href="/aydinlatma-ve-riza-metni" class="mx-1" target="_blank"> Aydınlatma metnini</a>  okudum, onaylıyorum.
          <input type="checkbox"
          v-model="form.kvkk"
          v-bind:class="{error: $v.form.kvkk.$error, valid: $v.form.kvkk.$dirty && !$v.form.kvkk.$invalid}"
          >
          <span class="checkmark"></span>
        </label>
        
        <label class="custom-checkbox d-flex align-items-center flex-wrap">13 yaş üzeriyim veya ebeveyn rızam var <a class="mx-1" href="/aydinlatma-ve-riza-metni" target="_blank"> okuyun.</a>
          <input type="checkbox"
          v-model="form.riza"
          v-bind:class="{error: $v.form.riza.$error, valid: $v.form.riza.$dirty && !$v.form.riza.$invalid}"
          >
          <span class="checkmark"></span>
        </label>
    
        <ButtonRounded class="float-right"  v-if="!successRegister"
        Text="Kayıt Ol"
        :isRouting="false"
        :disabled="disabled"
        />
            <p class="mt-3 mt-md-0" v-if="successRegister">Kaydınız oluşturuldu ! Giriş ekranına yönlendiriliyorsunuz...</p>
      </form>

      <div class="w-100 d-flex align-items-center flex-column flex-xl-row justify-content-between mt-5">
        <p class="w-100 d-none d-md-inline max-text">Ya da Kayıt Ol</p> 
        <div class="d-flex flex-column flex-md-row w-100">
          <ButtonRounded class="mr-0 mr-md-4 mb-2 mb-md-0"
            Text="Google ile Bağlan"
            :isRouting="false"
            @click.native="loginGoogle"
            color="blue"
            btnSize="small"
            Icon="icons-google"
          />
          
          <ButtonRounded
            Text="Facebook ile Bağlan"
            :isRouting="false"
            @click.native="loginFacebook"
            color="blue"
            btnSize="small"
            Icon="icons-facebook-flat"
          />
        </div>
      </div>
    </div>
   <ModalSocial ref="SocialLoginModal" :modalMsg="modalMessage"></ModalSocial>
    <div class="sides right-side section-wrapper d-none d-md-flex flex-column align-items-center justify-content-between position-relative">
      <div class="h-100 d-flex flex-column justify-content-center">
        <img class="" src="/svg/login.svg">
      </div>
      
      <button @click="closeModal" type="button" class="back-button ml-auto d-flex align-items-center">
        <span class="svg-icon icons-back-arrow mr-1"></span>
        <p class="d-flex flex-column align-items-start">
          Geri Dön
        </p>
      </button>
    </div>

  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import ModalSocial from "@/components/SocialLoginModal";
import 'vue-loading-overlay/dist/vue-loading.css';

import {required, email, minLength, sameAs} from 'vuelidate/lib/validators';

const nameSurnameLength = (value) => {
  let isValid = true;
  value.split(" ").forEach(e => {
    if(e.length < 2){
      isValid = false;
    }
  });
  return isValid;
}


export default {
  name: "SignUp",
  components: {
    ButtonRounded, Loading,ModalSocial
  },
  props: [ "formValue" ],
  data(){
    return{
      disabled: false,
      isLoading: false,
      fullPage: false,
      modalMessage: '',
      successRegister: false,
      form: {
        userName: '',
        userSurname: '',
        userMail: '',
        userPhone: '',
        userPass: '',
        kvkk: false,
        riza: false,
      },
      dial : '90',
    }
  },
  validations: {
    form:{
      userName: {
        required,
        nameSurnameLength,
        minLength: minLength(2),
      },
      userSurname: {
        required,
        nameSurnameLength,
        minLength: minLength(2),
      },
      userMail: {
        required,
        email: email
      },
      userPhone: {
        required,
        minLength: minLength(10),
      },
      userPass: {
        required,
        minLength: minLength(2),
      },
      passRepeat: {
        sameAsPassword: sameAs('userPass')
      },
      kvkk: { checked: value => value === true },
      riza: { checked: value => value === true },
    }
  },
  methods: {
      onSelect({dialCode}) {
       this.dial = dialCode;
     },
    closeModal(){
      this.$parent.destroyCarousel();
      this.$parent.closeModal();
    },
    doAjax() {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        },1500)
    },
    showModalSocial() {
      this.$refs.SocialLoginModal.openModal();
    },
    loginFacebook(){
      window.location.href="https://bilimseferberligi.org/cms/api/v1/auth/login/facebook"
    },
    loginGoogle(){
      window.location.href="https://bilimseferberligi.org/cms/api/v1/auth/login/google"
    },
    removeSpace: function(event){
      this.form.userName = this.form.userName.replace( /\s\s+/g, ' ' ).trimStart();
      this.form.userName = this.form.userName.replace(/[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
      
      this.form.userSurname = this.form.userSurname.replace( /\s\s+/g, ' ' ).trimStart();
      this.form.userSurname = this.form.userSurname.replace(/[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
    },
    slideToPage (event) {
      this.$emit('clicked', event);
    },
    key: function(event){
      if(event.keyCode === 32){
        event.preventDefault();
      }
    },
    showPassword(e){
      var $target = $(e.target).siblings('input');
      
      if ($target.attr('type') == "password" ) {
        $target.attr('type', 'text');
      }
      else{
        $target.attr('type','password');
      }
    },
    getinfoCreate(data){
      // console.log("data",typeof(data.userName.$model.split(" ")));
      let params = {}

      params["name"] = data.userName.$model;
      params["surname"] = data.userSurname.$model.split(' ').slice(-1).join(' ');
      params["email"] = data.userMail.$model;
      params["phone"] = data.userPhone.$model;
      params["phone_code"] = '+' + this.dial;
      params["password"] = data.userPass.$model;
      params["password_confirmation"] = data.passRepeat.$model;
      
      this.disabled = true;
      this.doAjax();

      this.$api.registerUser(params).then(response => {
        if(response.success){
          this.disabled = false;
          var elem = document.querySelector(".modal-wrapper");
          elem.scrollIntoView({behavior: "smooth"});
          this.successRegister = true;

            let userInfo = response;
             localStorage.setItem('user-token', JSON.stringify(userInfo)); 
             localStorage.setItem('login-status', response.success); 
             this.$store.commit('_loginStatus', response.success);
             this.$store.commit('_profileImage', response.user.image);
             this.$cookies.set('userInfo', JSON.stringify(userInfo));
             this.$parent.closeModal();
             this.$parent.destroyCarousel();
      

          // let userInfo = params;
          // delete userInfo.password;
          // this.$cookies.set('userInfo', JSON.stringify(userInfo));

        }else{
          this.disabled = false;
          // Popup Mesaj gelecek
          this.modalMessage = response.messages[0];
          this.showModalSocial();
        }
      })

    },
    submitForm(){
      this.$v.$touch();
      if(!this.$v.form.$invalid){
        this.getinfoCreate(this.$v.form);
        
      }else{
        
      }
    }
  }
}
</script>

<style lang="scss">
.max-text{
  max-width: 140px;
}
.info-bg{
  position: absolute;
  bottom: 0;
  right: -3rem;
  height: 75%;
}
#frmPhoneNumA{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.vue-country-select{
  background: var(--blueLight) !important;
  border:none !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  z-index: 3;
    right: 0px;
    width: 50px;
        border-right: 3px solid white !important;
  &:focus-within{
    box-shadow: none !important;
  }
    .dropdown{
      &:hover{
          border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
      }
    }
  .dropdown-list {
    z-index: 1;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;

    top: 100%;
    left: -1px;
    background-color: #fff;
    border: 1px solid #ccc;
    max-width: 250px !important;
    li{
      strong{
        font-weight: 500 !important;
        font-size: 14px !important;
      }
    }
}
}
.signup {
  input{
    box-shadow: none !important;
  }
}
</style>