<template>
  <div class="d-flex w-100 h-100 signup">
    <div class="sides left-side section-wrapper d-flex flex-column align-items-start justify-content-between">
      <loading :active.sync="isLoading" 
      background-color="#332abb"
      :opacity= 0.99
      color="#fff"
      :is-full-page="fullPage"></loading>

      <form @submit.prevent="submitForm" autocomplete="off" class="w-100 h-100 d-flex flex-column justify-content-center">
        <h2>Giriş Yap</h2>
        
          <div class="form-group">
            <label for="userMail">E-Posta</label>
            <input 
              v-model="form.userMail" 
              v-on:keydown='key'
              v-bind:class="{error: $v.form.userMail.$error, valid: $v.form.userMail.$dirty && !$v.form.userMail.$invalid}"
              inputmode="email" type="email" class="form-control">
            <p class="form-warning" v-if="!$v.form.userMail.email">
              <span class="svg-icon icons-alert"></span>Yanlış mail formatı</p>
            <p class="form-warning" v-if="!$v.form.userMail.required">
              <span class="svg-icon icons-alert"></span>Bu alan zorunludur.</p>
          </div>


        <div class="form-group">
          <label for="userPass">Şifre</label>
          <input 
            v-model="form.userPass"
            v-bind:class="{error: $v.form.userPass.$error, valid: $v.form.userPass.$dirty && !$v.form.userPass.$invalid}"
            type="password" class="form-control">
          <p class="form-warning" v-if="!$v.form.userPass.required">
            <span class="svg-icon icons-alert"></span> Bu alan zorunludur.</p>
            <p class="form-warn" v-if="wrongInfo">
            <span class="svg-icon icons-alert"></span> Giriş bilgilerinizi kontrol ediniz.</p>
        </div>
      

        <div class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-between">
          <div>
            <button class="btn-underline" type="button" 
              @click="slideToPage(2)"
              >Şifremi Unuttum</button>
            <button class="btn-underline" type="button"
              @click="slideToPage(1)"
              >Hemen Üye Ol!</button>
          </div>
          <ButtonRounded class="float-right my-3 my-md-0" 
          Text="Giriş Yap"
          :isRouting="false"
          :disabled="disabled"
          />
        </div>
      </form>

      <div class="w-100 d-flex flex-column flex-xl-row align-items-center justify-content-between mt-5">
        <div class="d-flex flex-column flex-md-row w-100">
          <ButtonRounded class="mr-0 mr-md-4 mb-2 mb-md-0"
            Text="Google ile Bağlan"
            :isRouting="false"
            color="blue"
            @click.native="loginGoogle"
            btnSize="small"
            Icon="icons-google"
          />
          
          <ButtonRounded
            Text="Facebook ile Bağlan"
            :isRouting="false"
            @click.native="loginFacebook"
            color="blue"
            btnSize="small"
            Icon="icons-facebook-flat"
          />
        </div>
      </div>
    </div>
    <ModalSocial ref="SocialLoginModal" :data="loginData"></ModalSocial>
    <div class="sides right-side section-wrapper d-none d-md-flex flex-column align-items-center justify-content-between position-relative">
      <div class="h-100 d-flex flex-column justify-content-center">
        <img class="" src="/svg/login.svg">
      </div>
      
      <button @click="closeModal" type="button" class="back-button ml-auto d-flex align-items-center">
        <span class="svg-icon icons-back-arrow mr-1"></span>
        <p class="d-flex flex-column align-items-start">
          Geri Dön
        </p>
      </button>
    </div>

  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import Loading from 'vue-loading-overlay';
import ModalSocial from "@/components/SocialLoginModal";
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import {required, email, minLength, helpers, requiredIf} from 'vuelidate/lib/validators';
const nameSurnameLength = (value) => {
  let isValid = true;
  value.split(" ").forEach(e => {
    if(e.length < 2){
      isValid = false;
    }
  });
  return isValid;
}


export default {
  name: "SignIn",
  components: {
    ButtonRounded, Loading,ModalSocial
  },
  props: [ "formValue" ],
  data(){
    return{
      disabled: false,
      isLoading: false,
      fullPage: false,
      loginData: '',
      form: {
        userMail: '',
        userPass: '',
      },
      wrongInfo: false,
      modalMsg: '',
    }
  },
  validations: {
    form:{
      userMail: {
        required,
        email: email
      },
      userPass: {
        required,
      }
    }
  },
  mounted(){

  },
  methods: {
    closeModal(){
      this.$parent.destroyCarousel();
      this.$parent.closeModal();
    },
    slideToPage (event) {
      this.$emit('clicked', event);
    },
    showModalSocial() {
      this.$refs.SocialLoginModal.openModal();
    },
    closeModalSocial() {
      this.$refs.SocialLoginModal.closeModal();
    },
    loginFacebook(){
      window.location.href="https://bilimseferberligi.org/cms/api/v1/auth/login/facebook"
    },
    loginGoogle(){
      window.location.href="https://bilimseferberligi.org/cms/api/v1/auth/login/google"
    },
    doAjax() {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        },1500)
    },
    key: function(event){
      if(event.keyCode === 32){
        event.preventDefault();
      }
    },
  
    submitForm(){
      this.$v.$touch();
      if(!this.$v.form.$invalid){
        this.disabled = true;
        let params = {}
        params["email"] = this.form.userMail;
        params["password"] = this.form.userPass;
        params["remember_me"] = false;
        this.$api.login(params).then(response => {
          if(response.success == false){
            this.wrongInfo = true;
            localStorage.removeItem('user-token');
            localStorage.setItem('login-status', response.success); 
          }else{
             let userInfo = response;
             localStorage.setItem('user-token', JSON.stringify(userInfo)); 
             localStorage.setItem('login-status', response.success); 
             this.$store.commit('_loginStatus', response.success);
             this.$store.commit('_profileImage', response.user.image);
             this.$cookies.set('userInfo', JSON.stringify(userInfo));
             this.$parent.closeModal();
             this.$parent.destroyCarousel();
          }

          this.disabled = false;
        });
       
      }else{
        
      }
    }
  }
}
</script>

<style lang="scss">
.info-bg{
  position: absolute;
  bottom: 0;
  right: -3rem;
  height: 75%;
}

.back-button{
  background: transparent;
  color: #fff;
  .svg-icon{
    width: 1.3rem;
    height: 1.3rem;
  }
}
.form-warn{
    display: flex;
    align-items: center;
    color: var(--orange);
    margin: 5px 0 0 5px;
    font-size: 0.8rem;
    .svg-icon{
      background: var(--orange);
    }
}

</style>