<template>
  <div class="subscribe">
    <!-- <input v-if="notSubscribed === false"  type='mail' v-model="subscribeMail"> -->
    <input
      v-if="notSubscribed === false"
      v-model="form.userMail"
      v-on:keydown="key"
      v-bind:class="{
        error: $v.form.userMail.$error,
        valid: $v.form.userMail.$dirty && !$v.form.userMail.$invalid,
      }"
      @keyup.enter="_subscribe()"
      inputmode="email"
      type="email"
      placeholder="E-Posta Adresin"
    />
    <p class="form-warning foot-alert" v-if="!$v.form.userMail.email">
      Yanlış mail formatı
    </p>
    <p class="form-warning foot-alert" v-if="!$v.form.userMail.required">
      Bu alan zorunludur.
    </p>
    <p class="foot-alert mt-4" v-if="this.errorMessage">{{ errorMessage }}</p>
    <button v-if="notSubscribed === false" @click="_subscribe()">
      Abone Ol
    </button>
    <h3 class="w-100 text-center" v-if="notSubscribed === true">
      Abonelik isteğiniz alınmıştır !
    </h3>
  </div>
</template>

<script>
import axios from "axios";
import { required, email, minLength, helpers } from "vuelidate/lib/validators";
export default {
  name: "SubscribeButton",
  data() {
    return {
      notSubscribed: false,
      subscribeMail: "",
      dt: "",
      errorMessage: "",
      form: {
        userName: "",
        userMail: "",
      },
    };
  },
  validations: {
    form: {
      userMail: {
        required,
        email: email,
      },
    },
  },
  methods: {
    key: function (event) {
      this.errorMessage = "";
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },
    _subscribe() {
      this.$v.$touch();
      this.errorMessage = "";
      if (!this.$v.form.$invalid) {
        let params = {};
        params["email"] = this.form.userMail;
        this.$api.subscribe(params).then((response) => {
          if (response.success) {
            this.notSubscribed = true;
          } else {
            this.errorMessage = response.message;
          }
        });
      } else {
      }
    },
  },
};
</script>

<style lang="scss">
.subscribe {
  height: 100%;
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  .foot-alert {
    position: absolute;
    top: 100%;
    color: white !important;
    margin: 5px 0 0 10px;
    font-size: 0.8rem;
  }
  input {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 30px;
    height: 60px;
    padding: 10px 0 10px 15px;
    outline: none;
    &:focus {
      // border: 1px solid #999;
      + button {
        // background-color: #000;
      }
    }
  }
  button {
    position: absolute;
    right: 4px;
    height: calc(100% - 8px);
    border-radius: 30px;
    background: var(--gradientOrange);
    padding: 0 2rem;
    color: white;
    &:hover {
    }
  }

  @media (max-width: 567px) {
    width: 100%;
    margin: 1rem 0;
  }
}
</style>