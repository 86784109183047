<template>
  <transition name="fade">
  <div class="modal video-modal" v-if="show" @click="closeModal">
      <div class="iframe-container">
      <iframe width="720" height="405" :src="'https://www.youtube.com/embed/'+videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
       
      </div>
    
  </div>
  </transition>
</template>

<script>
export default {
  name: "ModalVideo",
  props: ["videoUrl"],
  data(){
    return{
      show: false,
    }
  },
  mounted(){
    // Listen ESC for close
    window.addEventListener('keydown', (e)=>{
      var key = e.which || e.keyCode;
      if (key === 27) {
        this.closeModal();
      }
    });
  },
  methods:{
    footerLink(data){
      this.backNav = data;
    },
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  }
}
</script>

<style lang="scss">
.iframe-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
// .iframe-container {
//   position: relative;
//   height: 100%;
//   min-height: 100vh;
//   iframe {
//     height: 100%;
//     width: 100%;    
//     left: 0;
//     top: 0;
//     position: absolute;
//     body,html {
//       height: 100%;
//       overflow: hidden;
//       background: transparent;
//     }
//   }
// }
</style>