<template>
  <transition name="fade">
    <div class="modal donors-modal" v-if="show">
      <!-- <iframe id="modalFrame" frameborder="0"></iframe> -->

      <div class="modal-message scroll-modal">
        <div class="modal-header">
          <h1>Tüm Bağışçılar</h1>
          <p @click="closeModal">Kapat</p>
        </div>
        <div class="donors">
          <ul>
            <li v-for="(donor, index) in data" :key="index" class="d-flex justify-content-between">
              <span>{{ donor.name_surname }}</span>
              <span v-if="donor.price">{{ donor.price }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "DonorsModal",
  props: ["data", "modalMsg", "success"],
  components: { ButtonRounded },
  data() {
    return {
      show: false,
      donorList: {},
    };
  },
  mounted() {
    // this.getDonors();
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    getDonors() {
      this.$api.getDonors().then((response) => {
        this.donorList = response;
      });
    },
  },
};
</script>


<style lang="scss">
.donors-modal {
  z-index: 99999 !important;
  position: fixed !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: rgba(24, 15, 100, 0.9);
  .donors {
    width: 100%;
    overflow: auto;
    height: 100%;
    padding: 15px;
    margin-top: 70px;
  }
  ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
    
    li {

    padding: 5px 5px;
    width: 33%;
    border-radius: 10px;
    margin-top: 5px;
    background: #efefef;
    @media (max-width:567px) {
      width: 100%;
      }
    }
  }
}
.scroll-modal {
  height: 500px;
  overflow: hidden;
  width: 70%;
}
.modal-message {
  position: relative;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  flex-direction: column;
  color: #000;
  p {
    font-size: 1.4rem;
  }
  .modal-header {
    background: var(--whiteOld);
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    justify-content: space-between;
    width: 100%;
    p {
      text-decoration: underline;
      font-size: 1rem;
      cursor: pointer;
      color: var(--purpleDark);
    }
    h1 {
      font-size: 1.65rem;
      color: var(--purpleDark);
      font-weight: 400;
    }
  }
}
</style>