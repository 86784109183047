<template>
  <div id="app" class="smooth-scroll">
    <Header ref="modalSignup" />
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <router-view />
    </transition>

    <CookieBar />
  </div>
</template>
<script defer src="https://use.fontawesome.com/releases/v5.0.6/js/all.js"></script>
<script>
// import LocomotiveScroll from 'locomotive-scroll';
// const scroll = new LocomotiveScroll();

import Header from "@/components/Header";
import CookieBar from "@/components/CookieBar";
import axios from "axios";
export default {
  name: "app",
  components: {
    Header, CookieBar
  },
  mounted() {
    // this.locoScroll();
    this.loginCheck();
  },
  methods: {
    locoScroll() {
      const locoScroll = new LocomotiveScroll({
        el: document.querySelector(".smooth-scroll"),
        smooth: true,
      });
      locoScroll.on("scroll", ScrollTrigger.update);
    },
    loginCheck() {
      let token = localStorage.getItem("user-token");
      let tokenize = JSON.parse(token);
      if (token !== null) {
        axios
          .get(
            "https://bilimseferberligi.org/cms/api/v1/token/check",
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                Authorization: `Bearer ${tokenize.token}`,
              },
            }
          )
          .then((response) => {
            var response = response.data;
            if (response.success) {
              localStorage.setItem("login-status", response.success);
              //this.$store.commit('_loginStatus', response.success);
            } else {
              localStorage.setItem("login-status", response.success);
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
</style>
<!-- <style src="@/styles/main.scss" lang="scss"></style> -->