<template>
<form @submit.prevent="submitForm" autocomplete="off" class="d-flex h-100 w-100">
  <div class="sides left-side kart-bilgileri d-flex align-items-center justify-content-center">
    <div class="section-wrapper">
      <h2>Bilgileriniz</h2>
    
      <div class="d-flex align-items-center">
        <div class="image-wrapper position-relative">
          <img class="frame-img" src="/img/img-1.png">
        </div>
        
        <div class="ml-3">
            <h3>Lorem Ipsum</h3>
            <p>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
      </div>

      <div class="form-group">
        <label for="userName">İsim Soyisim</label>
        <input 
          v-model="form.userName" 
          v-on:input="$v.form.userName.$touch"
          v-bind:class="{error: $v.form.userName.$error, valid: $v.form.userName.$dirty && !$v.form.userName.$invalid}"
          type="text" class="form-control" id="userName">
        <p class="form-warning" v-if="$v.form.userName.$invalid">Bu alan zorunludur.</p>
      </div>
      
      <div class="row">
        <div class="form-group col-6">
          <label for="userMail">E-Posta</label>
          <input 
            v-model="form.userMail" 
            v-on:input="$v.form.userMail.$touch"
            v-bind:class="{error: $v.form.userMail.$error, valid: $v.form.userMail.$dirty && !$v.form.userMail.$invalid}"
            type="email" class="form-control" id="userMail">
          <p class="form-warning" v-if="$v.form.userMail.$invalid">Bu alan zorunludur.</p>
        </div>
        
        <div class="form-group col-6">
          <label for="userPhone">Telefon Numarası</label>
          <the-mask
            mask="#(###) ### ## ##"
            placeholder="#(###) ### ## ##"
            v-model="form.userPhone" 
            v-on:input="$v.form.userPhone.$touch"
            v-bind:class="{error: $v.form.userPhone.$error, valid: $v.form.userPhone.$dirty && !$v.form.userPhone.$invalid}"
            type="tel" class="form-control" />
          <p class="form-warning" v-if="$v.form.userPhone.$invalid">Bu alan zorunludur.</p>
        </div>
      </div>

    </div>
  </div>

  <div class="sides right-side d-flex flex-column align-items-center justify-content-center">
    <div class="section-wrapper">
      <h2>Gönderilecek Kişinin Bilgileri</h2>
      <div class="form-group">
        <label for="giftUserName">İsim Soyisim</label>
        <input 
          v-model="form.giftUserName" 
          v-on:input="$v.form.giftUserName.$touch"
          v-bind:class="{error: $v.form.giftUserName.$error, valid: $v.form.giftUserName.$dirty && !$v.form.giftUserName.$invalid}"
          type="text" class="form-control" id="giftUserName">
        <p class="form-warning" v-if="$v.form.giftUserName.$invalid">Bu alan zorunludur.</p>
      </div>

      <div class="form-group">
        <label for="address">Adres</label>
        <input 
          v-model="form.address" 
          v-on:input="$v.form.address.$touch"
          v-bind:class="{error: $v.form.address.$error, valid: $v.form.address.$dirty && !$v.form.address.$invalid}"
          type="text" class="form-control" id="address">
        <p class="form-warning" v-if="$v.form.address.$invalid">Bu alan zorunludur.</p>
      </div>
      
      <div class="form-group">
        <label for="notes">Kişiye Özel Notunuz</label>
        <textarea 
          v-model="form.notes" 
          v-on:input="$v.form.notes.$touch"
          v-bind:class="{error: $v.form.notes.$error, valid: $v.form.notes.$dirty && !$v.form.notes.$invalid}"
          type="text" class="form-control" id="notes"></textarea>
        <p class="form-warning" v-if="$v.form.notes.$invalid">Bu alan zorunludur.</p>
      </div>

      <label class="custom-checkbox"> <span>KVK </span> Okudum Onaylıyorum.
        <input type="checkbox">
        <span class="checkmark"></span>
      </label>
      <ButtonRounded Text="Devam Et" class="float-right" :isRouting="false"/>

      
    </div>
  </div>
</form>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";

import {integer, required, between, maxLength, minLength} from 'vuelidate/lib/validators';

export default {
  name: "KartBilgileri",
  components: {
    ButtonRounded,

  },
  data(){
    return{
      form: {
        userName: '',
        userMail: '',
        userPhone: '',
        giftUserName: '',
        address: '',
        notes: '',
      }
    }
  },
  validations: {
    form: {
      userName: { 
        required: required,
        minLength: minLength(3),
      },
      userMail: {
        required: required,
      },
      userPhone: {
        required: required,
      },
      giftUserName: { 
        required: required,
        minLength: minLength(3),
      },
      address: {
        required: required,
      },
      notes: {
        required: required,
      }
    }
  },
  methods: {
    submitForm(){
      if(!this.$v.form.$invalid){

      }else{
        
      }
    }
  }
}
</script>

<style lang="scss">
.kart-bilgileri{

  .image-wrapper .frame-img{
    width: 200px;
  }
}
</style>