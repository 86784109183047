<template>
  <form
    @submit.prevent="submitForm"
    autocomplete="off"
    class="kampanya-olustur d-flex flex-column flex-md-row h-100 w-100"
  >
    <div
      class="
        sides
        left-side
        section-wrapper
        d-flex
        align-items-center
        justify-content-center
      "
    >
      <div
        class="
          w-100
          mb-5
          sides-container
          d-flex
          flex-column
          justify-content-center
        "
      >
        <h3 class="mb-5">Bilgileriniz</h3>
        <div class="d-flex align-items-top">
          <div class="image-wrapper position-relative">
            <img :src="url" class="frame-img form-img" />
          </div>
          <div class="left-detail ml-3 mt-2">
            <p class="font-bold text-white">{{ title }}</p>
            <p class="text-white p-medium mt-1">
              {{ desc }}
            </p>
          </div>
        </div>
        <div class="form-wrapper">
          <div class="form-group">
            <label for="userName">İsim Soyisim</label>
            <input
              v-model="form.userName"
              v-on:input="removeSpace"
              v-bind:class="{
                error: $v.form.userName.$error,
                valid: $v.form.userName.$dirty && !$v.form.userName.$invalid,
              }"
              type="text"
              class="form-control"
            />
            <p class="form-warning" v-if="!$v.form.userName.alpha">
              <span class="svg-icon icons-alert"></span> Ad Soyad şeklinde
              giriniz.
            </p>
            <p class="form-warning" v-if="!$v.form.userName.nameSurnameLength">
              <span class="svg-icon icons-alert"></span> En az 2 karakter
              girmelisiniz
            </p>
            <p class="form-warning" v-if="!$v.form.userName.minLength">
              <span class="svg-icon icons-alert"></span> En az 2 karakter
              girmelisiniz
            </p>
            <p class="form-warning" v-if="!$v.form.userName.required">
              <span class="svg-icon icons-alert"></span> Bu alan zorunludur.
            </p>
          </div>
          <div class="d-flex row">
            <div class="form-group col-12 col-md-6">
              <label for="userMail">E-Posta</label>
              <input
                v-model="form.userMail"
                v-on:keydown="key"
                v-bind:class="{
                  error: $v.form.userMail.$error,
                  valid: $v.form.userMail.$dirty && !$v.form.userMail.$invalid,
                }"
                inputmode="email"
                type="email"
                class="form-control"
              />
              <p class="form-warning" v-if="!$v.form.userMail.email">
                <span class="svg-icon icons-alert"></span>Yanlış mail formatı
              </p>
              <p class="form-warning" v-if="!$v.form.userMail.required">
                <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
              </p>
            </div>

            <div class="form-group col-12 col-md-6">
              <label for="userPhone">Telefon Numarası</label>
              <div class="d-flex">
                <vue-country-code
                  v-model="dial"
                  @onSelect="onSelect"
                  defaultCountry="TR"
                  :preferredCountries="['vn', 'us', 'gb']"
                >
                </vue-country-code>
                <the-mask
                  name="phone"
                  id="frmPhoneNumA"
                  autocomplete="tel"
                  mask="(###) ### ## ##"
                  placeholder="(---) --- -- --"
                  v-model="form.userPhone"
                  v-bind:class="{
                    error: $v.form.userPhone.$error,
                    valid:
                      $v.form.userPhone.$dirty && !$v.form.userPhone.$invalid,
                  }"
                  type="tel"
                  class="form-control"
                />
              </div>
              <p class="form-warning" v-if="!$v.form.userPhone.required">
                <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
              </p>
              <p class="form-warning" v-if="!$v.form.userPhone.minLength">
                <span class="svg-icon icons-alert"></span>Yanlış telefon formatı
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        sides
        right-side
        section-wrapper
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
    >
      <div
        class="
          w-100
          sides-container
          d-flex
          flex-column
          justify-content-center
          mt-5 mt-md-0
        "
      >
        <h3 class="mb-5">Gönderilecek Kişinin Bilgileri</h3>
        <div class="form-wrapper">
          <div class="form-group">
            <label for="nameToSend">İsim Soyisim</label>
            <input
              v-model="form.nameToSend"
              v-on:input="removeSpace"
              v-bind:class="{
                error: $v.form.nameToSend.$error,
                valid:
                  $v.form.nameToSend.$dirty && !$v.form.nameToSend.$invalid,
              }"
              type="text"
              class="form-control"
            />
            <p class="form-warning" v-if="!$v.form.nameToSend.alpha">
              <span class="svg-icon icons-alert"></span> Ad Soyad şeklinde
              giriniz.
            </p>
            <p
              class="form-warning"
              v-if="!$v.form.nameToSend.nameSurnameLength"
            >
              <span class="svg-icon icons-alert"></span> En az 2 karakter
              girmelisiniz
            </p>
            <p class="form-warning" v-if="!$v.form.nameToSend.minLength">
              <span class="svg-icon icons-alert"></span> En az 2 karakter
              girmelisiniz
            </p>
            <p class="form-warning" v-if="!$v.form.nameToSend.required">
              <span class="svg-icon icons-alert"></span> Bu alan zorunludur.
            </p>
          </div>
          <div class="form-group">
            <label for="sendToMail">E-Posta</label>
            <input
              v-model="form.sendToMail"
              v-on:keydown="key"
              v-bind:class="{
                error: $v.form.sendToMail.$error,
                valid:
                  $v.form.sendToMail.$dirty && !$v.form.sendToMail.$invalid,
              }"
              inputmode="email"
              type="email"
              class="form-control"
            />
            <p class="form-warning" v-if="!$v.form.sendToMail.email">
              <span class="svg-icon icons-alert"></span>Yanlış mail formatı
            </p>
            <p class="form-warning" v-if="!$v.form.sendToMail.required">
              <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
            </p>
          </div>
          <!-- <div class="form-group">
            <div class="d-flex justify-content-between">
              <label>Adresi</label>
            </div>
            <textarea
              class="form-control"
              rows="1"
              v-model="form.adressToSend"
              v-bind:class="{
                error: $v.form.adressToSend.$error,
                valid:
                  $v.form.adressToSend.$dirty && !$v.form.adressToSend.$invalid,
              }"
            ></textarea>
            <p class="form-warning" v-if="!$v.form.adressToSend.required">
              <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
            </p>
            <p class="form-warning" v-if="!$v.form.adressToSend.minLength">
              <span class="svg-icon icons-alert"></span>En az 10 karakter
              girmelisiniz.
            </p>
          </div> -->
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label>Kişiye Özel Notunuz</label>
              <span class="char-count" v-text="'* karakter'"></span>
            </div>
            <textarea
              class="form-control"
              rows="1"
              v-model="form.messageToSend"
            ></textarea>
            <p class="form-warning">Bu alan zorunludur.</p>
          </div>
        </div>

        <label class="custom-checkbox">
          <a href="https://www.bilimseferberligi.org/Bilim_Seferberligi_kisisel_verilerin_korunmasi_ve_islenmesi_politikasi2.pdf" target="_blank">KVKK </a> okudum, onaylıyorum.
          <input
            type="checkbox"
            v-model="form.terms_accepted"
            v-bind:class="{
              error: $v.form.terms_accepted.$error,
              valid:
                $v.form.terms_accepted.$dirty &&
                !$v.form.terms_accepted.$invalid,
            }"
          />
          <span class="checkmark"></span>
        </label>
        <ButtonRounded
          class="ml-auto"
          Text="Devam Et"
          :isRouting="false"
          :disabled="disabled"
        />
      </div>
    </div>
  </form>
</template>

<script>
import {
  required,
  email,
  minLength,
  helpers,
  requiredIf,
} from "vuelidate/lib/validators";
const nameSurnameLength = (value) => {
  let isValid = true;
  value.split(" ").forEach((e) => {
    if (e.length < 2) {
      isValid = false;
    }
  });
  return isValid;
};

import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "KampanyaOlustur",
  props: ["url", "title", "desc"],
  components: {
    ButtonRounded,
  },

  data() {
    return {
      disabled: false,
      form: {
        userName: "",
        userMail: "",
        userPhone: "",
        nameToSend: "",
        // adressToSend: "",
        terms_accepted: false,
        messageToSend: "",
        sendToMail: "",
      },
      cardImage: "",
      cardTitle: "",
      cardDesc: "",
      dial: "90",
    };
  },
  mounted() {
    this.autoFillForm();
  },
  validations: {
    form: {
      userName: {
        required,
        nameSurnameLength,
        minLength: minLength(2),
        alpha: helpers.regex(
          "alpha",
          /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/i
        ),
      },
      userMail: {
        required,
        email: email,
      },
      sendToMail: {
        required,
        email: email,
      },
      userPhone: {
        required,
        minLength: minLength(10),
      },
      nameToSend: {
        required,
        nameSurnameLength,
        minLength: minLength(2),
        alpha: helpers.regex(
          "alpha",
          /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/i
        ),
      },
      // adressToSend: {
      //   // required,
      //   // minLength: minLength(2),
      // },
      terms_accepted: {
        checked: (value) => value === true,
      },
    },
  },
  methods: {
    key: function (event) {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },
    onSelect({ dialCode }) {
      this.dial = dialCode;
    },
    doAjax() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    removeSpace: function (event) {
      this.form.userName = this.form.userName
        .replace(/\s\s+/g, " ")
        .trimStart();
    },
    toggleInput(e, item) {
      let $target = $(item.target).closest("label").siblings().find("input");

      $target.prop("disabled", function (i, v) {
        return !v;
      });
      $target.val("");
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        this.getinfoCreate(this.$v.form);
      } else {
        
      }
    },
    getinfoCreate(data) {
      let params = {};

      params["name"] = data.userName.$model.split(" ").slice(0, -1).join(" ");
      params["surname"] = data.userName.$model.split(" ").slice(-1).join(" ");
      params["email"] = data.userMail.$model;
      params["phone"] = data.userPhone.$model;
      params["phone_code"] = "+" + this.dial;
      params["sent_adress"] = "";
      params["sent_email"] = data.sendToMail.$model;
      params["amount"] = this.$store.state.formValue.amount;
      params["price"] = this.$store.state.formValue.price;
      params["gift_card_id"] = this.$store.state.formValue.card_id;
      params["paymentCurrency"] = "1";
      params["sent_name_surname"] = this.form.nameToSend;
      params["sent_note"] = this.form.messageToSend;
      params["user_id"] = "1";

      this.disabled = true;
      this.doAjax();

      this.$api.giftCardInfoCreate(params).then((response) => {
        if (response.success) {
          this.disabled = false;
          this.$store.commit("_conversation_id", response.info.conversation_id);
          this.$store.commit("_userInfo", response.info);
          //console.log(response);
          var elem = document.querySelector(".modal-wrapper");
          elem.scrollIntoView({ behavior: "smooth" });
          this.$emit("clicked", "1");
        } else {
          this.disabled = false;
          // Popup Mesaj gelecek
        }
      });
    },
    autoFillForm() {
      let token = localStorage.getItem("user-token");
      if (token !== null && token) {
        let tokens = JSON.parse(token);
        this.form.userName = tokens.user.name;
        this.form.userPhone = tokens.user.phone;
        this.form.userMail = tokens.user.email;
      }
    },
  },
};
</script>
<style lang="scss">
.form-img {
  max-width: 200px;
  height: auto !important;
  margin: 0px !important;
}
textarea.form-control {
  max-height: 70px;
}
.vue-country-select {
  background: var(--whiteOld) !important;
  border: none !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  z-index: 3;
  right: 0px;
  width: 50px;
  border-right: 3px solid white !important;
  &:focus-within {
    box-shadow: none !important;
  }
  .dropdown {
    &:hover {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
  }
  .dropdown-list {
    z-index: 1;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;

    top: 100%;
    left: -1px;
    background-color: #fff;
    border: 1px solid #ccc;
    max-width: 250px !important;
    li {
      strong {
        font-weight: 500 !important;
        font-size: 14px !important;
      }
    }
  }
}
</style>
