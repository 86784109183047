<template>
  <div
    class="
      kartSec
      padding-wrapper
      w-100
      d-flex
      flex-column
      justify-content-between
    "
  >
    <div class="d-flex justify-content-between">
      <div>
        <h3>Hediye Kartı Gönder</h3>
      </div>
      <div class="d-flex h-100">
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>

    <div
      class="
        w-100
        d-flex
        card-container
        swiper-kartSec swiper-carousel swiper swiper-container-horizontal
      "
    >
      <div class="swiper-wrapper">
        <div
          class="single-card swiper-slide"
          v-for="(card, index) in cardData"
          :key="index"
          @click="
            selectCard(
              $event,
              card.id,
              card.price,
              1,
              card.title,
              card.description,
              card.image
            )
          "
        >
          <div class="card-wrapper">
            <div class="image-wrapper position-relative">
              <span class="svg-icon icons-check-yellow"></span>
              <img class="frame-img" :src="card.image" />
              <span class="kart-badge">{{ card.full_price }}</span>
            </div>
            <div class="px-2">
              <h3>{{ card.title }}</h3>
              <p class="p-medium">{{ card.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-pagination d-block d-md-none"></div>
    </div>

    <div class="d-flex justify-content-end">
      <ButtonRounded
        @click.native="formValidation"
        Text="Devam Et"
        :isRouting="false"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";

import ButtonRounded from "@/components/ButtonRounded";
export default {
  name: "KartSec",
  components: {
    ButtonRounded,
  },
  props: ["cardData"],
  data() {
    return {
      swiper: "",
      disabled: true,
      formValue: {
        card_id: 0,
        price: 0,
        amount: 1,
        cardName: "",
        cardText: "",
        imageUrl: "",
      },
    };
  },
  mounted() {
    this.initZoom();
  },
  methods: {
    _swiperInit() {
      this.swiper = new Swiper(".swiper-kartSec", {
        loop: false,
        spaceBetween: 20,
        grabCursor: false,
        allowTouchMove: false,
        simulateTouch: false,
        // loopFillGroupWithBlank: false,
        // preventClicks: false,
        // preventClicksPropagation: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          320: {
            slidesPerView: 1.5,
          },
          1024: {
            slidesPerView: 5,
          },
        },
      });
    },
    initZoom() {
      /* CONFIG */
      var xOffset = 10;
      var yOffset = 30;
      // these 2 variable determine popup's distance from the cursor
      // you might want to adjust to get the right result
      /* END CONFIG */
      $(".kartSec .image-wrapper .frame-img").hover(
        function (e) {

          $("body").append(
            "<p id='preview'><img class='frame-img' src='" +
              this.src +
              "' alt='Image preview' /></p>"
          );
          $("#preview")
            .css("top", e.pageY - xOffset + "px")
            .css("left", e.pageX + yOffset + "px")
            .fadeIn("fast");
        },
        function () {
          $("#preview").remove();
        }
      );
      $(".kartSec .image-wrapper .frame-img").mousemove(function (e) {
        $("#preview")
          .css("top", e.pageY - xOffset + "px")
          .css("left", e.pageX + yOffset + "px");
      });

      // starting the script on page load
    },
    slideToNext() {
      this.$emit("clicked");
    },
    selectCard(e, card_id, price, amount, title, text, image) {
      $(".single-card")
        .not(e.target)
        .find(".card-wrapper")
        .removeClass("active");
      $(e.target)
        .closest(".single-card")
        .find(".card-wrapper")
        .addClass("active");

      this.disabled = false;

      this.formValue.card_id = card_id;
      this.formValue.price = price;
      this.formValue.amount = amount;
      this.formValue.cardName = title;
      this.formValue.cardText = text;
      this.formValue.imageUrl = image;
    },
    formValidation() {
      this.$store.commit("_formValue", this.formValue);
      this.$store.commit("_selectedDonatinPrice", this.formValue.price);
      this.$store.commit("_giftCardName", this.formValue.cardName);
      this.$store.commit("_giftCardText", this.formValue.cardText);
      this.$store.commit("_giftCardURL", this.formValue.imageUrl);

      this.slideToNext();
    },
  },
};
</script>

<style lang="scss">
#preview {
  position: absolute;
  z-index: 99999999;
  display: none;
  max-width: 500px;
  max-height: 500px;
  .frame-img {
    border-image-source: url(/svg/yellow-frame.svg);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media (min-width: 1500px) {
  .kartSec.padding-wrapper {
    padding: 10vw 10vw 0;
    min-height: 550px;
  }
  .kartSec {
    .swiper-wrapper {
      .single-card {
        display: flex;
        align-items: center;
        min-height: 340px;
        display: flex !important;
        background: url(/svg/pattern.svg) no-repeat left;
        justify-content: unset !important;
        align-items: flex-start;
      }
    }
  }
}
.kartSec {
  .swiper-button-prev,
  .swiper-button-next {
    position: relative;
    background: #fff;
    border-radius: 50%;
    width: 2.9rem;
    height: 2.9rem;
    left: unset;
    right: unset;
    margin: 0 0.5rem;
    &:after {
      font-size: 1rem;
      font-weight: 700;
      color: var(--purpleDark);
    }
  }
  .single-card.swiper-slide {
    max-height: 200px;
    max-width: 230px;
  }
  @media (min-width: 1500px) {
    .image-wrapper {
      .frame-img {
        //  max-width: 200px;
        max-height: 200px;
        max-width: 230px;
        object-fit: cover;
        width: 230px;
        height: 210px;
      }
    }
  }
  @media (min-width: 1000px) and (max-width: 1499px) {
    .kartSec{
      .padding-wrapper{
        padding: 2vw 10vw 0 !important;
      }
    }
    .image-wrapper {
      .frame-img {
        //  max-width: 200px;
        max-height: 150px;
        max-width: 180px;
        object-fit: cover;
        width: 230px;
        height: 210px;
      }
    }
  }
  @media (max-width: 567px) {
    .image-wrapper {
      .frame-img {
        //  max-width: 200px;
        max-height: 200px;
        max-width: 230px;
        object-fit: cover;
        width: 230px;
        height: 210px;
      }
    }
  }
  .swiper-kartSec.swiper-container-horizontal {
    // overflow: hidden;
    padding-top: 3rem;
    .swiper-slide {
      padding: 0;
      justify-content: center;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      pointer-events: auto;
      z-index: 99;
      &:hover {
        // .image-wrapper {
        //   box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.5);
        // }
         .image-wrapper {
        img{
           box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.5);
        }
      }
      }
    }
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 1.5rem 0 0.5rem 0;
  }
  .single-card {
    padding: 10px;
    cursor: pointer;
    &:hover {
      .image-wrapper {
        img{
           box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.5);
        }
      }
    }
    .kart-badge {
      font-size: 1rem;
    }
    &:focus {
      background: red;
    }
    .svg-icon {
      position: absolute;
      right: 0.5rem;
      top: 0.2rem;
      width: 3rem;
      height: 3rem;
      display: none;
      background: var(--yellow);
    }

    .active {
      .svg-icon {
        display: block;
      }
      .frame-img {
        border-image-source: url(/svg/yellow-frame.svg);
      }
      .kart-badge {
        background: var(--yellow);
      }
    }
  }
}
 @media (min-width: 1000px) and (max-width: 1499px) {
    .kartSec.padding-wrapper{
        padding: 2vw 10vw 0 !important;
      min-height: 500px;
      }
 }
</style>
