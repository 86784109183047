<template>
  <div class="d-flex w-100 h-100 padding-section-wrapper">
    <div
      class="
        sides
        left-side
        section-wrapper
        d-none d-md-block
        position-relative
      "
    >
      <img class="info-bg" src="/svg/user-info.svg" />
    </div>

    <div
      class="
        sides
        right-side
        section-wrapper
        d-flex
        align-items-start align-items-md-center
        justify-content-center
      "
    >
      <loading
        :active.sync="isLoading"
        background-color="#332abb"
        :opacity="0.99"
        color="#fff"
        :is-full-page="fullPage"
      ></loading>
      <form @submit.prevent="submitForm" autocomplete="off" class="w-100">
        <h2>Bilgileriniz</h2>
        <div class="form-group">
          <label for="userName">İsim Soyisim</label>
          <input
            v-model="form.userName"
            v-on:input="removeSpace"
            v-bind:class="{
              error: $v.form.userName.$error,
              valid: $v.form.userName.$dirty && !$v.form.userName.$invalid,
            }"
            type="text"
            class="form-control"
          />
          <p class="form-warning" v-if="!$v.form.userName.alpha">
            <span class="svg-icon icons-alert"></span> İsim ve soyisim şeklinde
            giriniz.
          </p>
          <p class="form-warning" v-if="!$v.form.userName.nameSurnameLength">
            <span class="svg-icon icons-alert"></span> Sadece isim ve soyisim arasında boşluk olmalıdır.
          </p>
          <p class="form-warning" v-if="!$v.form.userName.minLength">
            <span class="svg-icon icons-alert"></span> En az 2 karakter
            girmelisiniz
          </p>
          <p class="form-warning" v-if="!$v.form.userName.required">
            <span class="svg-icon icons-alert"></span> Bu alan zorunludur.
          </p>
        </div>

        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label for="userMail">E-Posta</label>
            <input
              v-model="form.userMail"
              v-on:keydown="key"
              v-bind:class="{
                error: $v.form.userMail.$error,
                valid: $v.form.userMail.$dirty && !$v.form.userMail.$invalid,
              }"
              inputmode="email"
              type="email"
              class="form-control"
            />
            <p class="form-warning" v-if="!$v.form.userMail.email">
              <span class="svg-icon icons-alert"></span>Yanlış mail formatı
            </p>
            <p class="form-warning" v-if="!$v.form.userMail.required">
              <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
            </p>
          </div>

          <div class="form-group col-12 col-md-6">
            <label for="userPhone">Telefon Numarası</label>
            <div class="d-flex">
              <vue-country-code
                v-model="dial"
                @onSelect="onSelect"
                defaultCountry="TR"
                :preferredCountries="['vn', 'us', 'gb']"
              >
              </vue-country-code>
              <the-mask
                name="phone"
                id="frmPhoneNumA"
                autocomplete="tel"
                mask="(###) ### ## ##"
                placeholder="(---) --- -- --"
                v-model="form.userPhone"
                v-bind:class="{
                  error: $v.form.userPhone.$error,
                  valid:
                    $v.form.userPhone.$dirty && !$v.form.userPhone.$invalid,
                }"
                type="tel"
                class="form-control"
              />
            </div>
            <p class="form-warning" v-if="!$v.form.userPhone.required">
              <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
            </p>
            <p class="form-warning" v-if="!$v.form.userPhone.minLength">
              <span class="svg-icon icons-alert"></span>Yanlış telefon formatı
            </p>
          </div>
        </div>

        <!-- <div
          class="form-group"
          v-if="this.$store.state.formValue.donation_status == 'duzenli'"
        >
          <label for="userTc">TC Kimlik No</label>
          <the-mask
            mask="###########"
            v-model="form.userTc"
            v-bind:class="{
              error: $v.form.userTc.$error,
              valid: $v.form.userTc.$dirty && !$v.form.userTc.$invalid,
            }"
            type="tel"
            class="form-control"
          />
          <p class="form-warning" v-if="!$v.form.userTc.required">
            <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
          </p>
          <p class="form-warning" v-if="!$v.form.userTc.minLength">
            <span class="svg-icon icons-alert"></span>Yanlış format
          </p>
        </div> -->

        <!-- <div class="form-group">
          <label for="userAddress">Adres</label>
          <textarea
            v-model="form.userAddress"
            v-bind:class="{
              error: $v.form.userAddress.$error,
              valid:
                $v.form.userAddress.$dirty && !$v.form.userAddress.$invalid,
            }"
            type="text"
            class="form-control"
          ></textarea>
          <p class="form-warning" v-if="!$v.form.userAddress.required">
            Bu alan zorunludur.
          </p>
          <p class="form-warning" v-if="!$v.form.userAddress.minLength">
            En az 10 karakter giriniz.
          </p>
        </div> -->

        <label class="custom-checkbox">
          <a
            href="https://www.bilimseferberligi.org/kvkk"
            target="_blank"
            >Kişisel verilerin korunması ve işlenmesine ilişkin politikayı
          </a>
          okudum, onaylıyorum.
          <input
            type="checkbox"
            v-model="form.terms_accepted"
            v-bind:class="{
              error: $v.form.terms_accepted.$error,
              valid:
                $v.form.terms_accepted.$dirty &&
                !$v.form.terms_accepted.$invalid,
            }"
          />
          <span class="checkmark"></span>
        </label>
        <ButtonRounded
          class="float-right"
          Text="Devam Et"
          :isRouting="false"
          :disabled="disabled"
        />
      </form>
    </div>
  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import {
  required,
  email,
  minLength,
  helpers,
  requiredIf,
} from "vuelidate/lib/validators";
const nameSurnameLength = (value) => {
  let isValid = true;
  let tempValue = value.trim()
  tempValue.split(" ").forEach((e) => {
    if (e.length < 2) {
      isValid = false;
    }
  });
  return isValid;
};

const checkTcNum = function (value) {
  if (value.length > 0) {
    value = value.toString();
    var isEleven = /^[0-9]{11}$/.test(value);
    var totalX = 0;
    for (var i = 0; i < 10; i++) {
      totalX += Number(value.substr(i, 1));
    }
    var isRuleX = totalX % 10 == value.substr(10, 1);
    var totalY1 = 0;
    var totalY2 = 0;
    for (var i = 0; i < 10; i += 2) {
      totalY1 += Number(value.substr(i, 1));
    }
    for (var i = 1; i < 10; i += 2) {
      totalY2 += Number(value.substr(i, 1));
    }
    var isRuleY = (totalY1 * 7 - totalY2) % 10 == value.substr(9, 0);
    return isEleven && isRuleX && isRuleY;
  }
  return true;
};

export default {
  name: "Bilgiler",
  components: {
    ButtonRounded,
    Loading,
  },
  props: ["formValue", "donationType", "campaignId"],
  data() {
    return {
      disabled: false,
      isLoading: false,
      fullPage: false,
      form: {
        userName: "",
        userMail: "",
        userPhone: "",
        // userTc: "",
        // userAddress: "",
        terms_accepted: false,
      },
      dial: "90",
    };
  },
  mounted() {
    this.autoFillForm();
    console.log(this.$store.state.formValue);
  },
  validations: {
    form: {
      userName: {
        required,
        nameSurnameLength,
        minLength: minLength(2),
        alpha: helpers.regex(
          "alpha",
          /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/i
        ),
      },
      userMail: {
        required,
        email: email,
      },
      userPhone: {
        required,
        minLength: minLength(10),
      },
      // userTc: {
      //   required: requiredIf(function () {
      //     return this.$store.state.formValue.donation_status == "duzenli"
      //       ? true
      //       : false;
      //   }),
      //   checkTcNum,
      //   // minLength: minLength(11),
      // },
      // userAddress: {
      //   // required,
      //   // minLength: minLength(11),
      // },
      terms_accepted: {
        checked: (value) => value === true,
      },
    },
  },
  methods: {
    onSelect({ dialCode }) {
      this.dial = dialCode;
    },
    doAjax() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    removeSpace: function (event) {
      this.form.userName = this.form.userName
        .replace(/\s\s+/g, " ")
        .trimStart();
    },
    key: function (event) {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },
    getinfoCreate(data) {
      let tempData = data.userName.$model.trim()
      
      if (this.donationType === "standartDonate") {
        let token = localStorage.getItem("user-token");
        let params = {};
        if (token !== null) {
          let tokenize = JSON.parse(token);
          params["user_id"] = tokenize.user.id;
        } else {
          params["user_id"] = "0";
        }
        params["name"] = tempData.split(" ").slice(0, -1).join(" ");
        params["surname"] = tempData.split(" ").slice(-1).join(" ");
        params["email"] = data.userMail.$model;
        params["phone"] = data.userPhone.$model;
        // params["identity_number"] = data.userTc.$model;
        params["phone_code"] = "+" + this.dial;
        // params["adress"] = data.userAddress.$model;
        params["amount"] = this.$store.state.formValue.amount;
        params["donation_id"] = this.$store.state.formValue.donation_id;
        params["price"] = this.$store.state.formValue.price;
        params["donation_type"] = this.$store.state.formValue.donation_type;
        params["selected_type"] = this.$store.state.formValue.selected_type;
        params["donation_status"] = this.$store.state.formValue.donation_status;

        this.disabled = true;
        this.doAjax();

        this.$api.infoCreate(params).then((response) => {
          if (response.success) {
            this.disabled = false;
            this.$store.commit(
              "_conversation_id",
              response.info.conversation_id
            );
            this.$store.commit("_userInfo", response.info);
            var elem = document.querySelector(".modal-wrapper");
            elem.scrollIntoView({ behavior: "smooth" });
            this.$emit("clicked", "1");
          } else {
            this.disabled = false;
            // Popup Mesaj gelecek
          }
        });
      } else if (this.donationType === "campaignDonate") {
        let token = localStorage.getItem("user-token");
        let params = {};
        if (token !== null) {
          let tokenize = JSON.parse(token);
          params["user_id"] = tokenize.user.id;
        } else {
          params["user_id"] = "0";
        }
        params["selected_type"] = this.$store.state.formValue.selected_type;
        // params["campaign_id"] = this.$route.params.id;
        params["campaign_id"] = this.campaignId;
        params["phone_code"] = "+" + this.dial;
        params["donation_id"] = this.$store.state.formValue.donation_id;
        let newName = data.userName.$model;
        console.log("campaign_id: " , newName);
        newName = newName.replace(/\s*$/,"");
        params["name"] = newName.split(" ").slice(0, -1).join(" ");
        params["surname"] = newName.split(" ").slice(-1).join(" ");
        params["email"] = data.userMail.$model;
        params["phone"] = data.userPhone.$model;
        // params["adress"] = data.userAddress.$model;
        params["amount"] = this.$store.state.formValue.amount;
        params["price"] = this.$store.state.formValue.price;
        params["campaign_price_status"] = this.$store.state.hidePrice;
        params["name_show"] = this.$store.state.hideName;

        this.disabled = true;
        this.doAjax();
        this.$api.campaignInfoCreate(params).then((response) => {
          if (response.success) {
            this.disabled = false;
            this.$store.commit(
              "_conversation_id",
              response.info.conversation_id
            );
            this.$store.commit("_userInfo", response.info);
            var elem = document.querySelector(".modal-wrapper");
            elem.scrollIntoView({ behavior: "smooth" });
            this.$emit("clicked", "1");
          } else {
            this.disabled = false;
            // Popup Mesaj gelecek
          }
        });
      }

      // console.log( "total_price", this.$store.state.userInfo.total_price);
      // console.log( "form value", this.$store.state.formValue);
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        this.getinfoCreate(this.$v.form);
      } else {
        
      }
    },
    autoFillForm() {
      let token = localStorage.getItem("user-token");
      if (token !== null && token) {
        let tokens = JSON.parse(token);
        this.form.userName = tokens.user.name;
        this.form.userMail = tokens.user.email;
        this.form.userPhone = tokens.user.phone;
        this.form.userAddress = tokens.user.address;
      }
    },
  },
};
</script>

<style lang="scss">
.info-bg {
  position: absolute;
  bottom: 0;
  right: -3rem;
  height: 75%;
}
.vue-country-select {
  background: var(--whiteOld) !important;
  border: none !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  z-index: 3;
  right: 0px;
  width: 50px;
  border-right: 3px solid white !important;
  &:focus-within {
    box-shadow: none !important;
  }
  .dropdown {
    &:hover {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
  }
  .dropdown-list {
    z-index: 1;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;

    top: 100%;
    left: -1px;
    background-color: #fff;
    border: 1px solid #ccc;
    max-width: 250px !important;
    li {
      strong {
        font-weight: 500 !important;
        font-size: 14px !important;
      }
    }
  }
}
</style>