import 'core-js/stable';
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap"; 
import "bootstrap/dist/css/bootstrap.min.css";
import "@/styles/main.scss";
import JQuery from "jquery";
window.$ = JQuery;

import api from "@/api";

import cssVars from 'css-vars-ponyfill';
cssVars({
  // Options...
});

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);
import VueCountryCode from "vue-country-code-select";

import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

import VueTheMask from 'vue-the-mask';
import Skeleton from 'vue-loading-skeleton';
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueCountryCode);
Vue.use(VueTheMask);
Vue.prototype._closeModal = new Vue();
Vue.use(Skeleton)
Vue.use(VueLazyLoad)
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);
Vue.use(require('vue-cookies'))

Vue.config.productionTip = false;

Vue.prototype.$api = api;

var filter = function(text, length, clamp){
  clamp = clamp || '';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");


window.paymentSuccess = function () {
  // if (cb && typeof cb === 'function') return cb();
}
