<template>
  <div class="toplanan-bagis-infographic row justify-content-around" v-lazy-container="{ selector: 'img' }">
    <!-- <div class="d-flex flex-column align-items-center">
      <img src="/svg/sehir-pin.svg" class="mb-4" />
      <h3 class="count" data-count="461.200">461.200</h3>
      <p>Çocuk</p>
    </div> -->

    <div class="d-flex flex-column align-items-center">
      <img data-src="/svg/hands.svg" class="mb-4" width="210" height="129" />
      <h3 class="count" :data-count="countTeacher">0</h3>
      <p>Öğretmen</p>
    </div>

    <div class="d-flex flex-column align-items-center">
      <img data-src="/svg/okul.svg" class="mb-4" width="172" height="137" />
      <h3 class="count" :data-count="countSchool">0</h3>
      <p>Okul</p>
    </div>

    <div class="d-flex flex-column align-items-center">
      <img data-src="/svg/ogrenci.svg" class="mb-4" width="175" height="137" />
      <h3 class="count" :data-count="countStudent">0</h3>
      <p>Çocuk</p>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ToplananBagisInfographic",
  props: [
    "countTeacher",
    "countSchool",
    "countStudent",
  ],
  mounted() {
    this._countWithScrol();
  },
  methods: {
    _countWithScrol() {
      let countMe = () => {
        const counters = document.querySelectorAll(".count");

        counters.forEach(counter => {
          counter.innerText = '0'
          const target = +counter.getAttribute('data-count');
          const interval = target / 100;
          
          const updateCounter = () => {
            const value = +counter.innerText;
            if (value < target) {
              counter.innerText = Math.ceil(value + interval);
              
              setTimeout(() => {
                updateCounter()
              }, 20);
            }
          }

          updateCounter();
        });
      };


      setTimeout(() => {
        ScrollTrigger.create({
          trigger: ".toplanan-bagis-infographic",
          start: "top center",
          toggleActions: "restart pause reverse pause",
          markers: false,
          onEnter: countMe,
          onLeaveBack: self => self.disable()
        });
        
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
@media (max-width:567px) {
    .toplanan-bagis-infographic{
      .d-flex{
        width: 50%;
      }
    }
}
</style>