<template>
  <transition name="fade">
    <div class="modal donors-modal" v-if="show">

      <div class="modal-message scroll-modal">
        <div class="modal-header">
          <!-- <h1>Başlık</h1> -->
          <p @click="closeModal" class="ml-auto">Kapat</p>
        </div>
        <div class="donors" v-html="data">
          
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalGlobal",
  props: ["data"],
  data(){
    return{
      show: false,
    }
  },
  mounted(){
    // Listen ESC for close
    window.addEventListener('keydown', (e)=>{
      var key = e.which || e.keyCode;
      if (key === 27) {
        this.closeModal();
      }
    });
  },
  methods:{
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  }
}
</script>

<style lang="scss">

</style>