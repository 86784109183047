<template>
  <div :class="`progress-wrapper ${bgColor} ${size}`">
    <div class="progress-value" :style="{width : value + '%'}"></div>
    <span class="percentage">%{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: ["value", "barColor", "bgColor", "size"],
}
</script>

<style lang="scss">
.progress-wrapper {
  background: #fff;
  justify-content: flex-start;
  border-radius: 10px;
  align-items: center;
  position: relative;
  padding: 0;
  display: flex;
  height: 50px;
  width: 100%;
  &.mini{
    height: 5px;
    border-radius: 8px;
    .progress-value{
      border-radius: 8px 0 0 8px;
    }
    span.percentage{
      display: none;
    }
  }
  &.blue{ 
    background: var(--blueLight);
    span.percentage{
      color: #fff;
    }  
  }
  .progress-value {
    // animation: load 3s normal forwards;
    // box-shadow: 0 0px 20px -6px var(--orange);
    background: var(--orange);
    height: 100%;
  
    width: 0;
    border-radius: 10px ;
    // transition: var(--slow);
    transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  span.percentage{
    font-size: 1.3rem !important;
    font-weight: 600;
    color: var(--orange);
    margin-left: auto;
    margin-right: 1rem;
    position: absolute;
    right: 0;
  }
}


@keyframes load {
  0% { width: 0; }
  100% { width: 68%; }
}
</style>