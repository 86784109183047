var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"kampanya-olustur d-flex flex-column flex-md-row h-100 w-100",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"\n      sides\n      left-side\n      section-wrapper\n      d-flex\n      align-items-center\n      justify-content-center\n    "},[_c('div',{staticClass:"\n        w-100\n        mb-5\n        sides-container\n        d-flex\n        flex-column\n        justify-content-center\n      "},[_c('h3',{staticClass:"mb-5"},[_vm._v("Bilgileriniz")]),_c('div',{staticClass:"d-flex align-items-top"},[_c('div',{staticClass:"image-wrapper position-relative"},[_c('img',{staticClass:"frame-img form-img",attrs:{"src":_vm.url}})]),_c('div',{staticClass:"left-detail ml-3 mt-2"},[_c('p',{staticClass:"font-bold text-white"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"text-white p-medium mt-1"},[_vm._v(" "+_vm._s(_vm.desc)+" ")])])]),_c('div',{staticClass:"form-wrapper"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"userName"}},[_vm._v("İsim Soyisim")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.userName),expression:"form.userName"}],staticClass:"form-control",class:{
              error: _vm.$v.form.userName.$error,
              valid: _vm.$v.form.userName.$dirty && !_vm.$v.form.userName.$invalid,
            },attrs:{"type":"text"},domProps:{"value":(_vm.form.userName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "userName", $event.target.value)},_vm.removeSpace]}}),(!_vm.$v.form.userName.alpha)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" Ad Soyad şeklinde giriniz. ")]):_vm._e(),(!_vm.$v.form.userName.nameSurnameLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" En az 2 karakter girmelisiniz ")]):_vm._e(),(!_vm.$v.form.userName.minLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" En az 2 karakter girmelisiniz ")]):_vm._e(),(!_vm.$v.form.userName.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" Bu alan zorunludur. ")]):_vm._e()]),_c('div',{staticClass:"d-flex row"},[_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{attrs:{"for":"userMail"}},[_vm._v("E-Posta")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.userMail),expression:"form.userMail"}],staticClass:"form-control",class:{
                error: _vm.$v.form.userMail.$error,
                valid: _vm.$v.form.userMail.$dirty && !_vm.$v.form.userMail.$invalid,
              },attrs:{"inputmode":"email","type":"email"},domProps:{"value":(_vm.form.userMail)},on:{"keydown":_vm.key,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "userMail", $event.target.value)}}}),(!_vm.$v.form.userMail.email)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Yanlış mail formatı ")]):_vm._e(),(!_vm.$v.form.userMail.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Bu alan zorunludur. ")]):_vm._e()]),_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{attrs:{"for":"userPhone"}},[_vm._v("Telefon Numarası")]),_c('div',{staticClass:"d-flex"},[_c('vue-country-code',{attrs:{"defaultCountry":"TR","preferredCountries":['vn', 'us', 'gb']},on:{"onSelect":_vm.onSelect},model:{value:(_vm.dial),callback:function ($$v) {_vm.dial=$$v},expression:"dial"}}),_c('the-mask',{staticClass:"form-control",class:{
                  error: _vm.$v.form.userPhone.$error,
                  valid:
                    _vm.$v.form.userPhone.$dirty && !_vm.$v.form.userPhone.$invalid,
                },attrs:{"name":"phone","id":"frmPhoneNumA","autocomplete":"tel","mask":"(###) ### ## ##","placeholder":"(---) --- -- --","type":"tel"},model:{value:(_vm.form.userPhone),callback:function ($$v) {_vm.$set(_vm.form, "userPhone", $$v)},expression:"form.userPhone"}})],1),(!_vm.$v.form.userPhone.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Bu alan zorunludur. ")]):_vm._e(),(!_vm.$v.form.userPhone.minLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Yanlış telefon formatı ")]):_vm._e()])])])])]),_c('div',{staticClass:"\n      sides\n      right-side\n      section-wrapper\n      d-flex\n      flex-column\n      align-items-center\n      justify-content-center\n    "},[_c('div',{staticClass:"\n        w-100\n        sides-container\n        d-flex\n        flex-column\n        justify-content-center\n        mt-5 mt-md-0\n      "},[_c('h3',{staticClass:"mb-5"},[_vm._v("Gönderilecek Kişinin Bilgileri")]),_c('div',{staticClass:"form-wrapper"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nameToSend"}},[_vm._v("İsim Soyisim")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nameToSend),expression:"form.nameToSend"}],staticClass:"form-control",class:{
              error: _vm.$v.form.nameToSend.$error,
              valid:
                _vm.$v.form.nameToSend.$dirty && !_vm.$v.form.nameToSend.$invalid,
            },attrs:{"type":"text"},domProps:{"value":(_vm.form.nameToSend)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "nameToSend", $event.target.value)},_vm.removeSpace]}}),(!_vm.$v.form.nameToSend.alpha)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" Ad Soyad şeklinde giriniz. ")]):_vm._e(),(!_vm.$v.form.nameToSend.nameSurnameLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" En az 2 karakter girmelisiniz ")]):_vm._e(),(!_vm.$v.form.nameToSend.minLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" En az 2 karakter girmelisiniz ")]):_vm._e(),(!_vm.$v.form.nameToSend.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" Bu alan zorunludur. ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"sendToMail"}},[_vm._v("E-Posta")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sendToMail),expression:"form.sendToMail"}],staticClass:"form-control",class:{
              error: _vm.$v.form.sendToMail.$error,
              valid:
                _vm.$v.form.sendToMail.$dirty && !_vm.$v.form.sendToMail.$invalid,
            },attrs:{"inputmode":"email","type":"email"},domProps:{"value":(_vm.form.sendToMail)},on:{"keydown":_vm.key,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sendToMail", $event.target.value)}}}),(!_vm.$v.form.sendToMail.email)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Yanlış mail formatı ")]):_vm._e(),(!_vm.$v.form.sendToMail.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Bu alan zorunludur. ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Kişiye Özel Notunuz")]),_c('span',{staticClass:"char-count",domProps:{"textContent":_vm._s('* karakter')}})]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.messageToSend),expression:"form.messageToSend"}],staticClass:"form-control",attrs:{"rows":"1"},domProps:{"value":(_vm.form.messageToSend)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "messageToSend", $event.target.value)}}}),_c('p',{staticClass:"form-warning"},[_vm._v("Bu alan zorunludur.")])])]),_c('label',{staticClass:"custom-checkbox"},[_c('a',{attrs:{"href":"https://www.bilimseferberligi.org/Bilim_Seferberligi_kisisel_verilerin_korunmasi_ve_islenmesi_politikasi2.pdf","target":"_blank"}},[_vm._v("KVKK ")]),_vm._v(" okudum, onaylıyorum. "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.terms_accepted),expression:"form.terms_accepted"}],class:{
            error: _vm.$v.form.terms_accepted.$error,
            valid:
              _vm.$v.form.terms_accepted.$dirty &&
              !_vm.$v.form.terms_accepted.$invalid,
          },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.terms_accepted)?_vm._i(_vm.form.terms_accepted,null)>-1:(_vm.form.terms_accepted)},on:{"change":function($event){var $$a=_vm.form.terms_accepted,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "terms_accepted", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "terms_accepted", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "terms_accepted", $$c)}}}}),_c('span',{staticClass:"checkmark"})]),_c('ButtonRounded',{staticClass:"ml-auto",attrs:{"Text":"Devam Et","isRouting":false,"disabled":_vm.disabled}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }