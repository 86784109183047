<template>
  <router-link to="" :class="'user-card d-flex align-items-center ' + fontColor">
    <img src="/img/oval.png">
    <div class="ml-2">
      <span>Hoşgeldin</span>
      <p>Indira Shree</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "MobileMenuUser",
  props: ["fontColor"]
}
</script>

<style lang="scss">
a.user-card{
  color: var(--whiteOld);
  line-height: 1.2;
  &.blue{ color: var(--purpleDark); }
  span{
    opacity: .5;
    font-size: 0.9rem;
    // color: var(--purpleDark);
  }
  p{
      font-size: 1.1rem;
      // color:var(--purpleDark);
  }
}
</style>