<template>
  <div class="d-flex w-100 h-100 signup">
    <div
      class="
        sides
        left-side
        section-wrapper
        position-relative
        d-flex
        flex-column
        align-items-start
        justify-content-center
      "
    >
      <loading
        :active.sync="isLoading"
        background-color="#332abb"
        :opacity="0.99"
        color="#fff"
        :is-full-page="fullPage"
      ></loading>

      <h2 v-if="mailSend" v-html="mailResponse"></h2>
      <form
        @submit.prevent="submitForm"
        autocomplete="off"
        class="w-100"
        v-if="!mailSend"
      >
        <h2>Şifremi Unuttum</h2>
        <p>
          Lütfen, siteye kayıt olduğun e-posta adresini gir.
        </p>
        <div class="form-group mt-5 mb-3">
          <label for="userMail">E-Posta Adresin</label>
          <input
            v-model="form.userMail"
            v-on:keydown="key"
            v-bind:class="{
              error: $v.form.userMail.$error,
              valid: $v.form.userMail.$dirty && !$v.form.userMail.$invalid,
            }"
            inputmode="email"
            type="email"
            class="form-control"
          />
          <p class="form-warning" v-if="!$v.form.userMail.email">
            <span class="svg-icon icons-alert"></span>Yanlış mail formatı
          </p>
          <p class="form-warning" v-if="!$v.form.userMail.required">
            <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
          </p>
        </div>

        <ButtonRounded
          class="float-right"
          Text="Şifremi Gönder"
          :isRouting="false"
          :disabled="disabled"
        />
      </form>
      <button
        @click="backNav()"
        type="button"
        class="back-button backforgot ml-auto d-flex align-items-center"
      >
        <span class="svg-icon icons-back-arrow mr-1"></span>
        <p class="d-flex flex-column align-items-start">Geri Dön</p>
      </button>
    </div>

    <div
      class="
        sides
        right-side
        section-wrapper
        d-none d-md-flex
        flex-column
        align-items-center
        justify-content-between
        position-relative
      "
    >
      <div class="h-100 d-flex flex-column justify-content-center">
        <img class="" src="/svg/login.svg" />
      </div>

  
    </div>
  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import { required, email } from "vuelidate/lib/validators";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "ForgotPassword",
  components: {
    ButtonRounded,
    Loading,
  },
  props: ["formValue"],
  data() {
    return {
      disabled: false,
      isLoading: false,
      fullPage: false,
      form: {
        userMail: "",
      },
      mailResponse: "",
      mailSend: false,
    };
  },
  validations: {
    form: {
      userMail: {
        required,
        email: email,
      },
    },
  },
  methods: {
    backNav(){
      this.$parent.slideToPage(0);
      this.mailSend = false;
    },
    closeModal() {
      this.$parent.destroyCarousel();
      this.$parent.closeModal();
    },
    doAjax() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    removeSpace: function (event) {
      this.form.userName = this.form.userName
        .replace(/\s\s+/g, " ")
        .trimStart();
    },
    key: function (event) {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        this.disabled = true;
        let params = {};
        params["email"] = this.form.userMail;
        this.$api.forgotPassword(params).then((response) => {
          this.disabled = false;
          this.mailSend = true;
          this.mailResponse = response.message;
          this.disabled = false;
        });
      } else {
        
      }
    },
  },
};
</script>
<style lang="scss">
.backforgot{
  color: var(--blueOcean) !important;
  position: absolute;
  bottom: 7vh;
  left: 6vw;
  span{
    background: var(--blueOcean);
  }
}
</style>