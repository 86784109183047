<template>
  <section class="section-padding quotes">
      <div class="swiper-quotes">
        <div class="swiper-wrapper">
          <div class="swiper-slide " v-for="(quote, index) in quotes" :key="index">
            <div class="container text-center">
                <div class="quotes-text d-flex flex-column flex-md-row align-items-center">
                  <span class="svg-icon icons-quotesLeft"></span>
                  <p>{{quote.comment}}</p>
                  <span class="svg-icon icons-quotesRight"></span>
                </div>

                <div class="quotes-profile">
                  <picture>
                    <source :srcset="quote.image.replace('jpg', 'webp').replace('png', 'webp')" type="image/webp">
                    <img :data-src="quote.image" alt="Görsel">
                  </picture>
                  <h3 class="name">{{quote.name_surname}}</h3>
                  <p class="title">{{quote.title}}</p>
                </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagg d-flex w-100 justify-content-center"></div>
      </div>
    </section>
</template>

<script>
import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";
export default {
  name: "Quotes",
  data(){
    return{
      quotes: [],
    };
  },
  methods: {
    getComment(){
      this.$api.getComments().then(response =>{
        this.quotes = response;
      })
    },
    _swiperInit(){
       const swiperQ = new Swiper(
        ".swiper-quotes",
        {
          spaceBetween: 0,
             allowTouchMove: true,
    paginationClickable: true,
          observer: true,
          observeParents: true,
          pagination: {
            el: ".swiper-pagg",
            clickable: true,
          },
          breakpoints: {
            567: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
          },
        }
      );
    }
  },
  mounted(){
    this.getComment();
    this._swiperInit();
  }
}
</script>

<style lang="scss">
.quotes{
  .container{
    width: 50%;
  }
  .quotes-text{
    margin-bottom: 2.5rem;
  }
  .svg-icon{
    width: 6rem;
    height: 3rem;
    background: var(--green);
    &.icons-quotesLeft{margin-right: 2rem;}
    &.icons-quotesRight{margin-left: 2rem;}
  }

  .quotes-profile{
    h3{
      margin: .6rem 0 .2rem 0;
    }
    img{
      width: 120px;
      height: auto;
      aspect-ratio: 120 / 124;
    }
  }

  @media (max-width: 768px){
    .container{
      width: 100%;
    }
  }
  
  @media (max-width: 567px){
    .svg-icon{
      width: 3rem;
      &.icons-quotesLeft{margin: 0}
      &.icons-quotesRight{margin: 1rem 0 0 0;}
    }
  }
}
</style>