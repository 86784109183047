<template>
  <div class="birBagisYap padding-wrapper w-100">
    <div class="d-flex flex-column flex-md-row mb-4 mb-md-0">
      <div class="flex-4">
        <h3>{{donationData.detail.title}}</h3>
        <p class="campaign-desc mt-2 mb-4" v-html="donationData.detail.message"></p>
        <UserCard class="mb-4 mb-md-0" :image="donationData.campaign_user.image" :userName="donationData.campaign_user.name + ' ' + donationData.campaign_user.surname"/>
      </div>
      
      <div class="flex-3 text-right">
        <div class="planned-donation">
          <ProgressBar :value="donationData.total.percent" size="mini"/>

          <div class="d-flex align-items-center justify-content-between mt-3">
            <div class="text-left">
              <h3>{{donationData.total.donations_collected}}</h3>
              <div class="d-flex">
                <p class="mr-1">{{donationData.detail.full_price}}</p>
                <span>Hedeflenen Bağış</span>
              </div>

            </div>
            <div>
              <h3>34</h3>
              <span>Gün kaldı</span>
            </div>
          </div>
        </div>
      </div>
    </div>
<div class="d-flex flex-wrap flex-md-nowrap justify-content-center">

    <div class="d-flex flex-wrap flex-md-nowrap justify-content-center flex-four">

      <div v-for="item in donationData.donation_list" :key="item.id"
      :id="item.id"
      class="bagis-card" @click.self="selectDonation($event, 'bagis', 'auto', item.id, item.price, 1)">
        <img src="/svg/check.svg" class="check-icon">
        <div class="price">{{ item.price.split('.')[0] }} TL</div>
        <div class="price-card-desc">
          <p>{{item.title}}</p>
          <span>{{item.description}}</span>
        </div>
      </div>     

      <div class="bagis-card d-md-none d-block" @click="selectDonation($event, 'bilimseti', 'auto', 0, remainingPrice , formValue.amount)">
        <img src="/svg/check.svg" class="check-icon">
        <div class="price">{{donationData.total.remaining_donation}}</div>
        <div class="price-card-desc">
          <p>Kalan Bağış Tutarı</p>
          <span class="d-none">Bir Bilim Setinin bedeli 
            <strong>3.700TL</strong>'dir, her setten 
            <strong>80 çocuk</strong> faydalanır.</span>
          
        </div>
      </div>



      <div class="bagis-card d-md-block d-none" @click="selectDonation($event, 'bilimseti', 'auto', 0, remainingPrice, formValue.amount)">
        <img src="/svg/check.svg" class="check-icon">
        <div class="price">{{donationData.total.remaining_donation}}</div>
        <div class="price-card-desc">
          <p>Kalan Bağış Tutarı</p>
          <span>Bir Bilim Setinin bedeli 
            <strong>3.700TL</strong>'dir, her setten 
            <strong>80 çocuk</strong> faydalanır.</span>
          
        </div>
      </div>
      <div class="bagis-card text-donation d-md-block d-none" @click="selectDonation($event, 'bagis', 'manuel', 0, 0, 1)">
        <img src="/svg/check.svg" class="check-icon">
        <the-mask
            :tokens="minVal"
            mask="XYYY"
            placeholder="0 TL"
            v-model="donationValue" 
            v-on:input='donationValue >= 25 ? bagisCardIsSelected = true : bagisCardIsSelected = false'
            type="tel" class="price" />
        <div class="price-card-desc">
          <p>Kendin Belirle</p>
          <span>Bağış yapmak istediğin tutarı kendin belirle. <br /> (min. 25TL)</span>
        </div>
      </div>
    </div>
      <div class="bagis-card d-md-none d-block text-donation mt-0 " @click="selectDonation($event, 'bagis', 'manuel', 0, 0, 1)">
        <img src="/svg/check.svg" class="check-icon">
        <the-mask
            :tokens="minVal"
            mask="XYYY"
            placeholder="0 TL"
            v-model="donationValue" 
            v-on:input='donationValue >= 25 ? bagisCardIsSelected = true : bagisCardIsSelected = false'
            type="tel" class="price" />
        <div class="price-card-desc">
          <p>Kendin Belirle</p>
          <span>Bağış yapmak istediğin tutarı kendin belirle. <br /> (min. 25TL)</span>
        </div>
      </div>
</div>
    <div class="d-flex justify-content-between justify-content-md-end flex-column flex-md-row">
   <div class="d-flex justify-content-end">
        <label class="custom-checkbox d-flex align-items-center">İsmimi Gizle
        <input type="checkbox" ref="hideName" v-model="checkedName">
        <span class="checkmark"></span>
      </label>
      
      <label class="custom-checkbox mx-1 mx-md-5 d-flex align-items-center">Bağış Miktarını Gizle
        <input type="checkbox" ref="hidePrice" v-model="checkedPrice">
        <span class="checkmark"></span>
      </label>
      
   </div>
      <div class="d-flex align-items-center justify-content-end">
            <div class="load" v-if="loading"></div>
            <ButtonRounded Text="Devam Et" :isRouting="false" :disabled="!bagisCardIsSelected" @click.native="formValidation"/>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import UserCard from "@/components/UserCard";
import ProgressBar from "@/components/ProgressBar";

export default {
  name: "KampanyaBagis",
  components:{
    ButtonRounded, UserCard, ProgressBar
  },
  props: ['donationData'],
  watch:{
    checkedName(){
      this.formValue.hideName = this.checkedName;
    },
    checkedPrice(){
      this.formValue.hidePrice = this.checkedPrice;
    },
  },
  data(){
    return{
      checkedName: false,
      checkedPrice: false,
      toggleLabels: ["tek sefer", "düzenli"],
      selected: '',
      bagisCardIsSelected: false,
      isDisabled: true,
      donationValue: '',
      progressBarValue: '',
      donationList : {},
      remainingPrice: 0,
      formValue: {
        donation_type: '',
        selected_type: '',
        donation_status: 'teksefer',
        donation_id: 0,
        price: 0, 
        amount: 1,
        hideName: false,
        hidePrice: false,
      },
      loading: false,
      minVal: {
        X: { pattern: /[1-9]/ },
        Y: { pattern: /[0-9]/ }
      }
    }
  },
  mounted(){
    this.getDonations();
  },
  methods:{
    progressBar(x){
      this.progressBarValue = x;

    },
    getDonations(){
      let params = {};
      params["type"] = "normal";
      this.$api.donationList(params).then(response =>{
        this.donationList = response;
        let newData = this.donationList.splice(2, 1); 
        //console.log(this.donationData);
        let pprice = this.donationData.total.remaining_donation.slice(0, -3);
        this.remainingPrice  = pprice.split(',').join('')
        //console.log(parseInt(newp));
        
      });
    },
    checkboxValue(e){
      e == true ? this.formValue.donation_status = "duzenli" : this.formValue.donation_status = "teksefer";
      this.$store.commit('_donation_status', this.formValue.donation_status);
    },
    selectDonation(e, type, selected_type, donation_id, price, amount){ 
      $(".bagis-card").not(e.target).removeClass("active");

      if($(e.target).prop('nodeName') == "SPAN" || $(e.target).prop('nodeName') == "INPUT"){
        $(e.target).closest(".bagis-card").addClass("active");
      }else{
        e.target.classList.add("active");
      }

    this.bagisCardIsSelected = true;
      if(type == 'bagis' && selected_type == 'manuel'){
        
        this.bagisCardIsSelected = false;
        if(this.donationValue){
          if(this.donationValue < 25 ){
            this.bagisCardIsSelected = false;
          }else{
            this.bagisCardIsSelected = true;
          }
        }else{}
      }
      if( $(".text-donation").hasClass("active") && $(".text-donation input").val() == "" ){
        this.bagisCardIsSelected = false;
      }
      this.formValue.donation_type = type;
      this.formValue.selected_type = selected_type;
      this.formValue.donation_id = donation_id;
      this.formValue.price = price;
      this.formValue.amount = amount;

      this.$store.commit('_donation_status', this.formValue.donation_status);
      //console.log(this.formValue.price);
      // Slide End
      var elem = document.querySelector(".modal-wrapper");
      elem.scrollIntoView({behavior: "smooth", block: "end"});
    },
    formValidation(){ 

      if(this.formValue.selected_type == "manuel" && this.formValue.donation_type == "bagis"){
        this.formValue.price = this.donationValue;
      }

      this.$refs.hideName.checked ? this.formValue.hideName = "false" : this.formValue.hideName = "true";
      this.$refs.hidePrice.checked ? this.formValue.hidePrice = "false" : this.formValue.hidePrice = "true";
      this.loading = true
      let params = {};
      params['price'] = this.formValue.price
      params['selected_type'] = this.formValue.selected_type 
      params['donation_id'] =  this.formValue.donation_id 
      params['donation_type'] = this.formValue.donation_type
      this.$api.validCampaignInfoCreate(params).then((response) => {
        console.log(response);
        if(response.success){
          this.bagisCardIsSelected = true;
          this.$store.commit('_hideDonationName', this.formValue.hideName);
          this.$store.commit('_hideDonationPrice', this.formValue.hidePrice);
          this.$store.commit('_formValue', this.formValue);
          this.$store.commit('_selectedDonatinPrice', this.formValue.price);
          //console.log(this.formValue);
          this.bagisCardIsSelected ? this.slideToNext(2) : '';
          var elem = document.querySelector(".modal-wrapper");
          elem.scrollIntoView({behavior: "smooth"});
        }else{
          this.bagisCardIsSelected = false;
        }
       setTimeout(() => {
        this.loading = false
       }, 1000);
      })
      
     
    },
    slideToNext (event) {
      this.$emit('clicked');
    },
  }
}
</script>

<style lang="scss">
.planned-donation{
  background: var(--purpleDark);
  // border: 2px solid #4f45db;
  border-radius: 10px;
  padding: 1.5rem 1rem;
  h3{
    font-size: 2.3rem;
    font-weight: 600;
    color: var(--orange)
  }
  p{
    font-weight: 700;
  }
}
@media (max-width:567px) {
  .flex-four{
    .bagis-card{
          margin: 0 2px .5rem 0;
    padding: 1.5rem 1rem;
    width: 49%;
        height: 170px;
    }
  }
  .text-donation{
    width: 100% !important;
    input{
      max-width: 100% !important; 
    }
  }
  
}

.campaign-desc{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

</style>