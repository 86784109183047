<template>

  <transition
    enter-active-class="animate__animated animate__fadeInDown"
    leave-active-class="animate__animated animate__fadeOutUp"
    mode="out-in"
  >
 
    <div v-if="!isModal"
      :class="
        isModal
          ? 'isModal bagisSec d-flex flex-column align-items-center justify-content-center h-100'
          : 'bagisSec notModal d-flex flex-column align-items-center justify-content-center h-100'
    ">

      <ModalSignup ref="modalSignup"></ModalSignup>
      <div v-if="Title" class="text-center mb-5">
        <h1 v-html="Title"></h1>
        <p v-html="Desc"></p>
      </div>
      
      <div class="swiper-bagis-sec swiper-carousel position-relative">
        <div class="swiper-wrapper d-flex flex-row flex-sm-column flex-md-row mb-5" v-lazy-container="{selector: 'img'}">
          <router-link :to="{ name: 'BagisYapView'}" class="bagis-card swiper-slide d-flex flex-column align-items-center justify-content-between text-center">
            <div class="img-wrapper d-flex align-items-center justify-content-center">
              <img data-src="/svg/bagis-yap.svg" width="123" height="200" />
            </div>

            <div class="w-100">
              <h3>Bağış Yap</h3>
              <p>
                Düzenli ya da tek sefer yapacağınız bağış ile Türkiye’nin dört bir köşesindeki çocuklara son teknoloji bilim setleri gönderilmesine katkı sağlayabilirsiniz.
 
              </p>
            </div>
          </router-link>

          <a @click="showKampanyaOlustur()" class="bagis-card swiper-slide d-flex flex-column align-items-center justify-content-between text-center">
            <div class="img-wrapper d-flex align-items-center justify-content-center">
              <img data-src="/svg/kampanya-olustur.svg"  width="190" height="153" />
            </div>

            <div class="w-100">
              <h3>Kampanya Oluştur!</h3>
              <p>
              Özel günlerinizde, kurumsal etkinliklerinizde bireysel ya da kurumsal kampanyalar açabilirsiniz.
              </p>
            </div>
          </a>

          <router-link :to="{ name: 'HediyeKartiView'}" class="bagis-card swiper-slide d-flex flex-column align-items-center justify-content-between text-center">
            <div class="img-wrapper d-flex align-items-center justify-content-center">
              <img data-src="/svg/hediye-karti-gonder.svg" width="190" height="97" />
            </div>

            <div class="w-100">
              <h3>Hediye Kartı Gönder!</h3>
              <p>
                Özel günlerde sevdikleriniz adına bağış kartları alabilirsiniz.
              </p>
            </div>
          </router-link>
        </div>
        <div class="d-flex d-md-none justify-content-center">
        <div class=" el-pag w-100 d-flex align-items-center justify-content-center"></div>
        </div>
      </div>
    </div>

    <div class="modal main-modal" v-if="show">
      <div class="modal-container">
        <div class="modal-wrapper">
          <div
            :class="
              isModal
                ? 'isModal bagisSec d-flex flex-column align-items-center justify-content-center h-100'
                : 'bagisSec d-flex flex-column align-items-center justify-content-center h-100'
            "
          >
              <ModalSignup ref="modalSignup"></ModalSignup>
            <div class="d-flex flex-column flex-md-row container" v-lazy-container="{selector: 'img'}">
              
              <router-link :to="{ name: 'BagisYapView'}"
                class="bagis-card d-flex flex-column align-items-center justify-content-between text-center">
                <div
                  class="img-wrapper d-flex align-items-center justify-content-center"
                >
                  <img data-src="/svg/bagis-yap.svg" />
                </div>

                <div class="w-100">
                  <h3>Bağış Yap</h3>
                  <p>
                   Düzenli ya da tek sefer yapacağınız bağış ile Türkiye’nin dört bir köşesindeki çocuklara son teknoloji bilim setleri gönderilmesine katkı sağlayabilirsiniz.
 
                  </p>
                </div>
              </router-link>

              <a @click="showKampanyaOlustur()"
                class="bagis-card d-flex flex-column align-items-center justify-content-between text-center">
                <div
                  class="img-wrapper d-flex align-items-center justify-content-center"
                >
                  <img data-src="/svg/kampanya-olustur.svg" />
                </div>

                <div class="w-100">
                  <h3>Kampanya Oluştur!</h3>
                  <p>
                     Özel günlerinizde, kurumsal etkinliklerinizde bireysel ya da kurumsal kampanyalar açabilirsiniz.
                  </p>
                </div>
              </a>

              <router-link :to="{ name: 'HediyeKartiView'}"
                class="bagis-card d-flex flex-column align-items-center justify-content-between text-center"
              >
                <div
                  class="img-wrapper d-flex align-items-center justify-content-center"
                >
                  <img data-src="/svg/hediye-karti-gonder.svg" />
                </div>

                <div class="w-100">
                  <h3>Hediye Kartı Gönder!</h3>
                  <p>
                    Özel günlerde sevdikleriniz adına bağış kartları alabilirsiniz.
                  </p>
                </div>
              </router-link>
            </div>
          </div>
          
          <FrameFooter @clicked="prevSlide; destroyModal" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";
import ModalSignup from "@/components/ModalSignup";
import FrameFooter from "@/components/FrameFooter";

export default {
  name: "BagisSec",
  props: {
    Title: String,
    Desc: String,
    isModal: Boolean,
  },
  components: {
    FrameFooter,ModalSignup
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    // Listen ESC for close
    window.addEventListener('keydown', (e)=>{
      var key = e.which || e.keyCode;
      if (key === 27) {
        this.closeModal();
      }
    });
    this._swiperInittt();
  },
  methods: {
    showBirBagisYap() {
      this.$emit("showBirBagisYap");
    },
    showKampanyaOlustur() {
      console.log("qwewqeqw");
      const getState = this.$store.state.loginState;
      // console.log(getState);
      if(getState === true){
        this.$router.push('/kampanya-olustur')
      }else{
     //  this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
      this.showLoginModal();
      }
    },
    showLoginModal() {
      // this.$parent.showSignupModal();
      this.$refs.modalSignup.openModal();
      this.$refs.modalSignup.getSwiper();
      $(".mobile-wrapper").removeClass("menu-opened");
      $(".menu-mobile").removeClass("actived");
    },
    showHediyeKarti() {
      this.$emit("showHediyeKarti");
    },
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    destroyCarousel() {
      this.swiperModal = "";
    },
    _swiperInittt() {
      this.swiper = new Swiper(".swiper-bagis-sec", {
        loop: false,
        slideToClickedSlide: true,
        // paginationClickable: false,
        // spaceBetween: 0,
        breakpoints: {
          "576": {
            slidesPerView: 1,
            allowTouchMove: false,
            grabCursor: false,
            simulateTouch: false,
          },
          "768": {
            slidesPerView: 3,
            allowTouchMove: false,
            grabCursor: false,
            simulateTouch: false,
          },
        },
        pagination: {
          el: ".el-pag",
          clickable: true,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.bagisSec {
  // height: calc(100vh - 150px);
  width: 100vw;
  background: var(--purpleLight);
  color: #fff;
  padding: 5rem 0;
  .img-wrapper{
    img{
      max-width: 190px;
      // height: 190px;
    }
  }
  &.isModal {
    background: var(--blueOcean);
    .bagis-card {
      &:hover {
        background: rgb(52, 56, 186);
      }
    }
  }
  &.notModal {
    .swiper-bagis-sec{
      width: 100%;
    }
    @media (max-width: 567px) {
      .bagis-card {
        width: 100% !important;
        margin-right: 0;
      }
    }
  }

  .bagis-card {
    padding: 2rem 1rem;
    margin: 0;
    margin-right: 0.5rem;
    border-radius: 20px;
    @media (min-width:768px) {
        width: 33%;
    }
    p{
      min-height: 90px;
    }
    /*  IE BUG  */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* detect IE */
      width: 33%
    }
    /*  IE BUG  */
    &:hover {
      background: #3221ad;
    }
    &:last-child{
      margin-right: 0px !important;
    }
  }

  .swiper-carousel .el-pag .swiper-pagination-bullet {
    background: #fff;
    margin: 0px 5px;
  }
}
</style>
