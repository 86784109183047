<template>
  <div class="form-success d-flex w-100 h-100 flex-md-row flex-column">
    <div
      class="sides left-side section-wrapper d-md-flex align-items-center justify-content-center"
    >
      <div class="sides-container d-flex flex-column justify-content-around">
        <div>
          <h3>Teşekkürler</h3>
          
          <p class="mt-3" v-if="campaignSuccess">Kampanya başvrunuz tamamlandı. Yapılacak değerlendirmenin ardından, kampanyanız yayına alınacaktır. Kampanyanızın durumunu Kampanyalarım sayfasından takip edebilirsiniz.</p>
          <h1 >{{ this.$store.state.userInfo.full_name }}</h1>
        </div>
        <div>
          <div>
            <h2 v-if="campaignSuccess">Kampanyanız oluşturuldu.</h2>
            <h2 v-if="!campaignSuccess">{{ this.$store.state.userInfo.total_price }}</h2>
            <p>
              
İmkanları sınırlı, hayalleri sınırsız çocuklarımızı bilim setleriyle buluşturmak adına destek olduğunuz için teşekkür ederiz.
              <span
                > Daha çocuk çocuğun bilimle buluşmasını sağlamak için hemen paylaşın.</span
              >
            </p>

            <div class="d-flex justify-content- mt-4 flex-wrap" v-if="successType != 'campaignDonate'">
              
              <a class="flex-1 mr-4 mb-4 button-rounded d-flex align-items-center justify-content-center blue fs-09"  
                href="https://twitter.com/intent/tweet?text=İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur=https://bilimseferberligi.org/" data-via="BilimSeferberliği" target="_blank">Twitter'da Paylaş</a>
                    
               <a class="flex-1 mr-4 mb-4 button-rounded d-flex align-items-center justify-content-center blue fs-09" 
                href="http://www.facebook.com/sharer/sharer.php?u=https://bilimseferberligi.org/&amp;title=İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur." target="_blank">Facebook’da Paylaş</a>
               
               <a class="flex-1 mr-4 mb-4 button-rounded d-flex align-items-center justify-content-center blue fs-09" 
                href="https://www.linkedin.com/shareArticle?mini=true&url=https://bilimseferberligi.org&title=Bilim BilimSeferberliği&summary=İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur" target="_blank">Linkedin'de Paylaş</a>
               
               <a class="flex-1 mr-4 button-rounded d-flex align-items-center justify-content-center blue fs-09" 
                href="https://wa.me/?text=https://bilimseferberligi.org" rel="noreferrer" target="_blank">Whatsapp'ta Paylaş</a>

            </div>

            <div class="d-flex justify-content- mt-4 flex-wrap" v-if="successType == 'campaignDonate'">

              <a @click="twShare" class="flex-1 mr-4 mb-4 button-rounded d-flex align-items-center justify-content-center blue fs-09" href="" target="_blank">Twitter'da Paylaş</a>
                    
               <a @click="fbShare" class="flex-1 mr-4 mb-4 button-rounded d-flex align-items-center justify-content-center blue fs-09" href="" target="_blank">Facebook’da Paylaş</a>
               
               <a @click="linkedinShare" class="flex-1 mr-4 mb-4 button-rounded d-flex align-items-center justify-content-center blue fs-09" href="" target="_blank">Linkedin'de Paylaş</a>
               
               <a @click="waShare" class="flex-1 mr-4 button-rounded d-flex align-items-center justify-content-center blue fs-09" href="" target="_blank">Whatsapp'ta Paylaş</a>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sides right-side w-100 d-flex flex-column align-items-center justify-content-center">
      <img class="success-img" src="/img/success.jpg" />
    </div>
  </div>
</template>
<script>
function fbs_click() {
  u = location.href;
  t = document.title;
  window.open(
    "http://www.facebook.com/sharer.php?u=" +
      encodeURIComponent(u) +
      "&t=" +
      encodeURIComponent(t),
    "sharer",
    "toolbar=0,status=0,width=626,height=436"
  );
  return false;
}
</script>
<script>
!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?”http”:”https”;if(!d.getElementById(id))
{js=d.createElement(s);js.id=id;
js.src=p+”://platform.twitter.com/widgets.js”;
fjs.parentNode.insertBefore(js,fjs);}}
(document, ”script”, ”twitter-wjs”);
</script>
<script>
import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "FormSuccess",
  components: {
    ButtonRounded,
  },
  props: ["successType", "campaignData"],
  data() {
    return {
      show: true,
      campaignSuccess: false,
      campaignDonate: false,
      userInfo: '',
      metaData: {},
    };
  },
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.metaData.description,
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: this.metaData.title },
        { property: "og:description", content: this.metaData.description },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content: this.metaData.title },
        { name: "twitter:description", content: this.metaData.description },
        { name: "twitter:url", content: window.location.href },
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  mounted() {
    // this.userInfo = JSON.parse(localStorage.getItem("user-token")).user.name;
    this.successsType();
    gtag("event", "Bağış Ödeme Final", {
      event_category: "Bağış",
      event_label: "Başarılı Ödeme",
      value: this.$store.state.userInfo.total_price,
    });
    gtag('event', 'conversion', { 
 
      send_to: 'AW-979820132/EbGKCNTvsAcQ5Lyb0wM', 
      value: 1.0, 
      currency: 'TRY' 
 
  }); 

    gtag("event", "purchase", {
      transaction_id: this.$store.state.conversation_id,
      affiliation: "23 Nisan Kampanya Bağışı",
      value: this.$store.state.userInfo.total_price,
      currency: "TRY",
      tax: 0,
      shipping: 0,
      items: [
        {
          id: this.$store.state.conversation_id,
          name: "23 Nisan Kampanya Bağışı",
          list_name: "Bağış Tipi",
          brand: this.$store.state.formValue.donation_status,
          category: "Bağış",
          variant: "",
          list_position: 1,
          quantity: 1,
          price: this.$store.state.userInfo.total_price,
        },
      ],
    });
    if(this.successType == 'campaignDonate'){
      this.metaData = {
        title: this.$props.campaignData.detail.title,
        description: this.$props.campaignData.detail.message,
        slug: this.$props.campaignData.detail.slug,
      };
    }
  },
  methods: {
    closeModal() {
      this.$parent.destroyCarousel();
      this.$parent.closeModal();
    },
    successsType(){
      if(this.successType === 'createCampaign'){
          this.campaignSuccess = true;
      }else if(this.successsType === "campaignDonate"){
        this.campaignDonate = true;
      }
    },
    twShare() {
      event.preventDefault();
      // window.open("https://twitter.com/share?url=" + this.metaData.title + ' ' + window.location.href);
      window.open(
        "https://twitter.com/share?text=" +
          this.metaData.title +
          " " +
          this.metaData.description +
          "&url=" +
          window.location.href
      );
    },
    fbShare() {
      event.preventDefault();
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
          window.location.href +
          "&quote=" +
          this.metaData.title +
          " " +
          this.metaData.description
      );
    },
    linkedinShare() {
      event.preventDefault();
      // window.open("https://www.linkedin.com/shareArticle?mini=true&url="+window.location.href+"&title=Bilim BilimSeferberliği&summary=" + this.metaData.title);
      window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
          window.location.href
      );
    },
    waShare() {
      event.preventDefault();
      window.open(
        "https://api.whatsapp.com/send?text=" +
        this.metaData.title +
        " | " +
        this.metaData.description +
        " " +
        window.location.href
      );
    },
  },
};
</script>

<style lang="scss">
.form-success {
  .success-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 567px) {
    .section-wrapper{
      padding: 2rem;
    } 
  }
}
</style>
