var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100 h-100 padding-section-wrapper"},[_vm._m(0),_c('div',{staticClass:"\n      sides\n      right-side\n      section-wrapper\n      d-flex\n      align-items-start align-items-md-center\n      justify-content-center\n    "},[_c('loading',{attrs:{"active":_vm.isLoading,"background-color":"#332abb","opacity":0.99,"color":"#fff","is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"w-100",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('h2',[_vm._v("Bilgileriniz")]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"userName"}},[_vm._v("İsim Soyisim")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.userName),expression:"form.userName"}],staticClass:"form-control",class:{
            error: _vm.$v.form.userName.$error,
            valid: _vm.$v.form.userName.$dirty && !_vm.$v.form.userName.$invalid,
          },attrs:{"type":"text"},domProps:{"value":(_vm.form.userName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "userName", $event.target.value)},_vm.removeSpace]}}),(!_vm.$v.form.userName.alpha)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" İsim ve soyisim şeklinde giriniz. ")]):_vm._e(),(!_vm.$v.form.userName.nameSurnameLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" Sadece isim ve soyisim arasında boşluk olmalıdır. ")]):_vm._e(),(!_vm.$v.form.userName.minLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" En az 2 karakter girmelisiniz ")]):_vm._e(),(!_vm.$v.form.userName.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" Bu alan zorunludur. ")]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{attrs:{"for":"userMail"}},[_vm._v("E-Posta")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.userMail),expression:"form.userMail"}],staticClass:"form-control",class:{
              error: _vm.$v.form.userMail.$error,
              valid: _vm.$v.form.userMail.$dirty && !_vm.$v.form.userMail.$invalid,
            },attrs:{"inputmode":"email","type":"email"},domProps:{"value":(_vm.form.userMail)},on:{"keydown":_vm.key,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "userMail", $event.target.value)}}}),(!_vm.$v.form.userMail.email)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Yanlış mail formatı ")]):_vm._e(),(!_vm.$v.form.userMail.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Bu alan zorunludur. ")]):_vm._e()]),_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{attrs:{"for":"userPhone"}},[_vm._v("Telefon Numarası")]),_c('div',{staticClass:"d-flex"},[_c('vue-country-code',{attrs:{"defaultCountry":"TR","preferredCountries":['vn', 'us', 'gb']},on:{"onSelect":_vm.onSelect},model:{value:(_vm.dial),callback:function ($$v) {_vm.dial=$$v},expression:"dial"}}),_c('the-mask',{staticClass:"form-control",class:{
                error: _vm.$v.form.userPhone.$error,
                valid:
                  _vm.$v.form.userPhone.$dirty && !_vm.$v.form.userPhone.$invalid,
              },attrs:{"name":"phone","id":"frmPhoneNumA","autocomplete":"tel","mask":"(###) ### ## ##","placeholder":"(---) --- -- --","type":"tel"},model:{value:(_vm.form.userPhone),callback:function ($$v) {_vm.$set(_vm.form, "userPhone", $$v)},expression:"form.userPhone"}})],1),(!_vm.$v.form.userPhone.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Bu alan zorunludur. ")]):_vm._e(),(!_vm.$v.form.userPhone.minLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Yanlış telefon formatı ")]):_vm._e()])]),_c('label',{staticClass:"custom-checkbox"},[_c('a',{attrs:{"href":"https://www.bilimseferberligi.org/kvkk","target":"_blank"}},[_vm._v("Kişisel verilerin korunması ve işlenmesine ilişkin politikayı ")]),_vm._v(" okudum, onaylıyorum. "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.terms_accepted),expression:"form.terms_accepted"}],class:{
            error: _vm.$v.form.terms_accepted.$error,
            valid:
              _vm.$v.form.terms_accepted.$dirty &&
              !_vm.$v.form.terms_accepted.$invalid,
          },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.terms_accepted)?_vm._i(_vm.form.terms_accepted,null)>-1:(_vm.form.terms_accepted)},on:{"change":function($event){var $$a=_vm.form.terms_accepted,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "terms_accepted", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "terms_accepted", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "terms_accepted", $$c)}}}}),_c('span',{staticClass:"checkmark"})]),_c('ButtonRounded',{staticClass:"float-right",attrs:{"Text":"Devam Et","isRouting":false,"disabled":_vm.disabled}})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n      sides\n      left-side\n      section-wrapper\n      d-none d-md-block\n      position-relative\n    "},[_c('img',{staticClass:"info-bg",attrs:{"src":"/svg/user-info.svg"}})])}]

export { render, staticRenderFns }