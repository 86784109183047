<template>
  <transition name="fade">
    <div class="modal social-login-modal" v-if="show">
         
      <!-- <iframe id="modalFrame" frameborder="0"></iframe> -->

      <div  class="modal-message">
         <div class="modal-header">
                <h1></h1>
                
                <p  @click="closeModal">Kapat</p>
            </div>
        <p v-html="modalMsg" class="px-3 text-center"></p>
        <ButtonRounded class="mt-4"
            Text="Kapat"
            btnSize="middle" 
            :isRouting="false"
            @click.native="closeModal"
          />
      </div>

    </div>
  </transition>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "SocialLoginModal",
  props: [ "data" , "modalMsg" , "success"],
  components: { ButtonRounded },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      // document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  },
};
</script>


<style lang="scss">
.social-login-modal{
    z-index: 99999 !important;
    position: fixed !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: rgba(24, 15, 100 , 0.9);
}
.modal-message{
  width: 50%;
  height: 50%;
  position: relative;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 10px;
  flex-direction: column;
  color: #000;
  p{
    font-size: 1.4rem;
  }
  .modal-header{
      background:  var(--whiteOld);
      display: flex;
      align-items: center;
      position: absolute;
      top: 0px;
      justify-content: space-between;
      width: 100%;
        p{
            text-decoration: underline;
            font-size: 1rem;
            cursor: pointer;
            color: var(--purpleDark);
        }
        h1{
              font-size: 1.65rem;
            color: var(--purpleDark);
            font-weight: 400;
        }
  }
}
</style>