<template>
  <section
    :class="'home-banner d-flex flex-column align-items-center ' + TextPosition"
  >
    <modalVideo ref="modalVideo" :videoUrl="videoData"></modalVideo>
    <div
      :class="'bg-wrapper parallax d-none d-md-flex ' + Height" 
      data-depth="1"
      :style="bgImage"
    ></div>
    <div
      :class="'bg-wrapper parallax d-flex d-md-none mobile ' + Height" v-if="mobileImage"
      data-depth="1"
      :style="bgMobileImage"
    ></div>
    <div
      :class="'bg-wrapper parallax d-flex d-md-none  ' + Height" v-else
      data-depth="1"
    ></div>
    <!-- <span
          :class="'svg-icon icons-play ' + HasVideo"
          @click="showModalVideo('iyIDtf50qbY')"
        ></span> -->
    <div class="container text-center text-wrapper">
      <div class="parallax" data-depth="2.5">
        
        <h2 v-html="SmallTitle"></h2>
        <h1 v-html="Title"></h1>
        <p v-html="Desc"></p>
          <ButtonRounded v-if="Support === true"
          Text="Bağış Yap"
          class="mx-auto"
          btnSize="small"
          color=""
          :isRouting="false"
          @click.native="showKampanyaModal"
        />

        <div
          v-if="MultipleImage"
          class="multiple row justify-content-around multirow pt-5"
        >
          <div class="d-flex flex-column align-items-center">
            <img src="/svg/sehir-pin.svg" class="mb-4" />
            <h3 class="count" :data-count="countCity">0</h3>
            <p>İl</p>
          </div>

          <div class="d-flex flex-column align-items-center">
            <img src="/svg/hands.svg" class="mb-4" />
            <h3 class="count" :data-count="countTeacher">0</h3>
            <p>Öğretmen</p>
          </div>

          <div class="d-flex flex-column align-items-center">
            <img src="/svg/okul.svg" class="mb-4" />
            <h3 class="count" :data-count="countSchool">0</h3>
            <p>Okul</p>
          </div>

          <div class="d-flex flex-column align-items-center">
            <img src="/svg/ogrenci.svg" class="mb-4" />
            <h3 class="count" :data-count="countStudent">0</h3>
            <p>Çocuk</p>
          </div>
        </div>

        <img v-if="OverlapImage" class="overlapImage" :src="OverlapImage" />
      </div>
    </div>
    <div v-if="OverlapImage" class="white-space"></div>
  </section>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import ModalVideo from "@/components/ModalVideo";
export default {
  name: "MainBanner",
  components: { ModalVideo,ButtonRounded },
  data() {
    return {
      videoData: "",
    };
  },
  props: [
    "Title",
    "SmallTitle",
    "Desc",
    "Image",
    "OverlapImage",
    "MultipleImage",
    "TextPosition",
    "Height",
    "HasVideo",
    "countCity",
    "countTeacher",
    "countSchool",
    "countStudent",
    "Support",
    "mobileImage"
  ],
  computed: {
    bgImage() {
     if(this.Image){
        return 'background-image: url("' + this.Image.replace('jpg', 'webp') + '")'
        return 'background-image: url("' + this.Image + '")';
     }
    },
    bgMobileImage() {
     if(this.mobileImage){
        return 'background-image: url("' + this.mobileImage.replace('jpg', 'webp') + '")'
        return 'background-image: url("' + this.mobileImage + '")';
     }
    }
  },
  
  mounted() {
    this._scrollParallax();
    this._countWithScrol();
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
     showKampanyaModal() {
      this.$router.push('/bagis-yap')
    },
    showModalVideo(data) {
      this.videoData = data;
      this.$refs.modalVideo.openModal();
    },
    _scrollParallax() {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".home-banner",
          start: "top top",
          end: "+=300%",
          pin: false,
          scrub: true,
          // scrub: 1,
          invalidateOnRefresh: true,
          // ease: 0.05

          // start: "top top",
          // end: "bottom top",
          // markers: true,
        },
      });

      gsap.utils.toArray(".parallax").forEach((layer) => {
        const depth = layer.dataset.depth;
        const movement = -(layer.offsetHeight * depth);
        tl.to(layer, { y: movement, ease: "none" }, 0);
      });
    },
    _countWithScrol() {
      let countMe = () => {

        $(".count").each(function () {
          var $this = $(this),
            countTo = $this.attr("data-count");
          $({ countNum: $this.text(), }).animate(
            { countNum: countTo },
            {
              duration: 850,
              easing: "swing",
              step: function () {
                //$this.text(Math.ceil(this.countNum));
                $this.text(Math.ceil(this.countNum).toLocaleString("en"));
              },
              complete: function () {
                $this.text(Math.ceil(this.countNum).toLocaleString("en"));
                //alert('finished');
              },
            }
          );
        });
      };

      setTimeout(() => {
        ScrollTrigger.create({
          trigger: ".toplanan-bagis-infographic",
          start: "top center",
          toggleActions: "restart pause reverse pause",
          markers: false,
          onEnter: countMe,
          onLeaveBack: (self) => self.disable(),
        });
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.home-banner {
  min-height: 100vh;
  color: #fff;
  .icons-play{
        position: absolute;
    top: 50%;
  }
  .svg-icon {
    width: 3rem;
    cursor: pointer;
    display: none;
    height: 3rem;
    transition: var(--fast);
    &:hover {
      transform: scale(1.3);
    }
  }
  .bg-wrapper {
    width: 100%;
    color: #fff;
    position: fixed;
    left: 0;
    // padding: 4rem;
    background-size: cover;
    background-color: var(--purpleDark);
    background-position: bottom center;
    height: 100%;
    &.mobile{
      background-size: contain;
    background-repeat: no-repeat;
    background-color: black;
    }
    &.full {
      min-height: 100vh;
    }
    &.middle {
      min-height: 90vh;
    }
  }
  .count {
    min-width: 220px;
  }
  .white-space {
    position: relative;
    width: 100vw;
    height: 10vh;
    background: #fff;
  }

  .text-wrapper {
    padding-bottom: 4rem;
    margin-top: 15vh;
    position: relative;
    z-index: 1;
    /*  IE BUG  */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* detect IE */
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    /*  IE BUG  */
  }

  .parallax {
    transform: translate(0px, 0px);
    // margin-bottom: 1px;
    p {
      max-width: 90%;
      margin: auto;
    }
  }

  &.bottom {
    justify-content: flex-end;
  }
  &.between {
    justify-content: space-between;
    .text-wrapper {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  h1 {
    margin-bottom: 1.6rem;
  }
  h2 {
    font-size: 1.375rem;
    margin-bottom: 0.8rem;
  }
  .overlapImage {
    margin-bottom: -35vh;
    margin-top: 10vh;
    height: 50vh;
  }
}

@media (max-width: 567px) {
  .multirow {
    .d-flex {
      width: 50%;
    }
  }
  .home-banner {
    padding-top: 115px;
    h1 {
      margin-bottom: 1rem;
    }
    .bg-wrapper.full {
      min-height: calc(100vh - 115px);
    }
    .overlapImage {
      margin-bottom: -22vh;
      margin-top: 0vh;
    }
    .text-wrapper {
      padding-top: 4rem;
    }
    .multiple {
      img {
        max-height: 120px;
        height: 120px;
        max-width: 130px;
        object-fit: contain;
      }
    }
  }
}
</style>
