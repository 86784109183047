<template>
  <router-link v-if="isRouting" :to="Link" class="button-rounded d-flex align-items-center justify-content-center"
    :class="[btnSize, color]" 
  >{{ Text }}</router-link>
  
  <button v-else class="button-rounded d-flex align-items-center justify-content-center"
    :class="[btnSize, color]" 
    :disabled="disabled"
  >
  <span v-if="Icon" :class="'svg-icon ' + Icon" class="mr-2"></span>
  {{ Text }}
</button>
</template>

<script>
export default {
  name: "ButtonRounded",
  props: {
    Link: String,
    Text: String,
    btnSize: String,
    btnBg: String,
    isRouting: Boolean,
    color: String,
    disabled: Boolean,
    Icon: String,
  }
}
</script>
<style lang="scss">
.button-rounded{
  // background: var(--gradientOrange);
  background-image: linear-gradient(to right, #ee9c39, #e77b44, #ee9c39, #3b9bec);
  background-size: 300% 100%;
  // box-shadow: 0 4px 15px 0 rgba(238, 156, 57, 0.75);
  // box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  color: #fff;
  min-width: 215px;
  height: 55px;
  padding: 0 1.5rem;
  font-weight: 500;
  font-size: 1.2rem;
  transition: var(--slow);
  &:hover{
    // box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
    // box-shadow: 0 27px 55px 0 rgba(0,0,0,.3), 0 17px 17px 0 rgba(0,0,0,.15);
    background-position: 100% 0;
    transition: all .4s ease-in-out;
    // box-shadow: 0 10px 30px 5px rgba(0,0,0, .5);
    color: #fff;
  }
  &:disabled{
    background: var(--gray);
    color: #d0d0d0;
    cursor: not-allowed;
  }
  &.turquoise{
    background: var(--gradientBlue);
  }
  &.blue{
    background: var(--purpleDark);
    &:hover{
      background: var(--blueButton);
    }
  }
  &.large{}
  &.middle{
    width: 200px;
    height: 50px;
    font-size: 1rem;
  }
  &.small{
    min-width: 165px;
    font-size: 1rem;
    font-weight: 500;
    height: 45px;
    padding: 0 1rem;
  }

  .svg-icon{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: auto;
  }

  @media (max-width: 768px){
    min-width: 100px;
    // height: 45px;
    &.middle{
      width: 145px;
      height: 35px;
    }
  }

  @media (max-width: 567px){
    width: 100%;
    &.large,
    &.middle,
    &.small{
      width: 100%;
    }
  }
}
</style>