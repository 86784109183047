<template>
  <transition
    enter-active-class="animate__animated animate__fadeInDown"
    leave-active-class="animate__animated animate__fadeOutUp"
    mode="out-in"
  >
    <div class="modal main-modal" v-if="show">
      <div class="modal-container">
        <div class="modal-wrapper">
          <div
            class="
              swiper-modal
              swiper-carousel
              swiper-container
              swiper
              swiper-container-horizontal
            "
          >
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <SignIn @clicked="slideToPage" :formValue="formValue" />
              </div>
              <div class="swiper-slide">
                <SignUp @clicked="slideToPage" :formValue="formValue" />
              </div>
              <div class="swiper-slide">
                <ForgotPassword @clicked="slideToPage" :formValue="formValue" />
              </div>
            </div>
          </div>

          <div
            class="
              frame-footer frame-footer-signup
              d-flex d-md-none
              align-items-center
              justify-content-between
            "
          >
            <button
              @click="closeModal"
              type="button"
              class="back-button d-flex align-items-center"
            >
              <span class="svg-icon icons-back-arrow"></span>
              <p class="d-flex flex-column align-items-start">Geri Dön</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";

import SignUp from "@/components/SignUp";
import ForgotPassword from "@/components/ForgotPassword";
import SignIn from "@/components/SignIn";

export default {
  name: "ModalSignup",
  components: {
    SignUp,
    ForgotPassword,
    SignIn,
  },
  data() {
    return {
      show: false,
      formValue: {},
      swiperModal: "",
    };
  },
  mounted() {
    // Listen ESC for close
    window.addEventListener('keydown', (e)=>{
      var key = e.which || e.keyCode;
      if (key === 27) {
        this.closeModal();
      }
    });
  },
  methods: {
    getSwiper() {
      setTimeout(() => {
        this.swiperModal = new Swiper(".swiper-modal", {
          autoHeight: true,
          loop: false,
          slideToClickedSlide: true,
          paginationClickable: false,
          mousewheelControl: 1,
          parallax: false,
          speed: 0,
          slidesPerView: 1,
          effect: "fade",
          fadeEffect: { crossFade: true },
          allowTouchMove: false,
          grabCursor: false,
          simulateTouch: false,
        });
      }, 100);
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    destroyCarousel() {
      this.swiperModal = "";
    },
    keyPress(e) {
      if (e.key === "Escape") {
        // write your logic here.
      }
    },
    slideToPage(value) {
      this.swiperModal.slideTo(value, 0, false);
    },
  },
};
</script>

<style lang="scss">
.signup {
  color: var(--purpleDark);
  .left-side {
    background: #fff;
    min-height: calc(100vh - 80px);
  }
  .right-side {
    background: var(--purpleDark);
  }

  .custom-selectbox,
  input.form-control,
  textarea.form-control {
    background: var(--blueLight) !important;
    border: 2px solid var(--blueLight);
    color: #fff;
  }

  @media (max-width: 567px) {
    .left-side {
      padding: 2rem;
    }
  }
}

@media (max-width: 567px) {
  .frame-footer-signup {
    padding: 0 7vw;
    background: var(--purpleDark);
    button.back-button {
      color: #fff;
      .svg-icon {
        background: #fff;
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
</style>