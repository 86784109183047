<template>
  <transition name="fade">
    <div class="modal payment-modal" v-if="show">
      <iframe id="modalFrame" v-if="success" :srcdoc="data" frameborder="0"></iframe>

      <div v-if="!success" class="modal-message">
        <p v-html="modalMsg.msg"></p>
        <p v-html="modalMsg.message"></p>
        <ButtonRounded class="mt-4"
            Text="Kapat"
            btnSize="middle" 
            :isRouting="false"
            @click.native="closeModal"
          />
      </div>

    </div>
  </transition>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "ModalPayment",
  props: [ "data" , "modalMsg" , "success"],
  components: { ButtonRounded },
  data() {
    return {
      show: false,
    };
  },
  mounted(){
    // Listen ESC for close
    window.addEventListener('keydown', (e)=>{
      var key = e.which || e.keyCode;
      if (key === 27) {
        this.closeModal();
      }
    });
  },
  methods: {
    closeModal() {
      
      this.show = false;
      // document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  },
};
</script>


<style lang="scss">
.modal-message{
  width: 50%;
  height: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000;
  p{
    font-size: 1.4rem;
  }
}
</style>