<template>
  <div>
    <BagisSec
      ref="bagisSec"
      :isModal="true"
      @showBirBagisYap="showModal"
      @showKampanyaOlustur="modalKampanyaOlustur"
      @showHediyeKarti="modalHediyeKarti"
    ></BagisSec>

    <ModalBagisYap ref="modalBagisYap"></ModalBagisYap>
    <ModalKampanyaOlustur ref="modalKampanyaOlustur"></ModalKampanyaOlustur>
    <ModalHediyeKarti ref="modalHediyeKarti"></ModalHediyeKarti>

    <ModalSignup ref="modalSignup"></ModalSignup>

    <header
      class="d-none d-lg-flex align-items-center justify-content-between h-100"
      v-bind:class="getRouteName() ? 'sticky-head' : ''"
    >
      <router-link to="/" class="logo-wrapper">
        <img class="non-sticky" src="/svg/bs-logo-white.svg" alt="Bilim Seferberliği"/>
        <img class="sticky" src="/svg/bs-logo.svg" alt="Bilim Seferberliği"/>
      </router-link>

      <ul class="nav d-flex align-items-center justify-content-around w-50">
        <li>
          <router-link :to="{ path: '/bilim-seferberligi' }">Bilim Seferberliği</router-link>
          <div class="sub-menu">
            <div class="container same-links" v-if="this.$route.name === 'Bilim Seferberligi'">
              <a href="/bilim-seferberligi#bilim-seti">Bilim Seti</a>
              <a href="/bilim-seferberligi#bilim-seferberligi-danisma-kurulu">Bilim Seferberliği Danışma Kurulu</a>
              
              <a href="/bilim-seferberligi#neden-bilim-seferberligi">Neden Bilim Seferberliği?</a>
              <a href="/bilim-seferberligi#yga-hakkinda">YGA Hakkında?</a>
              <a href="/bilim-seferberligi#basinda-biz">Basında Biz</a>
            </div>
            <div class="container" v-else>
              <router-link :to="{ path: '/bilim-seferberligi', hash: '#bilim-seti' }">Bilim Seti</router-link>
              <router-link :to="{ path: '/bilim-seferberligi', hash: '#bilim-seferberligi-danisma-kurulu' }">Bilim Seferberliği Danışma Kurulu</router-link>
              <router-link :to="{ path: '/bilim-seferberligi', hash: '#neden-bilim-seferberligi', }">Neden Bilim Seferberliği?</router-link>
              <router-link :to="{ path: '/bilim-seferberligi', hash: '#yga-hakkinda' }">YGA Hakkında?</router-link>
              <router-link :to="{ path: '/bilim-seferberligi', hash: '#basinda-biz' }">Basında Biz</router-link>
            </div>
          </div>
        </li>
        <li>
          <router-link :to="{ path: '/destekcilerimiz' }"
            >Destekçilerimiz</router-link
          >
          <!-- <div class="sub-menu">
            <div class="container">
              <router-link to="/">Neden Bilim Seferberliği?</router-link>
            </div>
          </div> -->
        </li>
        <li>
          <router-link :to="{ path: '/kampanyalar' }">Kampanyalar</router-link>
          <!-- <div class="sub-menu">
            <div class="container">
              <router-link to="/">Neden Bilim Seferberliği?</router-link>
            </div>
          </div> -->
        </li>
        <li>
          <router-link :to="{ path: '/hikayelerimiz' }"
            >Hikayelerimiz</router-link
          >
        </li>
          <li>
          <router-link :to="{ path: '/guvenilirlik' }"
            >Güvenilirlik</router-link
          >
        </li>
      </ul>

      <div class="d-flex align-items-center">
        <button
          class="btn-underline"
          type="button"
          @click="showLoginModal"
          v-if="!isLogged"
        >
          Giriş Yap
        </button>

        <div class="user-menu-wrap" v-if="isLogged">
          <img
            @click.self="toggleUserMenu()"
            class="mini-photo"
            :src="profileImg"
            width="50"
            height="50"
          />

          <div class="menu-container">
            <ul class="user-menu">
              <li class="user-menu__item">
                <router-link :to="{ path: '/profil', hash: '#kampanyalarim' }" @click.native="tabSelect" class="user-menu-link">Kampanyalarım</router-link>
                <!-- <a href="/profil#kampanyalarim" class="user-menu-link"
                  >Kampanyalarım</a
                > -->
              </li>
              <li class="user-menu__item">
                <router-link :to="{ path: '/profil', hash: '#bagislarim', }" @click.native="tabSelect" class="user-menu-link">Bağışlarım</router-link>
                <!-- <a href="/profil#bagislarim" class="user-menu-link"
                  >Bağışlarım</a
                > -->
              </li>
              <li class="user-menu__item">
                <router-link :to="{ path: '/profil', hash: '#ayarlarim', }" @click.native="tabSelect" class="user-menu-link">Profil Ayarları</router-link>
                <!-- <a href="/profil#ayarlarim" class="user-menu-link"
                  >Profil Ayarları</a
                > -->
              </li>
              <li class="user-menu__item">
                <a href="#" class="user-menu-link" @click="logOut()"
                  >Çıkış Yap</a
                >
              </li>
            </ul>
          </div>
        </div>

        <ButtonRounded v-if="this.$route.name !== 'deprem'"
          class="float-right"
          Text="Bağış Yap"
          btnSize="small"
          color=""
          :isRouting="false"
          @click.native="showKampanyaModal"
        />
            <!-- <a href="https://23nisan.bilimseferberligi.org/?utm_source=button&utm_medium=link&utm_campaign=2022" target="_blank" class="button-rounded small float-right d-flex align-items-center justify-content-center" -->


      </div>
    </header>

    <div class="mobile-wrapper d-block d-lg-none">
      <div class="mobile-header">
        <div
          class="
            d-flex
            align-items-center
            justify-content-between
            w-100
            head-top
          "
        >
          <router-link to="/">
            <img
              class="logo"
              src="/svg/bs-logo.svg"
              alt="Bilim Seferberliği"
            />
          </router-link>
          <div class="burger-container" @click="toggleMobileMenu">
            <div id="burger">
              <div class="bar topBar"></div>
              <div class="bar midBar"></div>
              <div class="bar btmBar"></div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="menu-mobile d-flex d-lg-none flex-column justify-content-between"
      >
        <div class="panels accordion" id="menu-panels">
          <div class="menu-single" v-if="isLogged">
            <button
              class="
                menu-single-main-item
                w-100
                collapsed
                text-left
                d-flex
                justify-content-between
                align-items-center
              "
              type="button"
              data-toggle="collapse"
              data-target="#menu-user"
              aria-expanded="false"
              aria-controls="menu-user"
            >
              <router-link
                to="/profile"
                class="user-card d-flex align-items-center blue"
                v-if="isLogged"
              >
                <img :src="profileImg" />
                <div class="ml-2">
                  <span>Hoşgeldin</span>
                  <p>{{ userName }}</p>
                </div>
              </router-link>
              <span class="svg-icon icons-arrow-down"></span>
            </button>
            <div class="collapse" id="menu-user" data-parent="#menu-panels">
              <div class="d-flex flex-column align-items-start alt-items">
                <a href="/profil#kampanyalarim" class="user-menu-link"
                  >Kampanyalarım</a
                >
                <a href="/profil#bagislarim" class="user-menu-link"
                  >Bağışlarım</a
                >
                <a href="/profil#ayarlarim" class="user-menu-link"
                  >Profil Ayarları</a
                >
                <a href="#" class="user-menu-link" @click="logOut()"
                  >Çıkış Yap</a
                >
              </div>
            </div>
          </div>
          <div class="menu-single">
            <button
              class="
                menu-single-main-item
                w-100
                collapsed
                text-left
                d-flex
                justify-content-between
                align-items-center
              "
              type="button"
              data-toggle="collapse"
              data-target="#menu-1"
              aria-expanded="false"
              aria-controls="menu-1"
            >
              Bilim Seferberliği
              <span class="svg-icon icons-arrow-down"></span>
            </button>
            <div class="collapse" id="menu-1" data-parent="#menu-panels">
              <div class="d-flex flex-column align-items-start alt-items" v-if="this.$route.name === 'Bilim Seferberligi'">
                  <a @click="toggleMobileMenuAlt" href="/bilim-seferberligi#bilim-seti">Bilim Seti</a>
                  <a @click="toggleMobileMenuAlt" href="/bilim-seferberligi#bilim-seferberligi-danisma-kurulu">Bilim Seferberliği Danışma Kurulu</a>
                  <a @click="toggleMobileMenuAlt" href="/bilim-seferberligi#neden-bilim-seferberligi">Neden Bilim Seferberliği?</a>
                  <a @click="toggleMobileMenuAlt" href="/bilim-seferberligi#yga-hakkinda">YGA Hakkında?</a>
                  <a @click="toggleMobileMenuAlt" href="/bilim-seferberligi#basinda-biz">Basında Biz</a>
              </div>
               <div class="d-flex flex-column align-items-start alt-items" v-else>
                
                <router-link :to="{ path: '/bilim-seferberligi', hash: '#bilim-seti' }">
                  Bilim Seti
                </router-link>
                
                <router-link :to="{ path: '/bilim-seferberligi', hash: '#bilim-seferberligi-danisma-kurulu' }">
                  Bilim Seferberliği Danışma Kurulu
                </router-link>
                
                <router-link :to="{ path: '/bilim-seferberligi', hash: '#neden-bilim-seferberligi' }">
                  Neden Bilim Seferberliği?
                </router-link>
                
                <router-link :to="{ path: '/bilim-seferberligi', hash: '#yga-hakkinda' }">
                  YGA Hakkında
                </router-link>
                
                <router-link :to="{ path: '/bilim-seferberligi', hash: '#basinda-biz' }">
                  Basında Biz
                </router-link>

              </div>
            </div>
          </div>
          <div class="menu-single">
            <router-link to="/destekcilerimiz" class="menu-single-main-item"
              >Destekçilerimiz</router-link
            >
          </div>
          <div class="menu-single">
            <router-link to="/kampanyalar" class="menu-single-main-item"
              >Kampanyalar</router-link
            >
          </div>
          <div class="menu-single">
            <router-link to="/hikayelerimiz" class="menu-single-main-item"
              >Hikayelerimiz</router-link
            >
          </div>
          <div class="menu-single">
            <router-link to="/guvenilirlik" class="menu-single-main-item"
              >Güvenilirlik</router-link
            >
          </div>
         
        </div>

        <div class="w-100">
          <ButtonRounded
            class="login-btn"
            v-if="!isLogged"
            Text="Giriş Yap"
            btnSize="small"
            color="turquoise"
            :isRouting="false"
            @click.native="showLoginModal"
          />
        </div>
      </div>
      <ButtonRounded v-if="this.$route.name !== 'deprem'"
        Text="Bağış Yap"
        btnSize="small"
        color="orange"
        :isRouting="false"
        @click.native="showKampanyaModal"
      />
      <a v-if="this.$route.name === 'deprem'" href="https://fonzip.com/yga/fundraising-campaigns/depremzede-cocuklara-bilimi-sevdirelim--" target="_blank" class="button-rounded d-flex align-items-center justify-content-center small orange"><!----> Bağış Yap </a>
     
       <!-- <a href="https://23nisan.bilimseferberligi.org/?utm_source=button&utm_medium=link&utm_campaign=2022" target="_blank" class="button-rounded small orange  d-flex align-items-center justify-content-center" -->

  <!-- >Bağış Yap</a> -->
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import axios from "axios";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import MobileMenuUser from "@/components/MobileMenuUser";
import ButtonRounded from "@/components/ButtonRounded";
import BagisSec from "@/components/BagisSec";
import ModalSignup from "@/components/ModalSignup";

import ModalBagisYap from "@/components/ModalBagisYap";
import ModalKampanyaOlustur from "@/components/ModalKampanyaOlustur";
import ModalHediyeKarti from "@/components/ModalHediyeKarti";

export default {
  name: "Header",
  components: {
    ButtonRounded,
    MobileMenuUser,
    BagisSec,
    ModalSignup,
    ModalBagisYap,
    ModalKampanyaOlustur,
    ModalHediyeKarti,
  },
  data() {
    return {
      isStickyHeader: false,
      isLogged: false,
      profileImg: "",
      userName: "",
    };
  },
  mounted() {
    this.getHeaderSticky();
    this.getRouteName();
    // this._headerAnim();
    this.loginCheck();
      $('.alt-items a').on('click', function(){
       let header = document.querySelector(".mobile-wrapper");
      let menu = document.querySelector(".menu-mobile");
      header.classList.remove("menu-opened");
      menu.classList.remove("actived");
    });
    this.$root.$on('openLoginModalHome', (index) => { 
      // your code goes here
      this.showLoginModal();
      fbq('track', 'Lead');
      });
  },
  watch: {
    $route() {
      this.toggleMobileMenu();
      this.toggleMobileMenuAlt();
      //this.toggleUserMenu(close);
    },
    $route(to, from) {
      this.loginCheck();
      //this.toggleMobileMenu();
      this.toggleMobileMenuAlt();
      //this.toggleUserMenu(close);
      $(".menu-container").removeClass("active");
    },
    isLogged: function (newVal) {},
  },
  methods: {
    tabSelect() {
      var hash = window.location.hash;
      hash && $('ul.nav a[href="' + hash + '"]').tab("show");

      $(".user-menu__item a").click(function (e) {
        $(this).tab("show");
        var scrollmem = $("body").scrollTop();
        window.location.hash = this.hash;
        $("html,body").scrollTop(scrollmem);
      });

    },
    getRouteName() {
      this.isLogged = this.$store.state.loginState;
      let token = localStorage.getItem("user-token");
      if (token !== null && token) {
        let tokenize = JSON.parse(token);
        this.profileImg = tokenize.user.image;
      } else {
        
      }
      // if(this.$route.name === "Profile"){
      //   this.isStickyHeader = true;
      // }

      return this.$route.name === "Profile";
    },
    loginCheck() {
      let token = localStorage.getItem("user-token");
      let tokenize = JSON.parse(token);
      if (token !== null) {
        tokenize = JSON.parse(token);
        this.profileImg = tokenize.user.image;
        this.userName = tokenize.user.name;
        axios
          .get(
            "https://bilimseferberligi.org/cms/api/v1/token/check",
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                Authorization: `Bearer ${tokenize.token}`,
              },
            }
          )
          .then((response) => {
            var response = response.data;
            if (response.success) {
              localStorage.setItem("login-status", response.success);
              this.$store.commit("_loginStatus", response.success);

              this.isLogged = true;
            } else {
              localStorage.setItem("login-status", response.success);
              this.isLogged = false;
            }
          });
      }
    },
    logOut() {
      this.$store.commit("_loginStatus", false);
      this.$store.commit("_userInfo", null);
      localStorage.setItem("login-status", false);
      localStorage.removeItem("user-token");
      // localStorage.setItem('user-token', null);
    },
    showModal() {
      this.$refs.bagisSec.closeModal();
      this.$refs.modalBagisYap.openModal();
      this.$refs.modalBagisYap.getSwiper();
    },
    modalKampanyaOlustur() {
      this.$refs.bagisSec.closeModal();
      this.$refs.modalKampanyaOlustur.openModal();
      this.$refs.modalKampanyaOlustur.getSwiper();
    },
    modalHediyeKarti() {
      this.$refs.bagisSec.closeModal();
      this.$refs.modalHediyeKarti.openModal();
      this.$refs.modalHediyeKarti.getSwiper();
    },
    showLoginModal() {
      // this.$parent.showSignupModal();
      this.$refs.modalSignup.openModal();
      this.$refs.modalSignup.getSwiper();
      $(".mobile-wrapper").removeClass("menu-opened");
      $(".menu-mobile").removeClass("actived");
    },
    showKampanyaModal() {
      // $(".mobile-wrapper").removeClass("menu-opened");
      // $(".menu-mobile").removeClass("actived");
      // this.$refs.bagisSec.openModal();
      fbq('track', 'Lead');
      this.$router.push('/bagis-yap')
    },
    toggleUserMenu(close = false) {
      if ($(".menu-container")) {
        let container = document.querySelector(".menu-container");
        !close && container.classList.toggle("active");
        close && container.classList.remove("active");
      }
    },
    toggleMobileMenuAlt() {
      let header = document.querySelector(".mobile-wrapper");
      let menu = document.querySelector(".menu-mobile");
      header.classList.remove("menu-opened");
      menu.classList.remove("actived");
    },
    toggleMobileMenu() {
      let header = document.querySelector(".mobile-wrapper");
      let menu = document.querySelector(".menu-mobile");
      header.classList.toggle("menu-opened");
      menu.classList.toggle("actived");
      // document.querySelector("body").classList.toggle("overflow-hidden");
    },
    getHeaderSticky() {
      $(window).on("scroll", function () {
        if ($(window).scrollTop() > 100) {
          $("header").addClass("sticky");
          //  $(".mobile-menu").addClass("sticky-menu");
        } else {
          $("header").removeClass("sticky");
          // $(".mobile-menu").removeClass("sticky-menu");
        }
      });
    },
    _headerAnim() {
      const showAnim = gsap
        .from("header", {
          yPercent: -100,
          paused: true,
          duration: 0.2,
        })
        .progress(1);

      ScrollTrigger.create({
        start: "top top",
        end: 99999,
        // toggleClass: {targets: "header", className: "sticky"},
        onUpdate: (self) => {
          self.direction === -1 ? showAnim.play() : showAnim.reverse();
        },
      });
    },
  },
};
</script>

<style lang="scss">
header {
  position: fixed;
  width: 100vw;
  top: 0;
  padding: 0.75rem 2rem;
  // margin-bottom: -91px;
  z-index: 2;
  transition: var(--fast);
  max-height: 82px;
  font-weight: 600;
  backdrop-filter: blur(0);
  will-change: transform;
  a{
    img{
      width: 165px;
      height: auto;
      aspect-ratio: 165 / 113;
    }
  }
  &.sticky,
  &.sticky-head {
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(12px);
    // -webkit-backdrop-filter: blur(12px) saturate(50%);
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.2);
    margin-bottom: 0;
    .non-sticky {
      display: none;
    }
    .sticky {
      display: inline-block;
    }
    .btn-underline {
      color: var(--purpleDark);
    }
    .nav li a {
      color: var(--purpleDark);
    }
  }
  .sticky {
    display: none;
  }

  .logo-wrapper{
    img{
      width: 170px;
      // height: 46px;
      aspect-ratio: 170 / 46;
    }
  }


  .btn-underline {
    color: #fff;
    margin-right: 2rem;
  }
  .nav {
    > li {
      position: relative;
      > a {
        color: #fff;
        cursor: pointer;
        height: 82px;
        display: flex;
        align-items: center;
        position: relative;
        transition: var(--fast);
        &.active {
          color: var(--green);
        }
        &:hover {
          color: var(--green);
          &::before {
            content: "";
            position: absolute;
            left: calc(50% - 13.5px);
            bottom: -6px;
            border-style: solid;
            border-width: 0 15px 15px;
            border-color: transparent transparent var(--whiteOld);
          }
        }
      }
      &:nth-child(2) {
        a {
          &:hover {
            &::before {
              content: none !important;
            }
          }
        }
      }
      &:nth-child(3) {
        a {
          &:hover {
            &::before {
              content: none !important;
            }
          }
        }
      }
       &:last-child{
        a {
          &:hover {
            &::before {
              content: none !important;
            }
          }
        }
      }
      &:nth-child(4) {
        a {
          &:hover {
            &::before {
              content: none !important;
            }
          }
        }
      }
      &:hover {
        .sub-menu {
          display: block;
          position: fixed;
          padding: 1rem 2rem;
          top: 82px;
          max-height: 60px;
          left: 0;
          width: 100vw;
          background: var(--whiteOld);
          .container {
            display: flex;
            align-items: center;
            justify-content: center;
            a {
              padding: 0 2rem;
              font-weight: normal;
              font-size: 0.77rem;
              color: var(--purpleDark);
              &:hover {
                color: var(--orange);
              }
            }
          }
        }
      }
      .sub-menu {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    .sticky,
    .non-sticky {
      width: 130px;
    }

    .logo-wrapper{
      img{
        width: 155px;
      }
    }

  }
}

// Profile
.user-menu-wrap {
  position: relative;
  margin-right: 1rem;
  cursor: pointer;
  background: var(--white);
  border-radius: 50%;
  padding: 1px;
}

.menu-container {
  visibility: hidden;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
    // transition: all 0.2s ease-in-out;
    transition: var(--fast);
  }
}

.user-menu {
  position: absolute;
  right: -155%;
  margin-top: 15px;
  background-color: var(--blueOcean);
  color: #fff;
  width: 215px;
  border-radius: 8px;

  &:before {
    position: absolute;
    top: -16px;
    left: 120px;
    display: inline-block;
    content: "";
    border: 8px solid transparent;
  }

  &:after {
    position: absolute;
    top: -14px;
    left: 107px;
    display: inline-block;
    content: "";
    border: 7px solid transparent;
    border-bottom-color: var(--blueOcean);
  }

  .user-menu__item {
    .user-menu-link {
      padding: 0.7rem 1rem;
      font-size: 0.9rem;
      font-weight: 300;
      display: block;
      border-bottom: 1px solid var(--purpleLight);
      &:hover {
        background: #3d30f8;
      }
    }
    &:first-child {
      .user-menu-link {
        border-radius: 8px 8px 0 0;
      }
    }
    &:last-child {
      .user-menu-link {
        // border-bottom: none;
        border-radius: 0 0 8px 8px;
      }
    }
  }
}

// Mobile Menu
$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);

.burger-container {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s $cubic;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  #burger {
    width: 30px;
    height: 20px;
    position: relative;
    display: block;
    margin: -6px auto 0;
    top: 50%;
    .bar {
      width: 100%;
      height: 3px;
      display: block;
      position: relative;
      background: var(--purpleDark);
      transition: all 0.3s $cubic;
      transition-delay: 0s;
      &.topBar {
        transform: translateY(-3px) rotate(0deg);
      }
      &.midBar {
        transform: translateY(3px) rotate(0deg);
      }
      &.btmBar {
        transform: translateY(9px) rotate(0deg);
      }
    }
  }
}

// $menuItems: 7;
.mobile-wrapper {
  position: fixed;
  overflow: hidden;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 115px;
  max-height: -webkit-fill-available;
  background-color: rgba(255, 255, 255, 0.9);
  transition: var(--slow);

  .button-rounded {
    width: 100%;
    border-radius: 0px;
    height: 55px;
  }
  .head-top {
    padding: 0.5rem;
    height: 60px;
  }
  &.menu-opened {
    height: 100%;
    .burger-container {
      transform: rotate(90deg);
      #burger {
        .bar {
          transition: all 0.4s $cubic;
          transition-delay: 0.2s;
          &.topBar {
            transform: translateY(5px) rotate(45deg);
          }
          &.midBar {
            opacity: 0;
          }
          &.btmBar {
            transform: translateY(0px) rotate(-45deg);
          }
        }
      }
    }
    ul.menu {
      li.menu-item {
        transform: scale(1) translateY(0px);
        opacity: 1;
      }
    }
  }

  .logo {
    height: 40px;
  }
}
.menu-mobile {
  height: 0;
  z-index: 3;
  width: 100%;
  transition: var(--slow);
  background: transparent;

  .accordion {
    overflow-y: auto;
  }

  .login-btn {
    opacity: 0;
    visibility: hidden;
  }
  &.actived {
    top: 0px;
    height: calc(100% - 115px);
    background: var(--whiteOld);
    .login-btn {
      opacity: 1;
      visibility: visible;
    }
  }
  .menu-single {
    padding: 0 1rem;
    margin-top: 10px;
    &::after {
      content: "";
      display: block;
      height: 2px;
      width: 100%;
      background: var(--purpleLight);
      opacity: 0.1;
      margin-top: 10px;
    }
    .menu-single-main-item {
      display: block;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      color: var(--purpleDark);
      background: transparent;
      &:focus {
        outline: none !important;
      }
      .svg-icon {
        background: var(--purpleDark);
        width: 1.5rem;
        height: 1.5rem;
        transition: 0.5s ease;
        transform: rotate(-180deg);
      }
    }
    .collapsed {
      .svg-icon {
        transform: rotate(0deg);
      }
    }
    .alt-items {
      margin-top: 1rem;
      a {
        width: 100%;
        font-size: 1rem;
        color: var(--purpleDark);
        opacity: 0.8;
        line-height: 2rem;
      }
    }
  }
}
.button-rounded {
  &.mobile-button {
    width: 100%;
    border-radius: 0px;
    height: 55px;
    position: fixed;
    top: 60px;
    z-index: 9;
  }
}
.mini-photo {
  border-radius: 50%;
  background: white;
}
</style>
