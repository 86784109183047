<template>
  <div class="position-relative d-flex align-items-center flex-column flex-md-row">
    <div class="swiper-carousel swiper-parent position-relative container" :class="[nav]">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(data, index) in cardData" :key="index">

          <div class="swiper-img-wrapper">
            <img :src="data.image" alt="Görsel">
            <!-- <picture>
              <source srcset="/img/ogretmen-1.webp" type="image/webp">
              <source srcset="/img/ogretmen.jpeg" type="image/jpeg">
            </picture> -->
          </div>
        </div>
      </div>
    </div>

    <div class="swiper-child-wrapper row w-100">
      <div class="container">
        <div :class="cardPosition + ' swiper-carousel swiper-child position-relative ' + nav">
          <div class="swiper-wrapper d-flex align-items-center">
            <FloatingCard
              class="swiper-slide"
              :bg="cardColor"
              v-for="(datas, index) in cardData" :key="index"
              :carddData="datas"
              @clicked="openVideo"
            />
          </div>
          
          <div class="swiper-button swiper-card-next swiper-button-next" :class="[nav+'next']"></div>
          <div class="swiper-button swiper-card-prev swiper-button-prev" :class="[nav+'prev']"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, Scrollbar, EffectCoverflow, SwiperClass, Pagination, Navigation, Mousewheel, Autoplay, EffectFade } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar, EffectCoverflow, Mousewheel, Autoplay, EffectFade]);
import 'swiper/swiper-bundle.css';

import FloatingCard from "@/components/FloatingCard";

export default {
  name: "MultipleCarousel",
  props: ["cardColor", "cardPosition", "cardData", "nav"],
  components: {
    FloatingCard
  },
  mounted() {
    this._multipleCarousel();
  },
  methods: {
    openVideo(value){
      this.$emit("clicked", value);
    },
    _multipleCarousel() {
      const swiper = new Swiper(".swiper-parent." + this.nav , {
        loop: true,
        spaceBetween: 30,
        grabCursor: false,
        allowTouchMove: false,
        paginationClickable: false,
        navigation: {
          nextEl: ".swiper-card-next.swiper-button-next." + this.nav + "next",
          prevEl: ".swiper-card-prev.swiper-button-prev." + this.nav + "prev",
        },
        breakpoints: {
          640: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
          },
        },
      });

      const swiperChild = new Swiper(".swiper-child." + this.nav, {
        loop: true,
        slidesPerView: 1,
        grabCursor: false,
        allowTouchMove: false,
        navigation: {
          nextEl: ".swiper-card-next." + this.nav + "next",
          prevEl: ".swiper-card-prev." + this.nav + "prev",
        },
        effect: "fade",
        fadeEffect: { crossFade: true },
      });
    },
  },
};
</script>
