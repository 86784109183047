<template>
  <form
    @submit.prevent="submitForm"
    autocomplete="off"
    class="kampanya-olustur d-flex flex-column flex-md-row h-100 w-100"
  >
    <div
      class="
        sides
        left-side
        section-wrapper
        d-flex
        align-items-center
        justify-content-center
      "
    >
      <div
        class="
          w-100
          sides-container
          d-flex
          flex-column
          justify-content-center
          mb-5
        "
      >
        <h3 class="mb-3">Kampanya Oluştur</h3>
        <p class="mb-5">
          1 okulu bilimle buluşturmanın bedeli 18.500 TL’dir. Eğer kampanyanız
          18.500 TL ve katları olursa, bilimle buluşturacağınız okulun il ve
          ilçesini seçebilirsiniz.
        </p>
      </div>
    </div>

    <div
      class="
        sides
        right-side
        section-wrapper
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
    >
      <div class="form-group w-100">
        <div class="d-flex justify-content-between">
          <label for="campaignVideoLink">Video Linki (İsteğe Bağlı)</label>
        </div>
        <input
          v-model="form.campaignVideoLink"
          type="text"
          class="form-control"
        />
      </div>

      <div class="form-group w-100">
        <div class="d-flex justify-content-between">
          <label for="yetkililereMesaj">Yetkililer İçin Mesaj</label>
          <span
            class="char-count"
            v-text="'*' + (maxMsg - form.yetkililereMesaj.length) + ' karakter'"
          ></span>
        </div>
        <textarea
          v-model="form.yetkililereMesaj"
          v-on:input="$v.form.yetkililereMesaj.$touch"
          v-bind:class="{
            error: $v.form.yetkililereMesaj.$error,
            valid:
              $v.form.yetkililereMesaj.$dirty &&
              !$v.form.yetkililereMesaj.$invalid,
          }"
          rows="6"
          type="text"
          class="form-control"
        ></textarea>
        <p class="form-warning" v-if="$v.form.yetkililereMesaj.$invalid">
          Bu alan zorunludur.
        </p>
      </div>

      <div class="w-100 d-flex flex-column flex-md-row justify-content-between">
        <label class="custom-checkbox d-flex align-items-center"
          >Toplam Bağışı Gizle
          <input type="checkbox" v-model="form.bagisiGizle" />
          <span class="checkmark"></span>
        </label>

        <label class="custom-checkbox d-flex align-items-center"
          >Bağışçı Listesini Gizle
          <input type="checkbox" v-model="form.bagiscilariGizle" />
          <span class="checkmark"></span>
        </label>
      </div>

      <ButtonRounded
        class="ml-auto"
        Text="Devam Et"
        :isRouting="false"
        :disabled="disabled"
      />
    </div>
  </form>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

import ButtonRounded from "@/components/ButtonRounded";
import { required } from "vuelidate/lib/validators";

export default {
  name: "KampanyaOlustur",
  components: {
    ButtonRounded,
    flatPickr,
  },
  data() {
    return {
      maxMsg: 400,
      disabled: false,
      form: {
        campaignVideoLink: "",
        yetkililereMesaj: "",
        bagisiGizle: false,
        bagiscilariGizle: false,
      },
    };
  },
  mounted() {},
  validations: {
    form: {
      yetkililereMesaj: {
        required: required,
      },
      // tarih: {
      //   required: required
      // },
      // miktar: {
      //   required: required
      // },
      // bagisiGizle: { checked: value => value === true },
      // bagiscilariGizle: { checked: value => value === true },
    },
  },
  methods: {
    toggleInput(e, item) {
      let $target = $(item.target).closest("label").siblings().find("input");
      $($target).toggleClass("enabled");

      // $target.prop('disabled', function(i, v) { return !v; });
      $target.val("");
    },
    enableInput(item) {
      let $checkbox = $(item.target)
        .closest(".relative-checkbox")
        .find("input")[0];
      let $input = $(item.target)
        .closest(".relative-checkbox")
        .find("input")[1];

      if (!$($input).hasClass("enabled")) {
        $($input).addClass("enabled");
        // $($input).prop('disabled', function(i, v) { return !v; });
        $($checkbox).prop("checked", true);
      }
    },
    createCampaign() {
      let token = localStorage.getItem("user-token");
      let tokenize = JSON.parse(token);
      let params = {};
      params["user_id"] = tokenize.user.id;
      params["city_id"] = this.$store.state.createCampaignData.city;
      params["title"] = this.$store.state.createCampaignData.campaignTitle;
      params["message"] = this.$store.state.createCampaignData.campaignMsg;
      params["video"] = this.form.campaignVideoLink.split("v=")[1];
      params["price"] = this.$store.state.createCampaignData.miktar;
      params["owner_message"] = this.form.yetkililereMesaj;
      params["donation_show"] = this.form.bagisiGizle === true ? "true" : "false";
      params["donation_list"] = this.form.bagiscilariGizle === true ? "true" : "false";
      params["end_date"] = this.$store.state.createCampaignData.tarih;

      let headers = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${tokenize.token}`,
        },
      };
      this.$api.createCampaign(params, headers).then((response) => {});
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        this.$emit("clicked", "1");
        this.createCampaign();
      } else {
      }
    },
  },
};
</script>

<style lang="scss">
form.kampanya-olustur {
  height: calc(100% + 80px) !important;

  @media (max-width: 567px) {
    .section-wrapper {
      &.left-side {
        padding: 2rem 2rem 0 2rem;
      }
      &.right-side {
        padding: 0 2rem 2rem 2rem;
      }
    }
  }
}
</style>