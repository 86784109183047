<template>
  <router-link to="" :class="'user-card d-flex align-items-center ' + fontColor">
    <img :src="image">
    <div class="ml-2">
      <span>Kampanyayı Oluşturan</span>
      <p>{{userName}}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "UserCard",
  props: ["fontColor" , "userName" , "image"]

}
</script>

<style lang="scss">
a.user-card{
  color: var(--whiteOld);
  line-height: 1.2;
  &.blue{ color: var(--purpleDark); }
  span{
    opacity: .5;
    font-size: 0.8rem;
  }
  img{
    max-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
</style>